import { RecoverPasswordRequest } from "share2flow-typedefs"
import config from "../../config"

export async function recoverPassword(email: string, password: string, otp: string | null): Promise<void> {
  const request: RecoverPasswordRequest = {
    email,
    password,
    otp,
  }

  const res = await fetch(`${config.apiUrl}/auth/recoverPassword`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request)
  })
  if (res.status === 400) {
    const resJson = await res.json()
    throw new Error(resJson.error)
  }
  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
}

export async function authenticateGoogle(query: string): Promise<any> {
  const res = await fetch(`${config.apiUrl}/auth/google/callback?${query}`)
  if (res.status === 400) {
    const resJson = await res.json()
    throw new Error(resJson.error)
  }
  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
  return await res.json()
}
