import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { AiFillCaretUp } from "react-icons/ai";

export type PopupMenuAnchor = 'TopLeft' | 'TopRight' | 'TopCenter'

export interface PopupMenuProps {
  isVisible: boolean,
  onDismiss: () => void,
  width: number,
  top?: number,
  left?: number,
  anchor?: PopupMenuAnchor,
}

function useOutsideAlerter(ref: React.RefObject<HTMLDivElement>, handler: () => void) {
  useEffect(() => {
    function handleClickOutside(this: Document, event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as any)) {
        handler()
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    };
  }, [ref, handler]);
}

export function PopupMenu({ children, isVisible, onDismiss, width, top, left, anchor }: React.PropsWithChildren<PopupMenuProps>) {
  const [pLeft, setPLeft] = useState<number>()
  const [pTop, setPTop] = useState<number>()
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const rect = ref.current?.getBoundingClientRect()
    if (rect) {
      setPLeft(rect.left + rect.width / 2)
      setPTop(rect.top + rect.height / 2)
    }
  }, [isVisible])

  return (
    <>
      <div ref={ref}></div>
      {isVisible && <PopupMenuChild children={children} isVisible={true} onDismiss={onDismiss} width={width} left={left || pLeft} top={top || pTop} anchor={anchor} />}
    </>
  )
}

function PopupMenuChild({ children, onDismiss, width, top, left, anchor }: React.PropsWithChildren<PopupMenuProps>) {
  const [wasVisible, setWasVisible] = useState<boolean>(false)

  useEffect(() => {
    setTimeout(() => {
      setWasVisible(true)
    })
  }, [])

  const onClose = () => {
    setWasVisible(false)
    setTimeout(() => {
      onDismiss()
    }, 100)
  }

  const ref = useRef<HTMLDivElement>(null);
  useOutsideAlerter(ref, () => {
    onClose()
  });

  const portalEl = document.body

  let elLeft = (left||0) - width/2
  let elTop = (top||0) + 25
  if (anchor === 'TopLeft') {
    elLeft = (left||0)
    elTop = (top||0)
  } else if (anchor === 'TopRight') {
    elLeft = (left||0) - width
    elTop = (top||0)
  }

  return (
    <div>
      {portalEl && createPortal(
        <div className="relative z-[1002]">
          <div className={`fixed inset-0 opacity-0 ${wasVisible ? '' : 'pointer-events-none'}`}></div>
          <div className={`fixed z-[1002] py-2 bg-white rounded-lg shadow-xl flex-col items-start flex transition-opacity ease-in-out duration-200 ${wasVisible ? 'opacity-100' : 'opacity-0 pointer-events-none'}`} style={{ left: elLeft, top: elTop, width }} ref={ref}>
            {anchor === 'TopCenter' || anchor === undefined && <div className="absolute -top-4 inset-x-0 text-2xl flex justify-center pointer-events-none">
              <AiFillCaretUp className="text-white" />
            </div>}
            {children}
          </div>
        </div>
      , portalEl)}
    </div>
  )
}
