import config from "../../config"

export interface AvatarProps {
  avatarUrl?: string,
  size?: number,
  tooltip?: string,
}

export function Avatar({ avatarUrl, size, tooltip }: AvatarProps) {
  return (
    <div className="group relative">
      {avatarUrl
        ? <img className="aspect-square rounded-full object-cover" style={{ width: `${size ?? 32}px`, height: `${size ?? 32}px` }} src={config.backendUrl + avatarUrl} />
        : <div className="aspect-square flex items-center justify-center rounded-full bg-gray-100" style={{ width: `${size ?? 32}px`, height: `${size ?? 32}px` }}>
          <i className="fi fi-rr-user text-sky-500" style={{ fontSize: `${(size ?? 32) - 8}px` }}></i>
        </div>
      }
      <div className="absolute z-[1000] -bottom-6 right-0 px-2 py-1 bg-white text-gray-900 text-xs rounded opacity-0 group-hover:opacity-100">{tooltip}</div>
    </div>
  )
}
