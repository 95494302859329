import { GetUserHasUnreadNotificationsResponse, GetUserNotificationsResponse } from "share2flow-typedefs"
import config from "../../config"

export async function getUserNotifications(token: string): Promise<GetUserNotificationsResponse> {
  const res = await fetch(`${config.apiUrl}/notifications`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
  return await res.json()
}

export async function hasUnreadNotifications(token: string): Promise<boolean> {
  const res = await fetch(`${config.apiUrl}/notifications/unread`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
  const json: GetUserHasUnreadNotificationsResponse = await res.json()
  return json.hasUnreadNotifications
}

export async function markNotificationsAsRead(token: string): Promise<void> {
  const res = await fetch(`${config.apiUrl}/notifications/read`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
}
