import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { areCellCoordsEqual, CellCoords } from "share2flow-board"
import { sendMessage, sendMessageWithCallback } from "../../socketState"
import { AppDispatch } from "../../store"

export interface LockCell {
  lock: () => Promise<boolean>,
  unlock: () => Promise<void>,
  isLocked: () => boolean,
}

export function useLockCell(coords: CellCoords | null): LockCell {
  const [lockedCellCoords, setLockedCellCoords] = useState<CellCoords | null>(null)
  const dispatch = useDispatch<AppDispatch>()

  const lock = async (): Promise<boolean> => {
    if (lockedCellCoords !== null) throw new Error("Already locked")
    return new Promise((resolve, _reject) => {
      dispatch(sendMessageWithCallback({
        event: 'lock',
        data: coords,
        callback: (success: boolean) => {
          if (success) setLockedCellCoords(coords)
          resolve(success)
        }
      }))
    })
  }

  const unlock = async () => {
    if (lockedCellCoords) {
      dispatch(sendMessage({
        event: 'unlock',
        data: coords,
      }))
      setLockedCellCoords(null)
    }
  }

  const isLocked = () => {
    return lockedCellCoords !== null
  }

  useEffect(() => {
    if (lockedCellCoords !== null && (coords === null || !areCellCoordsEqual(coords, lockedCellCoords))) {
      unlock()
    }
  }, [coords])

  return { lock, unlock, isLocked }
}

export interface LockRow {
  lock: () => Promise<boolean>,
  unlock: () => Promise<void>,
  isLocked: () => boolean,
}

export function useLockRow(coords: CellCoords | null): LockCell {
  const [lockedRowCoords, setLockedRowCoords] = useState<CellCoords | null>(null)
  const dispatch = useDispatch<AppDispatch>()

  const lock = async (): Promise<boolean> => {
    if (lockedRowCoords !== null) throw new Error("Already locked")
    return new Promise((resolve, _reject) => {
      dispatch(sendMessageWithCallback({
        event: 'lockRow',
        data: coords,
        callback: (success: boolean) => {
          if (success) setLockedRowCoords(coords)
          resolve(success)
        }
      }))
    })
  }

  const unlock = async () => {
    if (lockedRowCoords) {
      dispatch(sendMessage({
        event: 'unlockRow',
        data: coords,
      }))
      setLockedRowCoords(null)
    }
  }

  const isLocked = () => {
    return lockedRowCoords !== null
  }

  useEffect(() => {
    if (lockedRowCoords !== null && (coords === null || !areCellCoordsEqual(coords, lockedRowCoords))) {
      unlock()
    }
  }, [coords])

  return { lock, unlock, isLocked }
}
