import { SupportLevelCell } from './SupportLevelCell';
import { IdeasAndImprovementsCell } from './IdeasAndImprovementsCell';
import { ProblemsAndDifficultiesCell } from './ProblemsAndDifficultiesCell';
import { RolesAndFlowsCell } from './RolesAndFlowsCell';
import { CommentCell } from './CommentCell';
import { KpisCell } from './KpisCell';
import { DocumentFlowCell } from './DocumentFlowCell';
import { CellCoords, SectionIdentifier } from 'share2flow-board';

export interface CellContentsProps {
  coords: CellCoords,
  boardId: string,
}

export function CellContents({ coords, boardId }: React.PropsWithChildren<CellContentsProps>) {
  let node: React.ReactNode | undefined = undefined

  switch (coords.section) {
    case SectionIdentifier.SUPPORT_LEVEL:
      node = <SupportLevelCell coords={coords} boardId={boardId} />
      break;
    case SectionIdentifier.IDEAS_AND_IMPROVEMENTS:
      node = <IdeasAndImprovementsCell coords={coords} boardId={boardId} />
      break;
    case SectionIdentifier.PROBLEMS_AND_DIFFICULTIES:
      node = <ProblemsAndDifficultiesCell coords={coords} boardId={boardId} />
      break;
    case SectionIdentifier.ROLES_AND_FLOWS:
      node = <RolesAndFlowsCell coords={coords} boardId={boardId} />
      break;
    case SectionIdentifier.KPIS:
      node = <KpisCell coords={coords} boardId={boardId} />
      break;
    case SectionIdentifier.DOCUMENT_FLOW:
      node = <DocumentFlowCell coords={coords} boardId={boardId} />
      break;
    case SectionIdentifier.COMMENTS:
      node = <CommentCell coords={coords} boardId={boardId} />
      break;
  }

  return (
    <div className="w-full h-full flex items-center justify-center">
      {node}
    </div>
  )
}
