import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useLockCell, useLockRow } from "../../features/locking";
import { CellIcon } from "../cells/CellIcon";
import { CellKpi, GradientColor } from "../cells/CellKpi";
import { InputDialog, InputSpec } from "../controls/InputDialog";
import { CellContentsProps } from "./CellContents";
import { AppDispatch } from "../../store";
import { useDispatch } from "react-redux";
import { setCell, setRow, useCell, useRow } from "../../rootState";
import { useTranslation } from "react-i18next";
import config from "../../config";
import { formatGlobalFloat, parseGlobalFloat } from "../../utils";

export function KpisCell({ coords }: CellContentsProps) {
  const { t, i18n } = useTranslation()
  const data = useCell(coords)
  const [isEditing, setIsEditing] = useState(false)
  const { lock, unlock } = useLockCell(coords)
  const { lock: lockRow, unlock: unlockRow } = useLockRow(coords)
  const dispatch = useDispatch<AppDispatch>()

  const kpiCell = useCell({...coords, column: 0})
  const row = useRow(coords) || []

  const tryEdit = async () => {
    const lockAcquired = coords.column === 0 ? await lockRow() : await lock()
    if (lockAcquired) {
      setIsEditing(true)
    }
  }

  const renderContents = () => {
    if (!data || Object.keys(data).length === 0) {
      return (
        <CellIcon icon={AiOutlinePlus} />
      )
    }

    if (coords.column === 0) {
      let sum: number | undefined = undefined
      let avg: number | undefined = undefined
      let sumStr: string | undefined = undefined
      let avgStr: string | undefined = undefined
      let count = 0
      if (data.type === 'Number') {
        sum = 0
        for (let i = 1; i < row.length; i++) {
          const cell = row[i]
          if (!cell || !cell.value) continue
          try {
            const num = parseGlobalFloat(cell.value)
            if (!isNaN(num)) {
              sum! += num
              count++
            }
          } catch (e) {
            console.error(e)
          }
        }
        avg = Math.round((sum / count) * 100) / 100
        avgStr = formatGlobalFloat(i18n.language, avg, 2)
        sumStr = formatGlobalFloat(i18n.language, sum, 2)
      }
      return (
        <CellKpi
          text={data.name}
          iconName={data.icon}
          sum={count > 0 ? `${data.prefix} ${sumStr} ${data.suffix}` : undefined}
          avg={count > 0 ? `${data.prefix} ${avgStr} ${data.suffix}` : undefined}
        />
      )
    }

    let color: GradientColor | undefined = undefined
    let sum: number | undefined = undefined
    let avg: number | undefined = undefined
    let sumStr: string | undefined = undefined
    let avgStr: string | undefined = undefined

    if (row.length > 1 && row[0].type === 'Number') {
      let minValue = data.value
      let maxValue = data.value
      sum = 0
      let count = 0

      let row2 = row.slice(1)
      row2.forEach((cell, idx) => {
        if (!cell?.value) return
        minValue = Math.min(cell.value, minValue)
        maxValue = Math.max(cell.value, maxValue)

        if (idx <= coords.column - 1) {
          const num = parseGlobalFloat(cell.value)
          if (!isNaN(num)) {
            sum! += num
            count++
          }
        }
      })

      if (row[0].reverse) {
        const temp = minValue
        minValue = maxValue
        maxValue = temp
      }

      if (minValue == data.value) color = GradientColor.GOOD
      if (maxValue == data.value) color = GradientColor.BAD
      if (minValue == maxValue) color = undefined

      avg = Math.round((sum / count) * 100) / 100

      avgStr = formatGlobalFloat(i18n.language, avg, 2)
      sumStr = formatGlobalFloat(i18n.language, sum, 2)
    }

    return (
      <div className="w-full h-full flex items-center justify-center cursor-pointer">
        <CellKpi
          text={`${kpiCell?.prefix || ''} ${formatGlobalFloat(i18n.language, data.value, 2)} ${kpiCell?.suffix || ''}`}
          color={color}
          sum={sum !== undefined ? `${kpiCell.prefix} ${sumStr} ${kpiCell.suffix}` : undefined}
          avg={avg !== undefined ? `${kpiCell.prefix} ${avgStr} ${kpiCell.suffix}` : undefined}
          showStatsOnHover={true}
        />
      </div>
    )
  }

  const renderEditor = () => {
    const inputSpecs: InputSpec[] = coords.column === 0
      ?[
        {
          id: 'type',
          name: t('KPI type choice'),
          type: 'select',
          initialValue: data?.type || 'Text',
          options: [
            { label: t('Text'), value: 'Text' },
            { label: t('Number'), value: 'Number' },
          ],
        },
        {
          id: 'reverse',
          name: t('Reverse min/max colors'),
          type: 'checkbox',
          initialValue: data?.reverse || false,
          precondition: (values) => values?.type === 'Number',
        },
        {
          id: 'name',
          name: t('KPI designation'),
          placeholder: t('Enter a designation for the indicator number'),
          type: 'text',
          initialValue: data?.name || '',
          maxLength: config.maxHeaderLength,
        },
        {
          id: 'icon',
          name: t('Icon'),
          type: 'icon',
          initialValue: data?.icon || '',
          options: [
            { label: t(''), value: '' },
            { label: t('Time'), value: 'Time' },
            { label: t('Cost'), value: 'Cost' },
            { label: t('Competency'), value: 'Competency' },
            { label: t('Inventory'), value: 'Inventory' },
            { label: t('Productivity'), value: 'Productivity' },
            { label: t('Distance'), value: 'Distance' },
          ],
        },
        {
          id: 'prefix',
          name: t('Prefix'),
          placeholder: t('Enter a prefix to be displayed BEFORE the indicator number'),
          type: 'text',
          initialValue: data?.prefix || '',
          maxLength: config.maxKpiPrefixLength,
        },
        {
          id: 'suffix',
          name: t('Suffix'),
          placeholder: t('Enter a suffix to be displayed AFTER the indicator number'),
          type: 'text',
          initialValue: data?.suffix || '',
          maxLength: config.maxKpiPrefixLength,
        },
      ]
      : [
        {
          id: 'value',
          name: t('KPI value'),
          placeholder: t('Enter the value/amount/definition of the indicator here'),
          type: kpiCell?.type === 'Number' ? 'number' : 'text',
          initialValue: data?.value || '',
        },
      ]
    const dialogTitle = coords.column === 0 ? t('Definition | KPI Area') : t('KPI | {{name}}', {name: kpiCell?.name || ''})

    const onClose = async (accepted: boolean, values?: {[id: string]: string | number | boolean}) => {
      if (accepted) {
        if (coords.column === 0 && data && values?.['type'] !== data.type) {
          const newRow = row.map((cell, idx) => {
            if (idx === 0) {
              return {...cell, ...values}
            }
            if (!cell?.value) return cell

            let value = cell.value
            if (values?.['type'] === 'Number') {
              value = parseGlobalFloat(value)
              value = isNaN(value) ? '' : value
            } else {
              value = value.toString()
            }
            return {...cell, value}
          })
          dispatch(setRow(coords, newRow))
        } else {
          dispatch(setCell(coords, {...data, ...values}))
        }
      }
      coords.column === 0 ? await unlockRow() : await unlock()
      setIsEditing(false)
    }

    return <InputDialog title={dialogTitle} inputSpecs={inputSpecs} open={isEditing} onClose={onClose} />
  }

  return (
    <>
      <div className="h-full w-full flex items-center justify-center cursor-pointer" onClick={tryEdit}>
        {renderContents()}
      </div>
      {renderEditor()}
    </>
  )
}
