import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { reloadUser } from "./authenticationSlice"
import { AppDispatch, RootState } from "../../store"
import { Router } from "@remix-run/router"
import { useTranslation } from "react-i18next"

export interface AuthenticationCheckerProps {
  router: Router,
}

export function AuthenticationChecker({ router } : AuthenticationCheckerProps) {
  const token = useSelector((state: RootState) => state.authentication.token)
  const user = useSelector((state: RootState) => state.authentication.user)
  const dispatch = useDispatch<AppDispatch>()
  const { i18n } = useTranslation()

  useEffect(() => {
    if (!token && !router.state.location.pathname.startsWith('/auth')) {
      router.navigate('/auth/login')
      return
    }
    dispatch(reloadUser())
    const interval = setInterval(() => {
      dispatch(reloadUser())
    }, 30000)
    return () => {
      clearInterval(interval)
    }
  }, [token])

  useEffect(() => {
    if (user) {
      i18n.changeLanguage(user.language)
    }
  }, [user])

  return <></>
}
