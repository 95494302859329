import { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { CellIcon } from "../cells/CellIcon";
import { CellText } from "../cells/CellText";
import { CellContentsProps } from "./CellContents";
import { useLockCell } from "../../features/locking";
import { useDispatch, useSelector } from "react-redux";
import { showDialog } from '../../features/actionPlan/actionPlanSlice'
import { RootState } from "../../store";
import { AppDispatch } from "../../store";
import { setCell, useCell } from "../../rootState";
import { useTranslation } from 'react-i18next'
import { numberToLetter } from "../../utils";
import config from "../../config";
import { InputDialog, InputSpec } from "../controls/InputDialog";

export function IdeasAndImprovementsCell({ coords }: CellContentsProps) {
  const { t } = useTranslation()
  const data = useCell(coords)
  const { lock, unlock, isLocked } = useLockCell(coords)
  const [isEditing, setIsEditing] = useState(false)
  const dialogOpen = useSelector((state: RootState) => state.actionPlan.dialogOpen)
  const dispatch = useDispatch<AppDispatch>()

  const onClick = async () => {
    const lockAcquired = await lock()
    let cell = data
    if (!cell) {
      dispatch(setCell(coords, { checkmark: 'unchecked' }))
    } else if (!cell.checkmark) {
      dispatch(setCell(coords, { ...cell, checkmark: 'unchecked' }))
    }
    if (lockAcquired) {
      dispatch(showDialog(coords))
    }
  }

  const renderEditor = () => {
    const inputSpecs: InputSpec[] = [
      {
        id: 'name',
        name: t('Name'),
        placeholder: t('Enter the name of the idea or improvement here'),
        type: 'text',
        initialValue: data?.name || '',
        maxLength: config.maxHeaderLength,
      },
    ]

    const onClose = async (accepted: boolean, values?: {[id: string]: string | number | boolean}) => {
      if (accepted) {
        dispatch(setCell(coords, {...data, ...values}))
      }
      await unlock()
      setIsEditing(false)
    }

    return <InputDialog title={t('Idea | Improvement designation')} inputSpecs={inputSpecs} open={isEditing} onClose={onClose} />
  }

  const tryEdit = async () => {
    if (await lock()) {
      setIsEditing(true)
    }
  }

  useEffect(() => {
    if (dialogOpen === false && isLocked()) {
      unlock()
    }
  }, [isLocked, dialogOpen])

  if (coords.column === 0) {
    return (
      <>
        <div className="h-full w-full flex items-center justify-center cursor-pointer" onClick={() => tryEdit()}>
          <CellText centered={true}>
            {data?.name ?
              <span>{t('Ideas & Improvements')} <span className="font-bold">{data.name}</span></span> :
              <span>{t('Ideas & Improvements')} <span className="font-bold">{numberToLetter(coords.row)}</span></span>
            }
          </CellText>
        </div>
        {renderEditor()}
      </>
    )
  }

  if (!data || Object.keys(data).length === 0) {
    return (
      <div className="h-full w-full flex items-center justify-center cursor-pointer" onClick={() => onClick()}>
        <CellIcon icon={AiOutlinePlus} />
      </div>
    )
  }

  return (
    <div className={`w-full h-full cursor-pointer ${data.checkmark ? 'pr-4' : ''} ${data.checkmark === 'checked' && 'bg-success-200 bg-opacity-25'}`} onClick={() => onClick()}>
      <CellText>{data.text}</CellText>
    </div>
  )
}
