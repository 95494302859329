import { ListBoardMembersResponse } from "share2flow-typedefs"
import config from "../../config"

export async function listBoardMembers(token: string, boardId: string): Promise<ListBoardMembersResponse> {
  const res = await fetch(`${config.apiUrl}/authorization/${boardId}/members`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
  return await res.json()
}
