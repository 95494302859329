import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { Arrows } from "../impure/Arrows";
import { renderDocumentFlowArrowControls } from "../impure/DocumentFlowArrowControls";

interface CellFileProps {
  id: string,
  filename: string,
  url: string,
  isHub: boolean,
  onClick?: () => void,
}

export function CellFile({ id, filename, url, isHub, onClick }: CellFileProps) {
  const isDrawing = useSelector((state: RootState) => state.arrowDrawing.isDrawing)

  const renderImage = () => {
    const extension = url.split('.').pop()
    if (['png', 'jpg', 'jpeg', 'gif'].includes(extension!)) {
      return (
        <a href={url} target="_blank">
          <img src={url} alt={filename} className="absolute left-0 bottom-0 h-1/2 w-2/5 object-cover cursor-zoom-in" />
        </a>
      )
    }
    return (<></>)
  }

  return (
    <div className="relative group w-full h-full flex items-center justify-center">
      {renderImage()}
      <div className={`${isHub ? 'w-10 h-10' : 'w-6 h-6'} m-2 relative`}>
        <div className={`${isHub ? 'bg-primary-blue' : 'bg-primary-orange'} w-full h-full shadow-lg rounded-full cursor-pointer`} onClick={onClick}>
        </div>
        <div className="absolute -top-[4px] left-1/2">
          <Arrows id={id} tag="top" renderControls={renderDocumentFlowArrowControls} defaultColor="#50b2d9" className={`rounded-full ${isHub || isDrawing ? 'w-2.5 h-2.5 -mx-1.5' : 'opacity-0 w-2 h-2 -mx-1'} bg-white border-2 border-primary-blue group-hover:opacity-100`}>
          </Arrows>
        </div>
        <div className="absolute -bottom-[4px] left-1/2">
          <Arrows id={id} tag="bottom" renderControls={renderDocumentFlowArrowControls} defaultColor="#50b2d9" className={`rounded-full ${isHub || isDrawing ? 'w-2.5 h-2.5 -mx-1.5' : 'opacity-0 w-2 h-2 -mx-1'} bg-white border-2 border-primary-blue group-hover:opacity-100`}>
          </Arrows>
        </div>
        <div className="absolute -left-[4px] top-1/2">
          <Arrows id={id} tag="left" renderControls={renderDocumentFlowArrowControls} defaultColor="#50b2d9" className={`rounded-full ${isHub || isDrawing ? 'w-2.5 h-2.5 -my-1.5' : 'opacity-0 w-2 h-2 -my-1'} bg-white border-2 border-primary-blue group-hover:opacity-100`}>
          </Arrows>
        </div>
        <div className="absolute -right-[4px] top-1/2">
          <Arrows id={id} tag="right" renderControls={renderDocumentFlowArrowControls} defaultColor="#50b2d9" className={`rounded-full ${isHub || isDrawing ? 'w-2.5 h-2.5 -my-1.5' : 'opacity-0 w-2 h-2 -my-1'} bg-white border-2 border-primary-blue group-hover:opacity-100`}>
          </Arrows>
        </div>
      </div>
      <div className="absolute z-[100] top-0 bg-white px-3 py-1 rounded-md shadow-md transition-opacity duration-200 ease-in-out opacity-0 group-hover:opacity-70 pointer-events-none">
        {filename}
      </div>
    </div>
  )
}
