import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, Line } from '@react-pdf/renderer'
import { Transaction } from "share2flow-typedefs"
import { LoadingState } from "../../components/controls/LoadingState"
import { AppDispatch, RootState } from "../../store"
import { formatDateLong, monthToShort } from "../../utils"
import { NotificationMessageType, showNotificationMessage } from "../notificationMessages/notificationMessagesSlice"
import { allTransactions } from "./api"


const invoiceStyles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF'
  },
  section: {
    margin: 10,
    padding: 10,
  },
  headerSection: {
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    fontSize: 24,
    fontWeight: 'bold',
  },
  detailsSection: {
    fontSize: 10,
    flexDirection: 'row',
  },
  detailsColumn: {
    paddingTop: 20,
    paddingLeft: 30,
    paddingRight: 30,
    flexDirection: 'column',
    flexGrow: 1,
  },
  columnHeader: {
    fontWeight: 'bold',
    fontSize: 12,
    marginBottom: 5,
  },
  summarySection: {
    paddingTop: 25,
    paddingLeft: 20,
    paddingRight: 20,
    fontSize: 10,
    flexDirection: 'column',
  },
  summaryHeader: {
    fontWeight: 'bold',
    fontSize: 12,
    marginBottom: 5,
    paddingBottom: 5,
    borderBottom: '1px solid #000000',
  },
  summaryRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  summaryRowUnderlined: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
    paddingBottom: 5,
    borderBottom: '1px dotted #000000',
  },
  summaryRowTotal: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderTop: '1px solid #000000',
    paddingTop: 5,
  },
});

interface InvoiceDocumentProps {
  t: (text: string) => string
  language: string
  transaction: Transaction
}

const InvoiceDocument = ({ t, language, transaction }: InvoiceDocumentProps) => {
  const amountParts = transaction.amount.toString().split('.')
  const amountCents = Number(amountParts[0]) * 100 + Number(amountParts[1])
  const taxCents = Math.ceil(amountCents * 0.19 / (1 + 0.19))
  const netCents = amountCents - taxCents

  const amount = `${amountParts[0]},${amountParts[1]} €`
  const tax = `${Math.floor(taxCents / 100)},${taxCents % 100} €`
  const net = `${Math.floor(netCents / 100)},${netCents % 100} €`

  return (
    <Document>
      <Page size="A4" style={invoiceStyles.page}>
        <View style={invoiceStyles.headerSection}>
          <Text>Share2Flow {t('Invoice')}</Text>
        </View>
        <View style={invoiceStyles.detailsSection}>
          <View style={invoiceStyles.detailsColumn}>
            <Text style={invoiceStyles.columnHeader}>{t('From')}</Text>
            <Text>Share2Flow</Text>
            <Text>Example Street 123</Text>
            <Text>12345 Example City</Text>
            <Text>Germany</Text>
            <Text>VAT-ID: DE123456789</Text>
          </View>
          <View style={invoiceStyles.detailsColumn}>
            <Text style={invoiceStyles.columnHeader}>{t('Invoice details')}</Text>
            <Text>{t('Invoice number')}: #{transaction.id}</Text>
            <Text>{t('Invoice date')}: {formatDateLong(language, new Date(transaction.createdAt))}</Text>
          </View>
        </View>
        <View style={invoiceStyles.detailsSection}>
          <View style={invoiceStyles.detailsColumn}>
            <Text style={invoiceStyles.columnHeader}>{t('Billing details')}</Text>
            <Text>{transaction.billing?.firstName} {transaction.billing?.lastName}</Text>
            <Text>{transaction.billing?.streetAddress}</Text>
            <Text>{transaction.billing?.postalCode} {transaction.billing?.locality}</Text>
            <Text>{transaction.billing?.countryName}</Text>
          </View>
        </View>
        <View style={invoiceStyles.summarySection}>
          <Text style={invoiceStyles.summaryHeader}>{t('Plan')}</Text>
          <View style={invoiceStyles.summaryRowUnderlined}>
            <Text>{transaction.planId}</Text>
            <Text>{amount}</Text>
          </View>
        </View>
        <View style={invoiceStyles.summarySection}>
          <Text style={invoiceStyles.summaryHeader}>{t('Summary')}</Text>
          <View style={invoiceStyles.summaryRowUnderlined}>
            <Text>{t('Subtotal')}</Text>
            <Text>{net}</Text>
          </View>
          <View style={invoiceStyles.summaryRow}>
            <Text>{t('VAT Germany')} (19%)</Text>
            <Text>{tax}</Text>
          </View>
          <View style={invoiceStyles.summaryRowTotal}>
            <Text>{t('Total')}</Text>
            <Text>{amount}</Text>
          </View>
        </View>
      </Page>
    </Document>
  )
};

export function History() {
  const { t, i18n } = useTranslation()
  const token = useSelector((state: RootState) => state.authentication.token)
  const user = useSelector((state: RootState) => state.authentication.user)
  const dispatch = useDispatch<AppDispatch>()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear())
  const [transactions, setTransactions] = useState<Transaction[]>([])

  const years = useMemo(() => {
    if (!user?.insertedAt) return null
    const currentYear = new Date().getFullYear()
    const startYear = new Date(user?.insertedAt).getFullYear()
    const years = []
    for (let i = startYear; i <= currentYear; i++) {
      years.push(i)
    }
    return years
  }, [user])

  useEffect(() => {
    if (!token) return

    const updateTransactions = async () => {
      try {
        setIsLoading(true)
        const transactions = await allTransactions(token, selectedYear)
        setTransactions(transactions.transactions)
      } catch (e: any) {
        dispatch(showNotificationMessage({
          type: NotificationMessageType.Error,
          title: t('Error fetching transactions'),
          body: e.toString(),
        }))
      } finally {
        setIsLoading(false)
      }
    }
    updateTransactions()
  }, [token, selectedYear])

  return (
    <div className="bootstrap">
      <main className="main-section" id="main-section">
        <div className="container">
          <div className="inner_container">
            <div className="row">
              <div className="col-lg-10 col-xl-8">

                <div className="d-lg-flex align-items-center mb-2">
                  <h3 className="fs_lg fw_medium mb-4 me-lg-5">
                    {t('Invoice Overview')}
                  </h3>
                  <div className="d-flex fs_sm align-items-center mb-4 me-lg-4">
                    <div className="me-3">
                      {t('Year')}: 
                    </div>
                    <div className="flex-grow-1">
                      <select className="form-select w-100" aria-label={t('Year')} value={selectedYear} onChange={e => setSelectedYear(Number(e.target.value))}>
                        {years?.map((year, index) => (
                          <option key={index} value={year}>{year}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                {/* invoice list */}
                <div className="pb-4">
                  <LoadingState isLoading={isLoading}>
                    {transactions.length === 0
                      ? (
                        <div className="text-center">
                          <p className="c_gray2 mb-3">{t('No invoices found')}</p>
                        </div>
                      )
                      : (
                        <ul className="invoice_list list-unstyled ps-0">
                          {transactions.map((transaction, index) => {
                            const date = new Date(transaction.createdAt)
                            return (
                              <li key={index} className="invoice_item c_gray2 d-flex align-items-start">
                                <div className="invoice_date text-end">
                                  {date.getDay()} {monthToShort(i18n.language, date.getMonth())} <br />
                                  <span className="c_gray">{date.getFullYear()}</span>
                                </div>
                                <div className="rounded-3 border py-2 px-3 d-flex justify-content-between flex-grow-1">
                                  <div className="me-2">
                                    {t('Invoice')} <PDFDownloadLink document={<InvoiceDocument t={t} language={i18n.language} transaction={transaction} />} fileName={`invoice-${transaction.id}.pdf`} className="hover_underline c_gray2 cursor-pointer">#{transaction.id}</PDFDownloadLink>
                                  </div>
                                  <div>
                                    <b className="me-2">{transaction.amount} €</b>
                                    <PDFDownloadLink document={<InvoiceDocument t={t} language={i18n.language} transaction={transaction} />} fileName={`invoice-${transaction.id}.pdf`} className="c_primary text-decoration-none cursor-pointer">
                                      <i className="fi fi-rr-cloud-download"></i>
                                    </PDFDownloadLink>
                                  </div>
                                </div>
                              </li>
                            )
                          })}
                        </ul>
                      )}
                  </LoadingState>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}
