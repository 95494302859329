import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { setBoardId } from '../../socketState';
import { AppDispatch, RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { clearBoard } from '../../rootState';
import { clearCursors } from '../userCursors/userCursorsSlice';
import { Sections } from './Board';

function BoardPrint() {
  const params = useParams()
  const boardId = params.boardId!

  const token = useSelector((state: RootState) => state.authentication.token)
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    if (token) {
      dispatch(clearBoard())
      dispatch(clearCursors())
      dispatch(setBoardId(boardId))
    }
  }, [boardId, token])

  const board = useSelector((state: RootState) => state.boardState.board)

  if (!board) return <div>Connecting...</div>

  return (
    <>
      <div className="flex flex-col py-4 px-4">
        <h1 className="text-lg lg:text-3xl text-gray-700 font-bold leading-relaxed flex items-center gap-2 line-clamp-1">{board?.boardInfo?.title}</h1>
        <p className="lg:text-lg text-gray-600 line-clamp-1 whitespace-pre-wrap">{board?.boardInfo?.description}</p>
      </div>
      <Sections boardId={boardId} />
      <div id="rendered"></div>
    </>
  )
}

export default BoardPrint
