import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface CursorCoords {
  x: number,
  y: number,
}

export interface UserCursorInfoUpdate {
  user: string,
  avatarUrl: string,
  coords: CursorCoords,
}

export interface UserCursorInfo {
  user: string,
  avatarUrl: string,
  coords: CursorCoords,
  receivedAt: number,
}

export type UserCursorInfos = {[user: string]: UserCursorInfo}

export interface UserCursorState {
  showCursors: boolean,
  userCursorInfos: UserCursorInfos,
}

const initialState: UserCursorState = {
  showCursors: true,
  userCursorInfos: {},
}

export const userCursorsSlice = createSlice({
  name: 'userCursors',
  initialState,
  reducers: {
    toggleCursors: (state) => {
      state.showCursors = !state.showCursors
    },
    updateCursor: (state, action: PayloadAction<UserCursorInfoUpdate>) => {
      state.userCursorInfos[action.payload.user] = {
        receivedAt: Date.now(),
        ...action.payload
      }
    },
    clearCursors: (state) => {
      state.userCursorInfos = {}
    },
  },
})

export const { toggleCursors, updateCursor, clearCursors } = userCursorsSlice.actions

export default userCursorsSlice.reducer
