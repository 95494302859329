import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { BoardState, newBoardState, dispatchEvent, SectionIdentifier, Action, CellCoords } from "share2flow-board"
import { RichBoardItem } from "share2flow-typedefs"
import { Socket } from "socket.io-client"
import { LoadingState } from "../../components/controls/LoadingState"
import { PopupButton } from "../../components/controls/PopupButton"
import { PopupMenu } from "../../components/controls/PopupMenu"
import { createSocket } from "../../socketState"
import { AppDispatch, RootState } from "../../store"
import { getStatuses } from "../actionPlan/statuses"
import { listRecentBoards } from "../boards/api"
import { NotificationMessageType, showNotificationMessage } from "../notificationMessages/notificationMessagesSlice"

const SortByOptions = ['Board Title', 'ID', 'Bottleneck', 'Improvement', 'Name', 'End Date', 'Status', 'Level'] as const
type SortBy = (typeof SortByOptions)[number]

export function Todo() {
  const { t } = useTranslation()

  const token = useSelector((state: RootState) => state.authentication.token)
  const dispatch = useDispatch<AppDispatch>()

  const [recentBoards, setRecentBoards] = useState<RichBoardItem[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isBoardLoaded, setIsBoardLoaded] = useState<boolean>(false)

  const [selectedBoardId, setSelectedBoardId] = useState<string>('')
  const [sockets, setSockets] = useState<{[boardId: string]: Socket}>({})
  const [boards, setBoards] = useState<{[boardId: string]: BoardState}>({})

  const [sortBy, setSortBy] = useState<SortBy>('Board Title')
  const [filterBy, setFilterBy] = useState<string>('')

  // Update board list
  useEffect(() => {
    setIsLoading(true)

    const updateBoards = async () => {
      try {
        const res = await listRecentBoards(token!, -1)
        setRecentBoards(res)
      } catch (e: any) {
        dispatch(showNotificationMessage({
          type: NotificationMessageType.Error,
          title: 'Error retrieving recent boards',
          body: e.message,
        }))
      } finally {
        setIsLoading(false)
      }
    }

    updateBoards()
  }, [])

  // Connect socket using selectedBoardId
  useEffect(() => {
    setSockets({})
    setBoards({})
    setIsBoardLoaded(false)

    const connectSocket = async (token: string, boardId: string) => {
      const newSocket = createSocket(token, boardId)
      newSocket.on('action', (payload) => {
        setBoards((boards) => {
          if (payload.type === 'boardState/init') {
            setIsBoardLoaded(() => true)
            const newBoard = newBoardState(payload.payload)
            return {...boards, [boardId]: newBoard}
          }

          if (boards[boardId]) {
            const newBoard = newBoardState(boards[boardId])
            dispatchEvent(newBoard, payload.payload.action, payload.payload.args)
            return {...boards, [boardId]: newBoard}
          }

          return boards
        })
      })
      newSocket.on('connect', () => {
        newSocket.emit('requestInit')
      })
      setSockets((sockets) => ({...sockets, [boardId]: newSocket}))
    }

    if (token) {
      if (selectedBoardId) {
        connectSocket(token, selectedBoardId)
      } else {
        for (let i = 0; i < recentBoards.length; i++) {
          connectSocket(token, recentBoards[i].id)
        }
      }
    }

    return () => {
      for (const boardId in sockets) {
        sockets[boardId].disconnect()
      }
    }
  }, [token, recentBoards, selectedBoardId])

  const getTodoId = (coords: CellCoords): string => {
    let rowChar = ''
    if (coords.row >= 26) {
      rowChar += String.fromCharCode('A'.charCodeAt(0) + Math.floor(coords.row / 26))
    }
    rowChar += String.fromCharCode('A'.charCodeAt(0) + (coords.row % 26))

    const id = `I${rowChar}${coords.column + 1}`
    return id
  }

  const sortedFilteredTodos = useMemo(() => {
    let result = []

    // Get non-empty cells
    for (const boardId in boards) {
      const board = boards[boardId]
      for (let rowIdx = 0; rowIdx < board.sections[SectionIdentifier.IDEAS_AND_IMPROVEMENTS].length; rowIdx++) {
        for (let colIdx = 0; colIdx < board.sections[SectionIdentifier.IDEAS_AND_IMPROVEMENTS][rowIdx].length; colIdx++) {
          const cell = board.sections[SectionIdentifier.IDEAS_AND_IMPROVEMENTS][rowIdx][colIdx]
          if (cell?.id) {
            // Supplement cells with additional fields
            let cellClone = {...cell}
            cellClone.boardId = boardId
            cellClone.boardTitle = board.boardInfo.title
            const coords: CellCoords = {section: SectionIdentifier.IDEAS_AND_IMPROVEMENTS, row: rowIdx, column: colIdx}
            cellClone.coords = coords
            cellClone.coordsId = getTodoId(coords)
            cellClone.statusText = t(cell.status)
            cellClone.fullText = [...Object.values(cellClone), board.boardInfo.title].join(',').toLowerCase()
            result.push(cellClone)
          }
        }
      }
    }

    // Sort cells
    result.sort((a, b) => {
      switch (sortBy) {
        case 'ID':
          return a.coordsId.localeCompare(b.coordsId)
        case 'Name':
          return a.name?.localeCompare(b.name ?? '')
        case 'Level':
          return a.level?.localeCompare(b.level ?? '')
        case 'Status':
          return a.statusText?.localeCompare(b.statusText ?? '')
        case 'End Date':
          if (a.endDate && b.endDate) {
            return (new Date(b.endDate) as any) - (new Date(a.date) as any)
          } else if (a.endDate) {
            return 1
          } else if (b.endDate) {
            return -1
          }
          return 0
        case 'Bottleneck':
          return a.bottleNeck?.localeCompare(b.bottleNeck ?? '')
        case 'Board Title':
          return a.boardTitle?.localeCompare(b.boardTitle ?? '')
        case 'Improvement':
          return a.text?.localeCompare(b.text ?? '')
      }
    })

    // Filter cells
    const filterByLC = filterBy.toLowerCase()
    result = filterBy ? result.filter((cell) => cell.fullText.includes(filterByLC)) : result

    return result
  }, [boards, sortBy, filterBy])

  const setStatus = (boardId: string, coords: CellCoords, status: string) => {
    const cell = boards[boardId]?.sections[coords.section][coords.row][coords.column]
    if (!cell) return
    const newCell = {...cell, status, checkmark: status === 'Completed' ? 'checked' : 'unchecked'}
    sockets[boardId]?.emit('action', {action: Action.setCell, args: {coords, cell: newCell}})
  }

  return (
    <div className="bootstrap">
      {/* .................. start page header .................. */}
      <div className="page-header pt-3 bg_light">
        <div className="container">
          <h1 className="page-title">
            To Do´s
          </h1>
        </div>
      </div>
      {/* .................. end page header .................. */}

      {/* .................. start main section .................. */}
      <main className="main-section">
        <div className="container">
          <div className="inner_container">
            <LoadingState isLoading={isLoading}>
              {/* filter area */}
              <div className="pb-4">
                <form action="#" autoComplete="off">
                  <div className="row gy-3">
                    <div className="col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                      <select className="form-select" aria-label="Board" value={selectedBoardId} onChange={(e) => setSelectedBoardId(e.target.value)}>
                        <option value="">{t('All boards')}</option>
                        {recentBoards?.map((board) =>
                          <option key={board.id} value={board.id}>{board.title}</option>
                        )}
                      </select>
                    </div>
                    <div className="col-md-6 col-lg-4 col-xl-3">
                      <div className="d-flex fs_sm align-items-center">
                        <div className="me-3">
                          {t('Sort by')}: 
                        </div>
                        <div className="flex-grow-1">
                          <select className="form-select w-100" aria-label="Sort by" value={sortBy} onChange={(e) => setSortBy(e.target.value as any)}>
                            {SortByOptions.map((option) =>
                              <option key={option} value={option}>{t(option)}</option>
                            )}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-4 col-xl-4 ">
                      <div className="input-group">
                        <span className="input-group-text bg_white">
                          <i className="fi fi-rr-search search_icon c_gray"></i>
                        </span>
                        <input className="form-control border-start-0" type="search" placeholder={t('Search')} value={filterBy} onChange={(e) => setFilterBy(e.target.value)} />
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              {/* table */}
              <LoadingState isLoading={!isBoardLoaded}>
                <div>
                  <table className="table table-borderless todo_table mobile_responsiveTable">
                    <thead>
                      <tr>
                        <th>{t('Board Title')}</th>
                        <th>{t('ID')}</th>
                        <th>{t('Bottleneck')}</th>
                        <th>{t('Improvement')}</th>
                        <th>{t('Name')} ({t('User')}/{t('Guest')})</th>
                        <th>{t('End Date')}</th>
                        <th>{t('Status')}</th>
                        <th>{t('Level')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortedFilteredTodos.map((todo) =>
                        <tr key={todo.id}>
                          <td data-label="BOARD TITLE">
                            <div>
                              {todo.boardTitle}
                            </div>
                          </td>
                          <td data-label="Id">
                            <div>
                              {todo.coordsId}
                            </div>
                          </td>
                          <td data-label="Bottleneck">
                            <div>
                              {todo.bottleNeck}
                            </div>
                          </td>
                          <td data-label="Improvement">
                            <div>
                              {todo.text}
                            </div>
                          </td>
                          <td data-label="Name (User/Guest)">
                            <div>
                              {todo.name}
                            </div>
                          </td>
                          <td data-label="End Date">
                            <div>
                              {todo.endDate}
                            </div>
                          </td>
                          <td data-label="Status">
                            <StatusTableCell cell={todo} onSetStatus={(status) => setStatus(todo.boardId, todo.coords, status)} />
                          </td>
                          <td data-label="Level">
                            <div>
                              {todo.level}
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </LoadingState>
            </LoadingState>
          </div>
        </div>
      </main>
      {/* .................. end main section .................. */}
    </div>
  )
}

interface StatusTableCellProps {
  cell: any,
  onSetStatus: (status: string) => void,
}

function StatusTableCell({ cell, onSetStatus }: StatusTableCellProps) {
  const { t } = useTranslation()
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false)

  const statuses = getStatuses(t)

  const setStatus = async (status: string) => {
    onSetStatus(status)
    setDropdownVisible(false)
  }

  const onClick = () => {
    setDropdownVisible(true)
  }

  return (
    <div className="relative flex flex-col items-center justify-center text-center text-sm">
      {cell.statusText
        ? <div className="text-green-700 bg-green-50 rounded-full px-3 py-1 cursor-pointer" onClick={onClick}>
          {cell.statusText}
        </div>
        : <div className="flex-1 w-full h-full min-h-[2rem] cursor-pointer" onClick={onClick}>&nbsp;</div>
      }
      <div className={`absolute top-5 inset-0 ${dropdownVisible ? '' : 'pointer-events-none'}`}>
        <PopupMenu isVisible={dropdownVisible} onDismiss={() => setDropdownVisible(false)} width={200}>
          {Object.entries(statuses).map(([key, value]) => (
            <PopupButton
              key={key}
              iconName="x"
              text={value}
              onClick={() => setStatus(key)}
            />
          ))}
        </PopupMenu>
      </div>
    </div>
  )
}
