import { GiArrowCursor } from 'react-icons/gi'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { AppDispatch } from "../../store";
import { sendMessage } from '../../socketState';
import { ColumnGroup } from 'share2flow-board';
import { columnWidth } from '../../utils';

export interface CursorCoords {
  x: number,
  y: number,
}

export interface UserCursorInfo {
  user: string,
  coords: CursorCoords,
  receivedAt: number,
}

export type UserCursorInfos = {[user: string]: UserCursorInfo}

export interface CursorsProps {
  element: React.RefObject<HTMLDivElement>,
  columnGroups: ColumnGroup[]
  collapsedColumnGroups: string[]
}

export function Cursors({ element, columnGroups, collapsedColumnGroups }: CursorsProps) {
  const user = useSelector((state: RootState) => state.authentication.user)
  const showCursors = useSelector((state: RootState) => state.userCursors.showCursors)
  const cursorInfos = useSelector((state: RootState) => state.userCursors.userCursorInfos)
  const dispatch = useDispatch<AppDispatch>()

  const [currentClientCoords, setCurrentClientCoords] = useState<CursorCoords>({ x: -1000, y: -1000 })
  const [lastCursorCoords, setLastCursorCoords] = useState<CursorCoords>({ x: -1000, y: -1000 })

  const zoomScale = useSelector((state: RootState) => state.zoom.scale)

  const sendCursorUpdate = (force: boolean) => {
    if (!element.current) return

    const x = currentClientCoords.x - element.current!.offsetLeft + element.current!.scrollLeft
    const y = currentClientCoords.y - element.current!.offsetTop + element.current!.scrollTop

    const deltaX = (x - lastCursorCoords.x)
    const deltaY = (y - lastCursorCoords.y)

    if (!force && deltaX*deltaX + deltaY*deltaY < 500) {
      return
    }

    // Adjust for collapsed groups
    let adjustedX = x / zoomScale
    let adjustedY = y / zoomScale

    for (let i = 1; (i + 1) * columnWidth < adjustedX; i++) {
      const columnGroup = columnGroups.find((columnGroup) => columnGroup.startColumn === i)
      if (!columnGroup) continue
      if (collapsedColumnGroups.includes(columnGroup.id)) {
        // If the group that the mouse is on is collapsed, scale x by the number of columns in the group
        if ((i + 2) * columnWidth > adjustedX) {
          adjustedX += (adjustedX % columnWidth) * (columnGroup.endColumn - columnGroup.startColumn)
        } else {
          adjustedX += columnWidth * (columnGroup.endColumn - columnGroup.startColumn)
        }
      }
    }

    setLastCursorCoords({ x, y })

    dispatch(sendMessage({ event: 'cursor', data: { x: adjustedX, y: adjustedY } }))
  }

  const onSectionsMouseMove = (ev: MouseEvent) => {
    const x = ev.clientX
    const y = ev.clientY

    setCurrentClientCoords({ x, y })
  }

  useEffect(() => {
    if (!element.current) {
      return
    }

    element.current!.addEventListener("mousemove", onSectionsMouseMove)

    return () => {
      if (!element.current) {
        return
      }

      element.current!.removeEventListener("mousemove", onSectionsMouseMove)
    }
  }, [element])

  useEffect(() => {
    sendCursorUpdate(false)

    const interval = setInterval(() => {
      sendCursorUpdate(true)
    }, 500)

    return () => {
      clearInterval(interval)
    }
  }, [element, currentClientCoords, lastCursorCoords])

  if (!element.current || !showCursors) {
    return <></>
  }

  const now = Date.now()

  return (
    <div>
      {Object.values(cursorInfos).map((cursor) => {
        if (cursor.user === user?.name || now - cursor.receivedAt > 5000) {
          return <div key={`cursor-${cursor.user}`}></div>
        }

        // Adjust for collapsed groups
        let x = cursor.coords.x
        let adjustedX = x
        for (let i = 1; (i + 1) * columnWidth < x; i++) {
          const columnGroup = columnGroups.find((columnGroup) => columnGroup.startColumn === i)
          if (!columnGroup) continue
          if (collapsedColumnGroups.includes(columnGroup.id)) {
            // If the group that the mouse is on is collapsed, scale x by the number of columns in the group
            if ((i + 2 + columnGroup.endColumn - columnGroup.startColumn) * columnWidth > x) {
              const delta = x - (columnWidth * (columnGroup.startColumn + 1))
              const ratio = delta / (columnWidth * (columnGroup.endColumn - columnGroup.startColumn + 1))
              adjustedX -= delta - ratio * columnWidth
            } else {
              adjustedX -= columnWidth * (columnGroup.endColumn - columnGroup.startColumn)
            }
          }
        }

        adjustedX *= zoomScale
        const adjustedY = cursor.coords.y * zoomScale

        return <div
          className="bg-white px-3 py-2 fixed z-[1000] pointer-events-none shadow-md rounded-md opacity-80 transition-all duration-100 ease-linear"
          key={`cursor-${cursor.user}`}
          style={{ left: adjustedX + element.current!.offsetLeft - element.current!.scrollLeft, top: adjustedY + element.current!.offsetTop - element.current!.scrollTop }}
        >
          {cursor.user}
          <div className="absolute -left-1 -top-1">
            <GiArrowCursor />
          </div>
        </div>
      })}
    </div>
  )
}
