import { Textfit } from 'react-textfit';
import { renderFlowsArrowControls } from '../impure/FlowsArrowControls';
import { Arrows } from './../impure/Arrows';

interface CellDecisionProps {
  id: string,
  text: string,
  onClick?: () => void,
}

export function CellDecision({ id, text, onClick }: CellDecisionProps) {
  return (
    <>
      <div className="absolute inset-0 text-xs flex flex-col items-center justify-center cursor-pointer" onClick={onClick}>
        <div className="absolute inset-0">
          <svg viewBox="0 0 200 100" xmlns="http://www.w3.org/2000/svg">
            <path d="M 20 50 C 40 27, 75 17, 100 15" fill="none" stroke="rgb(122 46 181)" strokeWidth="4" />
            <path d="M 20 50 C 40 73, 75 83, 100 85" fill="none" stroke="rgb(122 46 181)" strokeWidth="4" />

            <path d="M 180 50 C 160 27, 125 17, 100 15" fill="none" stroke="rgb(122 46 181)" strokeWidth="4" />
            <path d="M 180 50 C 160 73, 125 83, 100 85" fill="none" stroke="rgb(122 46 181)" strokeWidth="4" />
          </svg>
        </div>
        <Textfit mode="multi" max={16} className="flex items-center justify-center absolute inset-0 z-10 my-[1.8rem] mx-[2.2rem] text-center leading-none">
          {text}
        </Textfit>
      </div>
      <div className="absolute z-[20] top-[10px]">
        <Arrows id={id} tag="top" renderControls={renderFlowsArrowControls} className="bg-white border-2 border-black w-3 h-3 rounded-full">
        </Arrows>
      </div>
      <div className="absolute z-[20] bottom-[10px]">
        <Arrows id={id} tag="bottom" renderControls={renderFlowsArrowControls} className="bg-white border-2 border-black w-3 h-3 rounded-full">
        </Arrows>
      </div>
      <div className="absolute z-[20] left-[15px]">
        <Arrows id={id} tag="left" renderControls={renderFlowsArrowControls} className="bg-white border-2 border-black w-3 h-3 rounded-full">
        </Arrows>
      </div>
      <div className="absolute z-[20] right-[15px]">
        <Arrows id={id} tag="right" renderControls={renderFlowsArrowControls} className="bg-white border-2 border-black w-3 h-3 rounded-full">
        </Arrows>
      </div>
    </>
  );
}
