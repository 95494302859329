import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ClipboardState {
  contents: any,
}

const initialState: ClipboardState = {
  contents: undefined,
}

export const clipboardSlice = createSlice({
  name: 'clipboard',
  initialState,
  reducers: {
    setClipboardContents: (state, action: PayloadAction<any>) => {
      state.contents = action.payload
    },
    clearClipboardContents: (state) => {
      state.contents = undefined
    },
  },
})

export const { setClipboardContents, clearClipboardContents } = clipboardSlice.actions

export default clipboardSlice.reducer
