import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { AiFillStar, AiOutlineStar } from "react-icons/ai"
import { FaPlay } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../store"
import { NotificationMessageType, showNotificationMessage } from "../notificationMessages/notificationMessagesSlice"
import { VideoCategory, getVideoCategories, getVideos, Video, getFavoriteVideos, removeVideoFromFavorites, addVideoToFavorites } from "./api"

export function Videos() {
  const { t, i18n } = useTranslation()
  const token = useSelector((state: RootState) => state.authentication.token)
  const dispatch = useDispatch<AppDispatch>()

  const [videoCategories, setVideoCategories] = useState<VideoCategory[]>([])
  const [videos, setVideos] = useState<Video[]>([])
  const [favorites, setFavorites] = useState<string[]>([])
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null)
  const [search, setSearch] = useState<string>('')

  const updateVideos = async () => {
    try {
      const categories = await getVideoCategories(i18n.language)
      setVideoCategories(categories)
      const videos = await getVideos(i18n.language)
      setVideos(videos)
      const favorites = await getFavoriteVideos(token!)
      setFavorites(favorites.favoriteVideos)
    } catch (e: any) {
      dispatch(showNotificationMessage({
        type: NotificationMessageType.Error,
        title: t('Error Enabling Auth App 2FA'),
        body: e.toString(),
      }))
    }
  }

  useEffect(() => {
    updateVideos()
  }, [i18n.language])

  const addToFavorites = async (slug: string) => {
    try {
      await addVideoToFavorites(token!, slug)
      setFavorites([...favorites, slug])
    } catch (e: any) {
      dispatch(showNotificationMessage({
        type: NotificationMessageType.Error,
        title: t('Error Enabling Auth App 2FA'),
        body: e.toString(),
      }))
    }
  }

  const removeFromFavorites = async (slug: string) => {
    try {
      await removeVideoFromFavorites(token!, slug)
      setFavorites(favorites.filter((favorite) => favorite !== slug))
    } catch (e: any) {
      dispatch(showNotificationMessage({
        type: NotificationMessageType.Error,
        title: t('Error Enabling Auth App 2FA'),
        body: e.toString(),
      }))
    }
  }

  const isFavorite = (slug: string) => {
    return favorites.includes(slug)
  }

  const favoriteVideos = useMemo(() => {
    return videos.filter((video) => isFavorite(video.slug))
  }, [videos, favorites])

  const toggleSelectedCategory = (slug: string) => {
    if (selectedCategory === slug) {
      setSelectedCategory(null)
    } else {
      setSelectedCategory(slug)
    }
  }

  const filteredCategories = useMemo(() => {
    if (!selectedCategory) {
      return videoCategories
    }
    return videoCategories.filter((category) => category.slug === selectedCategory)
  }, [videoCategories, selectedCategory])

  const filteredVideos = useMemo(() => {
    if (!search) {
      return videos
    }
    return videos.filter((video) => video.title.toLowerCase().includes(search.toLowerCase()) || video.category?.title.toLowerCase().includes(search.toLowerCase()))
  }, [videos, search])

  return (
    <div className="bootstrap">
      {/* .................. start page header .................. */}
      <div className="page-header pt-3 bg_light">
        <div className="container">
          <h1 className="page-title">
            {t('Videos')}
          </h1>
        </div>
      </div>
      {/* .................. end page header .................. */}

      {/* .................. start main section .................. */}
      <main className="main-section">
        <div className="container">
          <div className="pb-4">
            <div className="ctg_filterOpts">
              {videoCategories.map((category) => (
                <div key={category.slug} className={`ctg_filterOpt cursor-pointer ${selectedCategory === category.slug ? 'active' : ''}`} style={{ backgroundImage: `url('${category.image}')` }} onClick={() => toggleSelectedCategory(category.slug)}>
                  <div className="ctg_filterOptOverlay" style={{ backgroundColor: category.overlayColor }}>
                    {category.title}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="d-md-flex justify-content-end align-items-center">
            <div className="">
              <div className="input-group">
                <span className="input-group-text bg_white">
                  <i className="fi fi-rr-search search_icon c_gray"></i>
                </span>
                <input className="form-control border-start-0" type="search" placeholder="Search" aria-label="Search" value={search} onChange={(e) => setSearch(e.target.value)} />
              </div>
            </div>
          </div>

          {!selectedCategory && !search && favoriteVideos.length > 0 && (
            <div className="py-5 border-bottom">
              <div className="">
                <h3 className="fs_lg fw_medium mb-4">
                  {t('My Favorite Videos')}
                </h3>
              </div>
              <div className="row gy-4">
                {favoriteVideos.map((video) => (
                  <VideoCard key={video.slug} video={video} isFavorite={isFavorite(video.slug)} addToFavorites={addToFavorites} removeFromFavorites={removeFromFavorites} setSelectedCategory={setSelectedCategory} />
                ))}
              </div>
            </div>
          )}

          {filteredCategories.map((category) => {
            const videos = filteredVideos.filter((video) => video.category?.slug === category.slug)
            if (videos.length === 0) {
              return null
            }
            return (
              <div key={category.slug} className="py-5 border-bottom">
                <div className="">
                  <h3 className="fs_lg fw_medium mb-4">
                    {category.title}
                  </h3>
                </div>
                <div className="row gy-4">
                  {videos.map((video) => (
                    <VideoCard key={video.slug} video={video} isFavorite={isFavorite(video.slug)} addToFavorites={addToFavorites} removeFromFavorites={removeFromFavorites} setSelectedCategory={setSelectedCategory} />
                  ))}
                </div>
              </div>
            )
          })}
        </div>
      </main>
      {/* .................. end main section .................. */}
    </div>
  )
}

interface VideoCardProps {
  video: Video
  isFavorite: boolean
  addToFavorites: (slug: string) => void
  removeFromFavorites: (slug: string) => void
  setSelectedCategory: (slug: string) => void
}

function VideoCard({ video, isFavorite, addToFavorites, removeFromFavorites, setSelectedCategory }: VideoCardProps) {
  return (
    <div className="col-sm-6 col-lg-4 col-xl-3">
      <div className="border rounded-3 overflow-hidden video_card" style={{ backgroundColor: video.category?.backgroundColor }}>
        <div className="position-relative">
          <img className="w-full aspect-video object-cover" src={video.image} alt="thumbnail" />

          <a className="position-absolute top-50 start-50 translate-middle btn btn_play" href={video.url} target="_blank">
            <FaPlay size={"16px"} />
          </a>

          <div className="position-absolute top-0 start-0 ps-2 pt-2">
            <a className="card_starLabel cursor-pointer">
              {isFavorite ? (
                <AiFillStar size={"24px"} onClick={() => removeFromFavorites(video.slug)} />
              ) : (
                <AiOutlineStar size={"24px"} onClick={() => addToFavorites(video.slug)} />
              )}
            </a>
          </div>
        </div>
        <div className="p-3 d-flex">
          <div className="me-2">
            <h6 className="fw_medium mb-1">
              <a href={video.url} target="_blank" className="hover_underline c_dark">
                {video.title}
              </a>
            </h6>
            <p className="fw_light fs_sm mb-0">
              <a className="hover_underline c_gray2 cursor-pointer" onClick={() => setSelectedCategory(video.category?.slug || '')}>
                {video.category?.title}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
