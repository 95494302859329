import { Section, SectionData, zoomUnit } from '../../components/impure/Section';
import { useEffect, useMemo, useRef, useState } from 'react';
import { InputDialog, InputSpec } from '../../components/controls/InputDialog';
import { AiOutlineEdit } from 'react-icons/ai';
import { SectionIdentifier } from 'share2flow-board';
import { useParams } from 'react-router-dom';
import { PlanDialog } from '../actionPlan/PlanDialog';
import { BiFullscreen, BiZoomIn, BiZoomOut } from 'react-icons/bi';
import { Cursors } from '../../components/impure/Cursors';
import { setBoardId } from '../../socketState';
import { AppDispatch, RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { clearBoard, setBoardInfo, useMaxColumnCount } from '../../rootState';
import { EditRolesDialog } from '../authorization/EditRolesDialog';
import { SharingDialog } from '../sharing/SharingDialog';
import { clearCursors } from '../userCursors/userCursorsSlice';
import { useHotkeys } from 'react-hotkeys-hook';
import { resetZoom, setZoomScale, zoomIn, zoomOut } from '../zoom/zoomSlice';
import { setIsDragging as contextMenuSetIsDragging } from '../contextMenu/contextMenuSlice'
import { GroupDialog } from '../groups/GroupDialog';
import { FaEdit } from 'react-icons/fa';
import { IconButton } from '../../components/controls/IconButton';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '../../windowDimenstions';
import { columnWidth } from '../../utils';
import { getCollapsedGroupIds, setCollapsedGroupIds } from '../groups/api';
import { Avatar } from '../../components/controls/Avatar';

function Board() {
  const { t } = useTranslation()
  const params = useParams()
  const boardId = params.boardId!

  const token = useSelector((state: RootState) => state.authentication.token)
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    if (token) {
      dispatch(clearBoard())
      dispatch(clearCursors())
      dispatch(setBoardId(boardId))
    }
    return () => {
      dispatch(clearBoard())
      dispatch(clearCursors())
      dispatch(setBoardId(undefined))
    }
  }, [boardId, token])

  const [timeLeft, setTimeLeft] = useState<string>('')
  const [isRunningOut, setIsRunningOut] = useState<boolean>(false)
  const [editBoardInfo, setEditBoardInfo] = useState<boolean>(false)

  const [isDragging, setIsDragging] = useState<boolean>(false)
  const dragDivRef = useRef<HTMLDivElement>(null)
  const [dragStartX, setDragStartX] = useState<number>(0)
  const [dragStartY, setDragStartY] = useState<number>(0)

  const [isFullScreen, setIsFullScreen] = useState<boolean>(false)

  const board = useSelector((state: RootState) => state.boardState.board)
  const zoomScale = useSelector((state: RootState) => state.zoom.scale)

  const groupsContainerRef = useRef<HTMLDivElement>(null)

  const groups = useSelector((state: RootState) => state.boardState.board?.columnGroups) || []
  const [collapsedGroups, setCollapsedGroups] = useState<string[]>([])
  const numColumns = useMaxColumnCount() + 1

  const [isGroupDialogOpen, setIsGroupDialogOpen] = useState<boolean>(false)
  const [editingGroupId, setEditingGroupId] = useState<string>()

  const cursors = useSelector((state: RootState) => state.userCursors.userCursorInfos)

  const windowDimensions = useWindowDimensions()

  useEffect(() => {
    const updateTimer = () => {
      if (!board) return

      const now = Date.now()
      const end = board.countdownEnd

      if (typeof end === 'undefined') {
        setTimeLeft('')
        setIsRunningOut(false)
        return
      }

      if (end <= now) {
        setTimeLeft('0:00:00')
        setIsRunningOut(true)
        return
      }

      const deltaTime = Math.floor((end - now) / 1000)
      const hours = Math.floor(deltaTime / 3600)
      const minutes = Math.floor((deltaTime / 60) % 60)
      const seconds = deltaTime % 60

      setTimeLeft(`${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`)
      setIsRunningOut(deltaTime < 60)
    }

    const interval = setInterval(updateTimer, 1000)
    updateTimer()

    return () => {
      clearInterval(interval)
    }
  }, [board])

  const boardInfoInputSpecs: InputSpec[] = [
    {
      id: 'title',
      name: t('Title'),
      type: 'text',
      initialValue: board?.boardInfo.title || '',
    },
    {
      id: 'description',
      name: t('Description'),
      type: 'textarea',
      initialValue: board?.boardInfo.description || '',
    },
  ]

  const onCloseBoardInfoDialog = (accepted: boolean, values?: {[id: string]: string | number | boolean}) => {
    if (accepted) {
      dispatch(setBoardInfo(values as any))
    }
    setEditBoardInfo(false)
  }

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.button !== 2) return

    setIsDragging(true)
    setDragStartX(dragDivRef.current!.scrollLeft + e.clientX)
    setDragStartY(dragDivRef.current!.scrollTop + e.clientY)
  }

  const onMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsDragging(false)
    dispatch(contextMenuSetIsDragging(false))
    e.preventDefault()
    e.stopPropagation()
    return false
  }

  const onContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    return false
  }

  const onMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isDragging) {
      dragDivRef.current!.scrollLeft = - e.clientX + dragStartX
      dragDivRef.current!.scrollTop = - e.clientY + dragStartY
      recomputeGroupsTop()
      dispatch(contextMenuSetIsDragging(true))
    }
  }

  useEffect(() => {
    const onContextMenu = (e: MouseEvent) => {
      if (isDragging) {
        e.preventDefault()
      }
    }

    document.addEventListener("contextmenu", onContextMenu)
    return () => {
      document.removeEventListener("contextmenu", onContextMenu)
    }
  }, [isDragging])

  const onZoomIn = (e?: KeyboardEvent) => {
    e?.preventDefault()
    dispatch(zoomIn())
    return false
  }

  const onZoomOut = (e?: KeyboardEvent) => {
    e?.preventDefault()
    dispatch(zoomOut())
    return false
  }

  const onResetZoom = (e: KeyboardEvent) => {
    e.preventDefault()
    dispatch(resetZoom())
    return false
  }

  useHotkeys(['ctrl/=', 'ctrl/+'], onZoomIn, { combinationKey: '/' });
  useHotkeys('ctrl+-', onZoomOut);
  useHotkeys('ctrl+0', onResetZoom);

  useEffect(() => {
    const newScale = Math.min(1, Math.max(0.4, windowDimensions.width / 1200))
    dispatch(setZoomScale(newScale))
  }, [windowDimensions])

  const recomputeGroupsTop = () => {
    groupsContainerRef.current!.style.top = `${dragDivRef.current!.scrollTop}px`
  }

  useEffect(() => {
    const updateCollapsedGroups = async () => {
      const collapsedGroupIds = await getCollapsedGroupIds(token!, boardId)
      setCollapsedGroups(collapsedGroupIds)
    }
    updateCollapsedGroups()
  }, [token, boardId])

  const onToggleGroup = (groupId: string) => {
    let newCollapsedGroups = collapsedGroups
    if (collapsedGroups.includes(groupId)) {
      newCollapsedGroups = collapsedGroups.filter((id) => id !== groupId)
    } else {
      newCollapsedGroups = [...collapsedGroups, groupId]
    }
    setCollapsedGroups(newCollapsedGroups)
    setCollapsedGroupIds(token!, boardId, newCollapsedGroups)
  }

  const onEditGroup = (groupId: string) => {
    setEditingGroupId(groupId)
    setIsGroupDialogOpen(true)
  }

  const isColumnGroupCollapsed = (groupId: string) => {
    return collapsedGroups.includes(groupId)
  }

  const hiddenColumns: Set<number> = useMemo(() => {
    let hiddenColumns = new Set<number>()
    for (const group of groups) {
      if (isColumnGroupCollapsed(group.id)) {
        for (let i = group.startColumn; i <= group.endColumn; i++) {
          hiddenColumns.add(i)
        }
      }
    }
    return hiddenColumns
  }, [groups, collapsedGroups])

  const groupColumns: {[groupId: string]: number} = useMemo(() => {
    // Assume no two groups start on the same column
    // Must be enforced elsewhere
    let res: {[groupId: string]: number} = {}
    for (const group of groups) {
      res[group.id] = group.startColumn
    }
    return res
  }, [groups, collapsedGroups])

  const adjustedGroupColumns: {[groupId: string]: number} = useMemo(() => {
    let res: {[groupId: string]: number} = {}
    let curIndex = 0
    for (let i = 0; i < numColumns; i++) {
      const columnGroup = Object.entries(groupColumns).find(([_, n]) => n === i)
      if (columnGroup) {
        res[columnGroup[0]] = curIndex
        if (isColumnGroupCollapsed(columnGroup[0])) {
          curIndex += 1
        }
      }
      if (!hiddenColumns.has(i)) {
        curIndex += 1
      }
    }
    return res
  }, [groupColumns, collapsedGroups])

  const collapsedGroupColumns = useMemo(() => {
    let items = Object.entries(groupColumns)
      .filter(([groupId, _column]) => isColumnGroupCollapsed(groupId))
      .map(([_groupId, column]) => column)
    return new Set<number>(items)
  }, [groupColumns, collapsedGroups])

  const columnNumberMapping = useMemo(() => {
    let res: {[column: number]: number} = {}
    let curIndex = 0
    for (let i = 0; i < numColumns; i++) {
      if (!hiddenColumns.has(i)) {
        res[i] = curIndex
        curIndex += 1
      }
      if (collapsedGroupColumns.has(i)) {
        curIndex += 1
      }
    }
    return res
  }, [hiddenColumns, numColumns])

  const columnColorMapping = useMemo(() => {
    let res: {[column: number]: string} = {}
    for (const group of groups) {
      const color = group.color
      for (let i = group.startColumn; i <= group.endColumn; i++) {
        res[i] = color
      }
    }
    return res
  }, [groups])

  if (!board) return <div>{t('Connecting...')}</div>

  const now = Date.now()

  return (
    <main className="main-section-np">
      <div className="h-screen flex bg-neutral-200">
        <div className="flex-1 flex flex-col overflow-hidden">
          <div className={`${isFullScreen ? 'hidden' : 'flex'} items-center justify-between bg-active-200 pr-8`}>
            <div className="flex flex-col py-4 px-4">
              <h1 className="text-lg lg:text-3xl text-gray-700 font-bold leading-relaxed flex items-center gap-2 line-clamp-1">{board?.boardInfo?.title}</h1>
              <p className="lg:text-lg text-gray-600 line-clamp-1 whitespace-pre-wrap">{board?.boardInfo?.description}</p>
            </div>
            <div className="flex justify-center gap-2">
              {Object.values(cursors).filter(cursor => (now - cursor.receivedAt) < 5000).map((cursor) => (
                <Avatar key={cursor.user} avatarUrl={cursor.avatarUrl} size={24} tooltip={cursor.user} />
              ))}
              <AiOutlineEdit className="text-button-peach cursor-pointer" size={30} onClick={() => setEditBoardInfo(true)} />
            </div>
          </div>
          <div id="board-sections" className={`${isFullScreen ? 'absolute top-0 left-0 h-screen w-screen bg-white z-[101]' : ''} flex-1 overflow-y-auto`} ref={dragDivRef} onMouseDown={onMouseDown} onMouseUp={onMouseUp} onContextMenu={onContextMenu} onMouseLeave={onMouseUp} onMouseMove={onMouseMove} onScroll={recomputeGroupsTop}>
            <div className="relative">
              <div ref={groupsContainerRef} className="absolute inset-0">
                {Object.entries(columnNumberMapping).map(([column, mappedColumn]) => (
                  <div
                    className="absolute z-[500] top-0"
                    style={{
                      left: zoomUnit(columnWidth + columnWidth * mappedColumn + 1, zoomScale, 'px'),
                      width: zoomUnit(columnWidth - 2, zoomScale, 'px'),
                      height: zoomUnit(24, zoomScale, 'px'),
                      fontSize: zoomUnit(0.875, zoomScale, 'rem'),
                      lineHeight: zoomUnit(1.25, zoomScale, 'rem'),
                    }}
                    key={mappedColumn}
                  >
                    <div className="absolute inset-0 bg-gray-100"></div>
                    <div className="absolute inset-0 z-1 flex items-center justify-center font-semibold text-gray-600 cursor-pointer pl-4 pr-8" onClick={() => {}}>{column}</div>
                  </div>
                ))}
                {board.columnGroups.map((group) => {
                  if (collapsedGroups.includes(group.id)) {
                    return (
                      <div
                        className="absolute z-[500] top-0"
                        style={{
                          left: zoomUnit(columnWidth + columnWidth * adjustedGroupColumns[group.id] + 1, zoomScale, 'px'),
                          width: zoomUnit(columnWidth - 2, zoomScale, 'px'),
                          height: isFullScreen ? '100vh' : `calc(100vh - 100px)`,
                          backgroundColor: group.color,
                          background: `linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, ${group.color} 40%, ${group.color} 60%, rgba(255, 255, 255, 1) 100%)`,
                          fontSize: zoomUnit(0.875, zoomScale, 'rem'),
                          lineHeight: zoomUnit(1.25, zoomScale, 'rem'),
                        }}
                        key={group.id}
                      >
                        <div className="absolute inset-0 z-1 flex flex-col items-center justify-center gap-4">
                          <div className="flex flex-col items-center justify-center gap-2 text-center text-gray-600 cursor-pointer px-4" onClick={() => onToggleGroup(group.id)}>
                            <span>{t('You have grouped')}</span>
                            <span><span className="font-bold">{group.endColumn - group.startColumn + 1}</span> {t('process steps')}&nbsp;</span>
                            <span>{t('in the group')}</span>
                            <span className="font-bold">{group.name}</span>
                            <span>{t('gruppiert')}</span>
                          </div>
                          <IconButton icon={FaEdit} size={14} onClick={() => onEditGroup(group.id)} />
                        </div>
                      </div>
                    )
                  } else {
                    return (
                      <div
                        className="absolute z-[500] top-0"
                        style={{
                          left: zoomUnit(columnWidth + columnWidth * adjustedGroupColumns[group.id] + 1, zoomScale, 'px'),
                          width: zoomUnit(columnWidth * (group.endColumn - group.startColumn + 1) - 2, zoomScale, 'px'),
                          height: zoomUnit(24, zoomScale, 'px'),
                          fontSize: zoomUnit(0.875, zoomScale, 'rem'),
                          lineHeight: zoomUnit(1.25, zoomScale, 'rem'),
                        }}
                        key={group.id}
                      >
                        <div className="absolute inset-0" style={{ backgroundColor: group.color }}></div>
                        <div className="absolute inset-0 z-1 flex items-center justify-center cursor-pointer pl-4 pr-8" onClick={() => onToggleGroup(group.id)}>
                          <p className="text-gray-600 line-clamp-1">
                            {group.endColumn - group.startColumn + 1}&nbsp;
                            {t('process steps in the group')}&nbsp;
                            <span className="inline font-bold">"{group.name}"</span>
                          </p>
                        </div>
                        <div className="absolute right-[1px] inset-y-0 z-1 flex items-center">
                          <IconButton icon={FaEdit} size={14} onClick={() => onEditGroup(group.id)} />
                        </div>
                      </div>
                    )
                  }
                })}
              </div>
              <Sections boardId={boardId} hiddenColumns={hiddenColumns} groupColumns={collapsedGroupColumns} columnColorMapping={columnColorMapping} />
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 right-0 z-[1020] flex items-center">
          {timeLeft && <div className={`bg-gray-100/50 rounded-lg ${isRunningOut ? 'text-red-600' : 'text-gray-600'} font-semibold tabular-nums text-2xl mr-2 p-2`}>
            {timeLeft}
          </div>}
          <div className="bg-gray-100/50 rounded-lg cursor-pointer p-2" onClick={() => onZoomIn()}>
            <BiZoomIn className="text-gray-700/75 text-4xl" />
          </div>
          <div className="bg-gray-100/50 rounded-lg cursor-pointer p-2" onClick={() => onZoomOut()}>
            <BiZoomOut className="text-gray-700/75 text-4xl" />
          </div>
          <div id="fullscreen-button" className="bg-gray-100/50 rounded-lg cursor-pointer p-2" onClick={() => setIsFullScreen(!isFullScreen)}>
            <BiFullscreen className="text-gray-700/75 text-4xl" />
          </div>
        </div>
      </div>
      <InputDialog title={t('Board Information')} inputSpecs={boardInfoInputSpecs} open={editBoardInfo} onClose={onCloseBoardInfoDialog} />
      <PlanDialog />
      <EditRolesDialog boardId={boardId} />
      <SharingDialog boardId={boardId} boardTitle={board.boardInfo.title} />
      <Cursors element={dragDivRef} columnGroups={groups} collapsedColumnGroups={collapsedGroups} />
      <GroupDialog open={isGroupDialogOpen} onClose={() => setIsGroupDialogOpen(false)} groupId={editingGroupId} />
    </main>
  )
}

export default Board

export interface SectionsProps {
  boardId: string,
  hiddenColumns?: Set<number>,
  groupColumns?: Set<number>,
  columnColorMapping?: {[column: number]: string},
}

export function Sections({ boardId, hiddenColumns, groupColumns, columnColorMapping }: SectionsProps) {
  const { t } = useTranslation()

  const data: SectionData[] = [
    {
      id: SectionIdentifier.SUPPORT_LEVEL,
      title: t('Support Level'),
      imagePath: '/images/support-level.png',
    },
    {
      id: SectionIdentifier.IDEAS_AND_IMPROVEMENTS,
      title: t('Ideas & Improvements'),
      imagePath: '/images/ideas-and-improvements.png',
    },
    {
      id: SectionIdentifier.PROBLEMS_AND_DIFFICULTIES,
      title: t('Problems & Difficulties'),
      imagePath: '/images/problems-and-difficulties.png',
    },
    {
      id: SectionIdentifier.ROLES_AND_FLOWS,
      title: t('Roles & Flows'),
      imagePath: '/images/roles-and-flows.png',
    },
    {
      id: SectionIdentifier.KPIS,
      title: t('KPIs'),
      imagePath: '/images/kpis.png',
    },
    {
      id: SectionIdentifier.DOCUMENT_FLOW,
      title: t('Document Flow'),
      imagePath: '/images/document-flow.png',
    },
    {
      id: SectionIdentifier.COMMENTS,
      title: t('Comments'),
      imagePath: '/images/comments.png',
    },
  ]

  return (
    <div className="flex flex-col">
      {data.map((section: SectionData, index: number) => (
        <Section
          boardId={boardId}
          sectionData={section}
          key={section.title}
          sectionIdx={index}
          hiddenColumns={hiddenColumns}
          groupColumns={groupColumns}
          columnColorMapping={columnColorMapping}
        />
      ))}
    </div>
  )
}
