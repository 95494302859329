import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { SectionIdentifier } from "share2flow-board";
import { useLockCell } from "../../features/locking";
import { CellDecision } from "../cells/CellDecision";
import { CellIcon } from "../cells/CellIcon";
import { CellPerson } from "../cells/CellPerson";
import { CellProcessCard, KPI } from "../cells/CellProcessCard";
import { InputDialog, InputSpec } from "../controls/InputDialog";
import { PopupButton } from "../controls/PopupButton";
import { PopupMenu } from "../controls/PopupMenu";
import { CellContentsProps } from "./CellContents";
import { AppDispatch, RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { clearReferenceBySource, setCell, setReferenceBySource, useCell, useColumn, useReferencesForSource } from "../../rootState";
import { useTranslation } from "react-i18next";
import config from "../../config";

export function RolesAndFlowsCell({ coords, boardId }: CellContentsProps) {
  const { t } = useTranslation()
  const data = useCell(coords)
  const [isEditing, setIsEditing] = useState(false)
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { lock, unlock } = useLockCell(coords)
  const dispatch = useDispatch<AppDispatch>()

  const kpiCells = useColumn({section: SectionIdentifier.KPIS, row: 0, column: coords.column}) || []

  const rootKpiCells = useColumn({section: SectionIdentifier.KPIS, row: 0, column: 0}) || []

  const kpis = useColumn({section: SectionIdentifier.KPIS, row: 0, column: 0}) || []

  const leftKpiReferences = useReferencesForSource(data?.id || '', 'leftKpi') || []
  const rightKpiReferences = useReferencesForSource(data?.id || '', 'rightKpi') || []

  const members = useSelector((state: RootState) => state.boardState.members)

  const [selectedType, setSelectedType] = useState<string>('')

  const onEditCard = async () => {
    setSelectedType('Process Step')
    const lockAcquired = await lock()
    if (lockAcquired) {
      setIsEditing(true)
    }
    setDropdownVisible(false)
  }

  const onEditDecision = async () => {
    setSelectedType('Decision')
    const lockAcquired = await lock()
    if (lockAcquired) {
      setIsEditing(true)
    }
    setDropdownVisible(false)
  }

  const onSetLeftKpi = (kpi: KPI) => {
    if (kpi.id === 'CLEAR') {
      dispatch(clearReferenceBySource(data.id, 'leftKpi'))
    } else {
      dispatch(setReferenceBySource({ sourceId: data.id, sourceTag: 'leftKpi', targetId: kpi.id, targetTag: '' }))
    }
  }

  const onSetRightKpi = (kpi: KPI) => {
    if (kpi.id === 'CLEAR') {
      dispatch(clearReferenceBySource(data.id, 'rightKpi'))
    } else {
      dispatch(setReferenceBySource({ sourceId: data.id, sourceTag: 'rightKpi', targetId: kpi.id, targetTag: '' }))
    }
  }

  const onSetComments = (comments: any) => {
    dispatch(setCell(coords, {...data, comments}))
  }

  if (coords.column === 0) {
    const inputSpecs: InputSpec[] = [
      {
        id: 'role',
        name: t('Role or function designation'),
        type: 'text',
        initialValue: data?.role || '',
        maxLength: config.maxHeaderLength,
      },
      {
        id: 'userId',
        name: t('User'),
        type: 'select',
        initialValue: data?.userId || '',
        options: [
          { value: '', label: t('New (enter details below)') },
          ...(members?.map((user) => ({ value: user.userId, label: user.name })) || [])
        ],
      },
      {
        id: 'name',
        name: t('Name'),
        type: 'text',
        initialValue: data?.name || '',
        maxLength: config.maxHeaderLength,
      },
      {
        id: 'avatarUrl',
        name: t('Avatar'),
        type: 'avatar',
        initialValue: data?.avatarUrl || '',
      },
    ]

    const onClose = async (accepted: boolean, values?: {[id: string]: string | number | boolean}) => {
      if (accepted) {
        dispatch(setCell(coords, {...data, ...values}))
      }
      await unlock()
      setIsEditing(false)
    }

    const user = members?.find((user) => user.userId === data?.userId)
    let avatarUrl = data?.avatarUrl || ''
    let name = data?.name || ''
    if (user) {
      avatarUrl = user.avatarUrl
      name = user.name
    }

    return (
      <div className="h-full w-full flex items-center justify-center cursor-pointer">
        <InputDialog title={t('Definition | Roles')} inputSpecs={inputSpecs} open={isEditing} onClose={onClose} />
        <div className="h-full w-full flex items-center justify-center cursor-pointer" onClick={() => onEditCard()}>
          {data
            ? <CellPerson avatarUrl={avatarUrl} name={name} description={data.role} />
            : <CellIcon icon={AiOutlinePlus} />
          }
        </div>
      </div>
    )
  }

  const renderEditor = () => {
    const [name, placeholder, title] = selectedType === 'Process Step' ? [
      t('Description of subprocess step'),
      t('Describe the process step here'),
      t('Definition | Process Step'),
    ] : [
      t('Description of decision'),
      t('Describe the decision here'),
      t('Definition | Decision'),
    ]

    const inputSpecs: InputSpec[] = [
      {
        id: 'text',
        name: name,
        placeholder: placeholder,
        type: 'textarea',
        initialValue: data?.text || '',
      },
    ]

    const onClose = async (accepted: boolean, values?: {[id: string]: string | number | boolean}) => {
      if (accepted) {
        dispatch(setCell(coords, {...data, ...values, type: selectedType}))
      }
      await unlock()
      setIsEditing(false)
    }

    return <InputDialog title={title} inputSpecs={inputSpecs} open={isEditing} onClose={onClose} />
  }

  const renderContents = () => {
    if (!data || Object.keys(data).length === 0) {
      return (
        <div className="h-full w-full flex flex-col items-center justify-center cursor-pointer relative" onClick={() => setDropdownVisible(true)}>
          <CellIcon icon={AiOutlinePlus} />

          <div className="relative -top-5">
            <PopupMenu isVisible={dropdownVisible} onDismiss={() => setDropdownVisible(false)} width={200}>
              <PopupButton icon={<div className="rounded w-4 h-4 border-2 border-gray-700" />} text={t('Process Step')} onClick={() => onEditCard()} />
              <PopupButton icon={<div className="rounded w-4 h-4 border-2 border-gray-700 transform rotate-45" />} text={t('Decision')} onClick={() => onEditDecision()} />
            </PopupMenu>
          </div>
        </div>
      )
    }

    if (data.type === 'Process Step') {
      const rootKpis: KPI[] = kpis.map((cell, idx) => ({
        id: kpiCells?.[idx]?.id,
        name: cell?.name,
        icon: cell?.icon,
      })).filter((cell) => cell.id)
      rootKpis.push({
        id: 'CLEAR',
        name: t('Clear KPI'),
        icon: 'TRASH',
      })

      const leftKpiId = leftKpiReferences.length > 0 ? leftKpiReferences[0].targetId : undefined
      const rightKpiId = rightKpiReferences.length > 0 ? rightKpiReferences[0].targetId : undefined

      const leftKpiIdx = kpiCells.findIndex((kpi) => kpi && kpi.id && kpi.id === leftKpiId)
      const rightKpiIdx = kpiCells.findIndex((kpi) => kpi && kpi.id && kpi.id === rightKpiId)

      const leftKpiText = leftKpiIdx >= 0
        ? `${rootKpiCells[leftKpiIdx]?.prefix || ''}${kpiCells[leftKpiIdx]?.value || ''}${rootKpiCells[leftKpiIdx]?.suffix || ''}`
        : undefined

      const rightKpiText = rightKpiIdx >= 0
        ? `${rootKpiCells[rightKpiIdx]?.prefix || ''}${kpiCells[rightKpiIdx]?.value || ''}${rootKpiCells[rightKpiIdx]?.suffix || ''}`
        : undefined

      let leftIcon = leftKpiIdx >= 0 ? rootKpiCells[leftKpiIdx].icon : undefined
      let rightIcon = rightKpiIdx >= 0 ? rootKpiCells[rightKpiIdx].icon : undefined

      return (
        <div className="relative w-full h-full flex items-center justify-center">
          <CellProcessCard
            id={data.id}
            text={data.text}
            comments={data.comments || []}
            leftSuperText={leftKpiText}
            leftIconName={leftIcon}
            rightSuperText={rightKpiText}
            rightIconName={rightIcon}
            kpis={rootKpis}
            onClickComment={onEditCard}
            onChangeLeftKpi={onSetLeftKpi}
            onChangeRightKpi={onSetRightKpi}
            onChangeComments={onSetComments}
          />
        </div>
      )
    }

    if (data.type === 'Decision') {
      return (
        <div className="h-full w-full flex items-center justify-center">
          <CellDecision
            id={data.id}
            text={data.text}
            onClick={onEditDecision}
          />
        </div>
      )
    }
  }

  return (
    <>
      {renderContents()}
      {renderEditor()}
    </>
  )
}
