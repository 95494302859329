export function Upgrade() {
  return (
    <div className="bootstrap">
      <main className="main-section">
        <div className="container">
          <div className="inner_container">
            <div id="dropin-container"></div>
          </div>
        </div>
      </main>
    </div>
  )
}
