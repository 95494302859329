import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

export interface LoadingStateProps {
  isLoading: boolean,
  isEmpty?: boolean,
}

export function LoadingState({ isLoading, isEmpty, children }: PropsWithChildren<LoadingStateProps>) {
  const { t } = useTranslation()

  if (isEmpty) {
    return (
      <></>
    )
  }

  if (isLoading) {
    return (
      <div className="flex justify-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">{t('Loading...')}</span>
        </div>
      </div>
    )
  }

  return (
    <>{children}</>
  )
}
