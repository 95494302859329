import { AddFavoriteVideoRequest, FavoriteVideosResponse } from "share2flow-typedefs"
import config from "../../config"

export type VideoCategory = {
  title: string
  slug: string
  image: string
  overlayColor: string
  backgroundColor: string
}

export type Video = {
  title: string
  slug: string
  category?: VideoCategory
  image: string
  url: string
}

export async function getVideoCategories(locale: string): Promise<VideoCategory[]> {
  const res = await fetch(`${config.cmsUrl}/api/video-categories?populate[0]=Image&locale=${locale}`)
  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
  const json = await res.json()
  const result = json.data.map((category: any) => ({
    title: category.attributes.Title,
    slug: category.attributes.slug,
    image: config.cmsUrl + category.attributes.Image.data.attributes.url,
    overlayColor: hexToRgba(category.attributes.Tint, 0.5),
    backgroundColor: hexToRgba(category.attributes.Tint, 0.13),
  }))
  return result
}

export async function getVideos(locale: string): Promise<Video[]> {
  const res = await fetch(`${config.cmsUrl}/api/videos?populate[0]=Image&populate[1]=video_category&locale=${locale}`)
  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
  const json = await res.json()
  const result = json.data.map((video: any) => ({
    title: video.attributes.Title,
    slug: video.attributes.slug,
    category: video.attributes.video_category.data ? {
      title: video.attributes.video_category.data.attributes.Title,
      slug: video.attributes.video_category.data.attributes.slug,
      image: '',
      overlayColor: hexToRgba(video.attributes.video_category.data.attributes.Tint, 0.5),
      backgroundColor: hexToRgba(video.attributes.video_category.data.attributes.Tint, 0.13),
    } : null,
    image: config.cmsUrl + video.attributes.Image.data.attributes.url,
    url: video.attributes.URL,
  }))
  return result
}

function hexToRgba(hex: string, alpha: number) {
  const [r, g, b] = hex.match(/\w\w/g)!.map((x) => parseInt(x, 16))
  return `rgba(${r},${g},${b},${alpha})`
}

export async function getFavoriteVideos(token: string): Promise<FavoriteVideosResponse> {
  const res = await fetch(`${config.apiUrl}/favoriteVideos`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
  return await res.json()
}

export async function addVideoToFavorites(token: string, videoSlug: string): Promise<void> {
  let request: AddFavoriteVideoRequest = { videoSlug }

  const res = await fetch(`${config.apiUrl}/favoriteVideos`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(request),
  })

  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
}

export async function removeVideoFromFavorites(token: string, videoSlug: string): Promise<void> {
  const res = await fetch(`${config.apiUrl}/favoriteVideos/${videoSlug}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
}
