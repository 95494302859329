import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { AiFillStar, AiOutlineStar } from "react-icons/ai"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { RichBoardItem, UserBoardRole } from "share2flow-typedefs"
import { Avatar } from "../../components/controls/Avatar"
import { LoadingState } from "../../components/controls/LoadingState"
import config from "../../config"
import { AppDispatch, RootState } from "../../store"
import useWindowDimensions from "../../windowDimenstions"
import { NotificationMessageType, showNotificationMessage } from "../notificationMessages/notificationMessagesSlice"
import { SharingDialog } from "../sharing/SharingDialog"
import { deleteBoard, duplicateBoard, listRecentBoards, setBoardFavorite } from "./api"
import { showDialog as showSharingDialog } from "../sharing/sharingSlice";
import { PopupMenu } from "../../components/controls/PopupMenu"
import { PopupButton } from "../../components/controls/PopupButton"
import { InputDialog } from "../../components/controls/InputDialog"
import { createSocket } from "../../socketState"
import { Action } from "share2flow-board"

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'swiper-container': any,
      'swiper-slide': any,
    }
  }
}

enum FilterOption {
  ALL = 'ALL',
  MY = 'MY',
  SHARED = 'SHARED',
  FAVORITE = 'FAVORITE',
}

enum SortOption {
  ALPHA_ASC = 'ALPHA_ASC',
  ALPHA_DESC = 'ALPHA_DESC',
  OPENED_ASC = 'OPENED_ASC',
  OPENED_DESC = 'OPENED_DESC',
  MODIFIED_ASC = 'MODIFIED_ASC',
  MODIFIED_DESC = 'MODIFIED_DESC',
  CREATED_ASC = 'CREATED_ASC',
  CREATED_DESC = 'CREATED_DESC',
}

const sortFunctions: {[sortOption: string]: (a: RichBoardItem, b: RichBoardItem) => number} = {
  [SortOption.ALPHA_ASC]: (a, b) => a.title.localeCompare(b.title),
  [SortOption.ALPHA_DESC]: (a, b) => b.title.localeCompare(a.title),
  [SortOption.OPENED_ASC]: (a, b) => ((new Date(a.lastOpened).getTime()) || 0) - ((new Date(b.lastOpened)).getTime() || 0),
  [SortOption.OPENED_DESC]: (a, b) => ((new Date(b.lastOpened).getTime()) || 0) - ((new Date(a.lastOpened)).getTime() || 0),
  [SortOption.MODIFIED_ASC]: (a, b) => ((new Date(a.updatedAt).getTime()) || 0) - ((new Date(b.updatedAt)).getTime() || 0),
  [SortOption.MODIFIED_DESC]: (a, b) => ((new Date(b.updatedAt).getTime()) || 0) - ((new Date(a.updatedAt)).getTime() || 0),
  [SortOption.CREATED_ASC]: (a, b) => ((new Date(a.insertedAt).getTime()) || 0) - ((new Date(b.insertedAt)).getTime() || 0),
  [SortOption.CREATED_DESC]: (a, b) => ((new Date(b.insertedAt).getTime()) || 0) - ((new Date(a.insertedAt)).getTime() || 0),
}

export function MyFlows() {
  const { t } = useTranslation()
  const [recentBoards, setRecentBoards] = useState<RichBoardItem[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const token = useSelector((state: RootState) => state.authentication.token)
  const dispatch = useDispatch<AppDispatch>()

  const [searchQuery, setSearchQuery] = useState<string>('')
  const [filterBy, setFilterBy] = useState<FilterOption>(FilterOption.ALL)
  const [sortBy, setSortBy] = useState<SortOption>(SortOption.OPENED_DESC)

  const [selectedBoard, setSelectedBoard] = useState<RichBoardItem | null>(null)
  const [showRenameDialog, setShowRenameDialog] = useState<boolean>(false)
  const [showDuplicateDialog, setShowDuplicateDialog] = useState<boolean>(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)

  const windowDimensions = useWindowDimensions()

  const updateBoards = async () => {
    setIsLoading(true)
    try {
      const res = await listRecentBoards(token!, -1)
      setRecentBoards(res)
    } catch (e: any) {
      dispatch(showNotificationMessage({
        type: NotificationMessageType.Error,
        title: 'Error retrieving recent boards',
        body: e.message,
      }))
    }
    setIsLoading(false)
  }

  useEffect(() => {
    updateBoards()
  }, [])

  const filteredSortedBoards = useMemo(() => {
    if (!recentBoards) return []

    const queryLower = searchQuery.toLowerCase().trim()

    const searched = queryLower
      ? recentBoards.filter((board) => {
        return board.title?.toLowerCase().includes(queryLower) ||
               board.description?.toLowerCase().includes(queryLower)
      })
      : recentBoards

    const filtered = searched.filter((board) => {
      if (filterBy === FilterOption.ALL) return true
      else if (filterBy === FilterOption.MY) return board.role === UserBoardRole.OWNER
      else if (filterBy === FilterOption.SHARED) return board.role !== UserBoardRole.OWNER
      else if (filterBy === FilterOption.FAVORITE) return board.isFavorite
    })

    const sorted = filtered.sort(sortFunctions[sortBy] ?? ((a, b) => a.title.localeCompare(b.title)))

    return sorted
  }, [recentBoards, searchQuery, filterBy, sortBy])

  const onBoardChange = (board: RichBoardItem) => {
    setRecentBoards(recentBoards.map((b) => {
      if (b.id === board.id) return board
      return b
    }))
  }

  const onShareBoard = (board: RichBoardItem) => {
    setSelectedBoard(board)
    dispatch(showSharingDialog())
  }

  const onRenameBoard = (board: RichBoardItem) => {
    setSelectedBoard(board)
    setShowRenameDialog(true)
  }

  const onAcceptRenameBoard = async (newTitle: string) => {
    setShowRenameDialog(false)
    if (!selectedBoard) return
    try {
      // This is a hack to update the board title
      // We have to do it this way because it has to go through the backend session to all users
      const socket = createSocket(token!, selectedBoard.id)
      socket.on('connect', () => {
        socket.emit('requestInit')
        socket.on('action', (payload) => {
          if (payload.type === 'boardState/init') {
            socket.emit('action', {
              action: Action.setBoardInfo,
              args: {
                boardInfo: {
                  ...payload.payload.boardInfo,
                  title: newTitle,
                },
              },
            })
          } else if (payload.type === 'boardState/applyAction' && payload.payload.action === 'setBoardInfo') {
            socket.disconnect()
            updateBoards()
          }
        })
      })
    } catch (e: any) {
      dispatch(showNotificationMessage({
        type: NotificationMessageType.Error,
        title: 'Error renaming board',
        body: e.message,
      }))
    }
  }

  const onDuplicateBoard = (board: RichBoardItem) => {
    setSelectedBoard(board)
    setShowDuplicateDialog(true)
  }

  const onAcceptDuplicateBoard = async (newTitle: string) => {
    setShowDuplicateDialog(false)
    if (!selectedBoard) return
    try {
      await duplicateBoard(token!, selectedBoard.id, newTitle)
      updateBoards()
    } catch (e: any) {
      dispatch(showNotificationMessage({
        type: NotificationMessageType.Error,
        title: 'Error duplicating board',
        body: e.message,
      }))
    }
  }

  const onDeleteBoard = (board: RichBoardItem) => {
    setSelectedBoard(board)
    setShowDeleteDialog(true)
  }

  const onAcceptDeleteBoard = async () => {
    setShowDeleteDialog(false)
    if (!selectedBoard) return
    try {
      await deleteBoard(token!, selectedBoard.id)
      updateBoards()
    } catch (e: any) {
      dispatch(showNotificationMessage({
        type: NotificationMessageType.Error,
        title: 'Error deleting board',
        body: e.message,
      }))
    }
  }


  return (
    <div className="bootstrap">
      {/* .................. start page header .................. */}
      <div className="page-header pt-3 bg_light">
        <div className="container">
          <h1 className="page-title">
            {t('Flow Boards')}
          </h1>
        </div>
      </div>
      {/* .................. end page header .................. */}

      {/* .................. start main section .................. */}
      <main className="main-section">

        {/* Recently Viewed section */}
        <section className="pb-5">
          <div className="container">
            <h3 className="fs_lg fw_medium mb-4">
              {t('Recently Viewed')}
            </h3>

            <div className="def_carousel_container">
              <LoadingState isLoading={isLoading}>
                <swiper-container
                  space-between="25"
                  slides-per-view={Math.min(Math.max(Math.floor(windowDimensions.width / 300), 1), 5)}
                  navigation="true"
                  className="def_carousel recently_viewedSlider"
                >
                  {recentBoards.slice(0, 10).map((board) => <BoardSlide board={board} key={board.id} />)}
                </swiper-container>
              </LoadingState>
            </div>

          </div>
        </section>

        {/* My Flows section */}
        <section className="py-5 bg_light">
          <div className="container">
            <div className="d-lg-flex align-items-center mb-2">
              <h3 className="fs_lg fw_medium mb-4 me-lg-5">
                {t('My Flows')}
              </h3>
              <div className="d-flex fs_sm align-items-center mb-4 me-lg-4">
                <div className="me-3">
                  {t('Board Category')}: 
                </div>
                <div className="flex-grow-1">
                  <select className="form-select w-100" aria-label="Filter by" value={filterBy} onChange={(e) => setFilterBy(e.target.value as FilterOption)}>
                    <option value={FilterOption.ALL}>{t('All boards')}</option>
                    <option value={FilterOption.MY}>{t('My boards')}</option>
                    <option value={FilterOption.SHARED}>{t('Boards shared with me')}</option>
                    <option value={FilterOption.FAVORITE}>{t('Favorite boards')}</option>
                  </select>
                </div>
              </div>
              <div className="d-flex fs_sm align-items-center mb-4 me-lg-4">
                <div className="me-3">
                  {t('Sort by')}: 
                </div>
                <div className="flex-grow-1">
                  <select className="form-select w-100" aria-label="Sort by" value={sortBy} onChange={(e) => setSortBy(e.target.value as SortOption)}>
                    <option value={SortOption.ALPHA_ASC}>{t('Alphabetically A-Z')}</option>
                    <option value={SortOption.ALPHA_DESC}>{t('Alphabetically Z-A')}</option>
                    <option value={SortOption.OPENED_DESC}>{t('Newly opened first')}</option>
                    <option value={SortOption.OPENED_ASC}>{t('Newly opened last')}</option>
                    <option value={SortOption.MODIFIED_DESC}>{t('Newly modified first')}</option>
                    <option value={SortOption.MODIFIED_ASC}>{t('Newly modified last')}</option>
                    <option value={SortOption.CREATED_DESC}>{t('Newly created first')}</option>
                    <option value={SortOption.CREATED_ASC}>{t('Newly created last')}</option>
                  </select>
                </div>
              </div>
              <div className="d-flex fs_sm align-items-center mb-4 flex-1">
                <div className="input-group">
                  <span className="input-group-text bg_white">
                    <i className="fi fi-rr-search search_icon c_gray"></i>
                  </span>
                  <input className="form-control border-start-0" type="search" placeholder={t('Search')} value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                </div>
              </div>
            </div>

            {/* cards */}
            <div>
              <div className="grid items-stretch flex-wrap gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6">
                {filteredSortedBoards.map((board) =>
                  <BoardCard
                    board={board}
                    key={board.id}
                    onChange={onBoardChange}
                    onShare={() => onShareBoard(board)}
                    onRename={() => onRenameBoard(board)}
                    onDuplicate={() => onDuplicateBoard(board)}
                    onDelete={() => onDeleteBoard(board)}
                  />)}
              </div>
            </div>
          </div>
        </section>


      </main>

      {selectedBoard &&
        <>
          <SharingDialog boardId={selectedBoard.id} boardTitle={selectedBoard.title} />
          <InputDialog
            title={t('Rename Board')}
            open={showRenameDialog}
            onClose={(accepted, values) => accepted ? onAcceptRenameBoard(values?.title as string) : setShowRenameDialog(false)}
            inputSpecs={[{
              id: 'title',
              name: t('Title'),
              type: 'text',
              initialValue: selectedBoard.title,
              maxLength: 100,
            }]}
            acceptLabel={t('Rename')}
          />
          <InputDialog
            title={t('Duplicate Board')}
            open={showDuplicateDialog}
            onClose={(accepted, values) => accepted ? onAcceptDuplicateBoard(values?.title as string) : setShowDuplicateDialog(false)}
            inputSpecs={[{
              id: 'title',
              name: t('Title'),
              type: 'text',
              initialValue: selectedBoard.title,
              maxLength: 100,
            }]}
            acceptLabel={t('Duplicate')}
          />
          <InputDialog
            title={t('Delete Board')}
            open={showDeleteDialog}
            onClose={(accepted) => accepted ? onAcceptDeleteBoard() : setShowDeleteDialog(false)}
            inputSpecs={[]}
            acceptLabel={t('Delete')}
          />
        </>
      }
      {/* .................. end main section .................. */}
    </div>
  )
}

interface BoardSlideProps {
  board: RichBoardItem,
}

function BoardSlide({ board }: BoardSlideProps) {
  const { t } = useTranslation()
  const token = useSelector((state: RootState) => state.authentication.token)

  return (
    <swiper-slide key={board.id} className="card position-relative overflow-hidden rounded">
      <div className="card-header bg_white_light position-absolute w-100 top-0 left-0 z-index-2">
        <div className="d-flex card_userInfo">
          {!board.ownerId
            ? <div className="fs_sm fw_semiBold c_dark me-3">{t('Board Overview')}</div>
            : <div className="d-flex card_userInfo gap-2">
                <Avatar avatarUrl={board.ownerAvatarUrl} size={28} />
                <div className="card_userName fs_sm fw_semiBold c_dark">{board.ownerName}</div>
              </div>
          }
        </div>
      </div>
      <div className="card-body p-0">
        <Link to={`/boards/${board.id}`}>
          <img className="img-fluid w-100" src={`${config.apiUrl}/render/board/${board.id}/preview?token=${token}`} alt="board" />
        </Link>
        <div className="p-3">
          <h5 className="card-title fw_medium fs_lg mb-1">
            <Link to={`/boards/${board.id}`} className="hover_underline c_dark">
              {board.title}
            </Link>
          </h5>
          <p className="card-text fw_light fs_sm">{board.description}</p>
        </div>
      </div>
    </swiper-slide>
  )
}

interface BoardCardProps {
  board: RichBoardItem,
  onChange: (board: RichBoardItem) => void,
  onShare: () => void,
  onRename: () => void,
  onDuplicate: () => void,
  onDelete: () => void,
}

function BoardCard({ board, onChange, onShare, onRename, onDuplicate, onDelete }: BoardCardProps) {
  const { t } = useTranslation()
  const token = useSelector((state: RootState) => state.authentication.token)
  const [showMenu, setShowMenu] = useState<boolean>(false)
  const user = useSelector((state: RootState) => state.authentication.user)

  const toggleIsFavorite = async () => {
    await setBoardFavorite(token!, board.id, !board.isFavorite)
    onChange({ ...board, isFavorite: !board.isFavorite })
  }

  return (
    <div className="card position-relative overflow-hidden rounded">
      <div className="card-header bg_warning_light position-absolute w-100 top-0 left-0 z-index-2 d-flex justify-content-between align-items-center bg-rose-200">
        <div className="flex items-center">
          <button className="card_starLabel" onClick={() => toggleIsFavorite()}>
            {board.isFavorite
              ? <AiFillStar size={"24px"} />
              : <AiOutlineStar size={"24px"} />
            }
          </button>
        </div>

        {board.role !== UserBoardRole.VIEWER &&
          <div className="dropdown">
            <button className="btn px-2 py-1 fs_sm" type="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => setShowMenu(true)}>
              <i className="fi fi-rr-menu-dots-vertical"></i>
            </button>
            <div className="relative -top-5">
              <PopupMenu isVisible={showMenu} onDismiss={() => setShowMenu(false)} width={200}>
                <PopupButton text={t('Share')} onClick={() => { setShowMenu(false); onShare() }} />
                {user?.id === board.ownerId &&
                  <>
                    <PopupButton text={t('Rename')} onClick={() => { setShowMenu(false); onRename() }} />
                    <PopupButton text={t('Duplicate')} onClick={() => { setShowMenu(false); onDuplicate() }} />
                    <PopupButton text={t('Delete')} onClick={() => { setShowMenu(false); onDelete() }} />
                  </>
                }
              </PopupMenu>
            </div>
          </div>
        }
      </div>
      <div className="card-body p-0 flex flex-col">
        <Link to={`/boards/${board.id}`} className="flex-1">
          <img className="img-fluid w-100 min-h-[200px]" src={`${config.apiUrl}/render/board/${board.id}/preview?token=${token}`} alt="board" />
        </Link>
        <div className="p-3">
          <h5 className="card-title fw_medium small mb-1">
            <Link to={`/boards/${board.id}`} className="hover_underline c_dark">
              {board.title}
            </Link>
          </h5>
          <div className="flex flex-col w-full my-2">
            <div className="flex text-xs text-gray-500">
              <span className="flex-1">{t('Modified at')}</span>
              <span className="flex-1">{t('Created at')}</span>
            </div>
            <div className="flex text-sm">
              <span className="flex-1">{board.updatedAt.toString().slice(0, 10)}</span>
              <span className="flex-1">{board.insertedAt.toString().slice(0, 10)}</span>
            </div>
          </div>
          <div className="d-flex card_userInfo">
            {!board.ownerId
              ? <div className="fs_sm fw_semiBold c_dark me-3">{t('Board Overview')}</div>
              : <div className="d-flex card_userInfo gap-2">
                  <Avatar avatarUrl={board.ownerAvatarUrl} size={28} />
                  <div className="card_userName fs_sm fw_semiBold c_dark">{board.ownerName}</div>
                </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}
