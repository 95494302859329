import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { RootState } from "../../store"

export function getLanguage() {
  if (window.navigator.language.startsWith('de')) {
    return 'de'
  }
  return 'en'
}

export function LanguageDetector() {
  const user = useSelector((state: RootState) => state.authentication.user)
  const { i18n } = useTranslation()

  useEffect(() => {
    if (user) {
      i18n.changeLanguage(user.language)
    } else {
      i18n.changeLanguage(getLanguage())
    }
  }, [user])

  return (<></>)
}
