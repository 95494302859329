import { useState } from 'react';
import { HiChevronDown } from 'react-icons/hi';
import { FaComment } from 'react-icons/fa';
import { PopupMenu } from '../controls/PopupMenu';
import { PopupButton } from '../controls/PopupButton';
import { NamedIcon, NamedIcons } from '../controls/NamedIcon';
import { Arrows } from '../impure/Arrows';
import { renderFlowsArrowControls } from '../impure/FlowsArrowControls';
import { BiTrash } from 'react-icons/bi';
import { Textfit } from 'react-textfit';
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next';

export interface KPI {
  id: string,
  name: string,
  icon?: string,
}

export interface ProcessCardComment {
  id: string,
  text: string,
}

interface CellProcessCardProps {
  id: string,
  text: string,
  comments: ProcessCardComment[],
  leftSuperText?: string,
  rightSuperText?: string,
  leftIconName?: string,
  rightIconName?: string,
  kpis: KPI[],
  onClickComment: () => void,
  onChangeLeftKpi: (kpi: KPI) => void,
  onChangeRightKpi: (kpi: KPI) => void,
  onChangeComments: (comments: ProcessCardComment[]) => void,
}

export function CellProcessCard({ id, text, comments, leftSuperText, rightSuperText, leftIconName, rightIconName, kpis, onClickComment, onChangeLeftKpi, onChangeRightKpi, onChangeComments }: CellProcessCardProps) {
  const { t } = useTranslation()
  const [leftDropdownVisible, setLeftDropdownVisible] = useState(false);
  const [rightDropdownVisible, setRightDropdownVisible] = useState(false);
  const [commentsVisible, setCommentsVisible] = useState(false);
  const [newComment, setNewComment] = useState('')

  const toggleLeftDropdown = () => {
    setLeftDropdownVisible(!leftDropdownVisible)
  }
  const hideLeftDropdown = () => {
    setLeftDropdownVisible(false)
  }
  const toggleRightDropdown = () => {
    setRightDropdownVisible(!rightDropdownVisible)
  }
  const hideRightDropdown = () => {
    setRightDropdownVisible(false)
  }
  const toggleComments = () => {
    setCommentsVisible(!commentsVisible)
  }
  const hideComments = () => {
    setCommentsVisible(false)
  }
  const addComment = () => {
    onChangeComments([
      ...comments,
      {
        id: uuidv4(),
        text: newComment,
      },
    ])
    setNewComment('')
  }
  const removeComment = (index: number) => {
    const newComments = [...comments]
    newComments.splice(index, 1)
    onChangeComments(newComments)
  }

  return (
    <>
      <div className="w-full h-full flex items-stretch justify-stretch relative p-4 cursor-pointer" onClick={onClickComment ? () => onClickComment() : undefined}>
        <Textfit mode="multi" max={16} className="flex-1 bg-neutral-300 text-center border-l-2 border-r-2 border-b-2 border-l-primary-purple/50 border-r-primary-blue/50 border-b-primary-orange/50 p-2 leading-none">
          {text}
        </Textfit>
      </div>
      <div className="absolute top-0 right-[2px] text-xs z-[100]">
        {rightSuperText}
      </div>
      <div className="absolute top-0 left-[2px] text-xs z-[100]">
        {leftSuperText}
      </div>
      <div className="absolute inset-y-0 left-[3px] text-xs flex items-center">
        <div className="relative bg-white p-[2px] rounded-full border-2 border-primary-purple/50 cursor-pointer" onClick={() => toggleLeftDropdown()}>
          {leftIconName
            ? <NamedIcon iconName={leftIconName as NamedIcons} size="18px" className="text-primary-purple p-[1px]" />
            : <HiChevronDown size="18px" className="text-primary-purple" />
          }
          {kpis && 
            <PopupMenu isVisible={leftDropdownVisible} onDismiss={() => hideLeftDropdown()} width={200}>
              {kpis.map((kpi) => (
                <PopupButton
                  key={kpi.id}
                  iconName={kpi.icon}
                  text={kpi.name}
                  onClick={() => onChangeLeftKpi ? onChangeLeftKpi(kpi) : {}}
                />
              ))}
            </PopupMenu>
          }
        </div>
      </div>
      <div className="absolute inset-y-0 right-[4px] text-xs flex items-center">
        <div className="relative bg-white p-[2px] rounded-full border-2 border-primary-blue/50 cursor-pointer" onClick={() => toggleRightDropdown()}>
          {rightIconName
            ? <NamedIcon iconName={rightIconName as NamedIcons} size="18px" className="text-primary-blue p-[1px]" />
            : <HiChevronDown size="18px" className="text-primary-blue" />
          }
          {kpis && 
            <PopupMenu isVisible={rightDropdownVisible} onDismiss={() => hideRightDropdown()} width={200}>
              {kpis.map((kpi) => (
                <PopupButton
                  key={kpi.id}
                  iconName={kpi.icon}
                  text={kpi.name}
                  onClick={() => onChangeRightKpi ? onChangeRightKpi(kpi) : {}}
                />
              ))}
            </PopupMenu>
          }
        </div>
      </div>
      <div className="absolute inset-x-0 bottom-[4px] text-xs flex flex-col items-center">
        <div className="bg-white p-[4px] rounded-full border-2 border-primary-orange/50 cursor-pointer" onClick={() => toggleComments()}>
          <FaComment size="14px" className={comments.length === 0 ? 'text-gray-300' : 'text-primary-orange'} />
        </div>
        <PopupMenu isVisible={commentsVisible} onDismiss={() => hideComments()} width={500}>
          <ul className="text-[1rem] divide-y flex flex-col w-full">
            {comments && comments.map((comment, index) => (
              <li key={index} className="px-4 py-4 flex gap-4">
                <div className="flex-1">
                  {comment.text}
                </div>
                <button onClick={() => removeComment(index)}><BiTrash /></button>
              </li>
            ))}
            <li className="flex px-2 pt-2">
              <input type="text" className="flex-1 block rounded-l-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" value={newComment} onChange={(e) => setNewComment(e.target.value)} />
              <input type="button" className="justify-center rounded-r-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" value={t('Comment')} onClick={() => addComment()} />
            </li>
          </ul>
        </PopupMenu>
      </div>
      <div className="absolute right-[13px] bottom-[14px]">
        <Arrows id={id} tag="bottomRight" renderControls={renderFlowsArrowControls} className="bg-white border-2 border-black w-2 h-2 rounded-full">
        </Arrows>
      </div>
      <div className="absolute right-[13px] top-[14px]">
        <Arrows id={id} tag="topRight" renderControls={renderFlowsArrowControls} className="bg-white border-2 border-black w-2 h-2 rounded-full">
        </Arrows>
      </div>
      <div className="absolute left-[13px] bottom-[14px]">
        <Arrows id={id} tag="bottomLeft" renderControls={renderFlowsArrowControls} className="bg-white border-2 border-black w-2 h-2 rounded-full">
        </Arrows>
      </div>
      <div className="absolute left-[13px] top-[14px]">
        <Arrows id={id} tag="topLeft" renderControls={renderFlowsArrowControls} className="bg-white border-2 border-black w-2 h-2 rounded-full">
        </Arrows>
      </div>
    </>
  );
}
