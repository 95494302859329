import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from "../../components/controls/Dialog";
import { AppDispatch, RootState } from "../../store";
import { NotificationMessageType, showNotificationMessage } from "../notificationMessages/notificationMessagesSlice";
import { getAuthAppUri, setAuthApp2FAEnabled } from "./api";
import QRCode from 'react-qr-code'
import { LoadingState } from "../../components/controls/LoadingState";
import { t } from "i18next";

export interface AuthAppDialogProps {
  open: boolean,
  onClose: () => void,
}

export function AuthAppDialog({ open, onClose }: AuthAppDialogProps) {
  const token = useSelector((state: RootState) => state.authentication.token)
  const dispatch = useDispatch<AppDispatch>()

  const [secret, setSecret] = useState<string>('')
  const [uri, setUri] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const [passwordError, setPasswordError] = useState<string>()

  const getSecret = async () => {
    const res = await getAuthAppUri(token!)
    setSecret(res.secret)
    setUri(res.uri)
  }

  useEffect(() => {
    if (open) {
      setSecret('')
      setPassword('')
    }
    getSecret()
  }, [open])

  const setEnabled = async () => {
    if (password === '') {
      setPasswordError('Required')
      return
    }
    try {
      await setAuthApp2FAEnabled(token!, true, secret, password)
      onClose()
      dispatch(showNotificationMessage({
        type: NotificationMessageType.Success,
        title: t('Auth App 2FA Enabled!'),
      }))
    } catch (e: any) {
      dispatch(showNotificationMessage({
        type: NotificationMessageType.Error,
        title: t('Error Enabling Auth App 2FA'),
        body: e.toString(),
      }))
    }
  }

  const accept = (e?: any) => {
    if (e) e.preventDefault()
    setEnabled()
    return false
  }

  const controls =[
    {
      id: 'enable',
      text: t('Enable 2FA'),
      isPrimary: true,
      onClick: () => setEnabled(),
    },
    {
      id: 'close',
      text: t('Close'),
      onClick: () => onClose(),
    },
  ]
  
  return (
    <Dialog title={t('Auth App 2FA')} open={open} onClose={() => onClose()} controls={controls} >
      <form className="flex-1 flex flex-col gap-2" onSubmit={(e) => accept(e)}>
        <div className="flex justify-center">
          <div className="p-2 border rounded">
            <LoadingState isLoading={!uri}>
              <QRCode value={uri} />
            </LoadingState>
          </div>
        </div>
        <div>
          <label htmlFor="oneTimePassword" className="block text-sm font-medium leading-6 text-gray-900">
            {t('Secret')}
          </label>
          <div className="mt-2">
            <input type="text" className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" value={secret} readOnly />
          </div>
        </div>
        <p>
          {t('Scan this QR code or enter the secret manually in Google Authenticator or Microsoft Authenticator and type the One Time Password below')}
        </p>
        <div>
          <label htmlFor="oneTimePassword" className="block text-sm font-medium leading-6 text-gray-900">
            {t('One Time Password')}
          </label>
          <div className="mt-2">
            <input
              id="oneTimePassword"
              type="text"
              className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {passwordError && <span className="text-red-700 text-sm">{passwordError}</span>}
          </div>
        </div>
      </form>
    </Dialog>
  )
}
