import React from 'react';
import { IconType } from 'react-icons';

interface CellIconProps {
  icon: IconType,
}

export function CellIcon({ icon }: CellIconProps) {
  return React.createElement(icon, { size: '24px', className: 'text-gray-200' });
}
