import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CellCoords } from 'share2flow-board'

export interface ContextMenuState {
  isDragging: boolean,
  hoveredCellCoords?: CellCoords,
}

const initialState: ContextMenuState = {
  isDragging: false,
  hoveredCellCoords: undefined,
}

export const contextMenuSlice = createSlice({
  name: 'contextMenu',
  initialState,
  reducers: {
    setHoveredCellCoords: (state, action: PayloadAction<CellCoords>) => {
      state.hoveredCellCoords = action.payload
    },
    clearHoveredCellCoords: (state) => {
      state.hoveredCellCoords = undefined
    },
    setIsDragging: (state, action: PayloadAction<boolean>) => {
      state.isDragging = action.payload
    },
  },
})

export const { setHoveredCellCoords, clearHoveredCellCoords, setIsDragging } = contextMenuSlice.actions

export default contextMenuSlice.reducer
