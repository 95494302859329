import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useLockCell } from "../../features/locking";
import { CellFile } from "../cells/CellFile";
import { CellIcon } from "../cells/CellIcon";
import { CellText } from "../cells/CellText";
import { InputDialog, InputSpec } from "../controls/InputDialog";
import { CellContentsProps } from "./CellContents";
import { AppDispatch } from "../../store";
import { useDispatch } from "react-redux";
import { setCell, useCell, useReferencesForSource, useReferencesForTarget } from "../../rootState";
import { useTranslation } from "react-i18next";
import config from "../../config";

export function DocumentFlowCell({ coords }: CellContentsProps) {
  const { t } = useTranslation()
  const data = useCell(coords)
  const [isEditing, setIsEditing] = useState(false)
  const { lock, unlock } = useLockCell(coords)
  const dispatch = useDispatch<AppDispatch>()

  const documentCell = useCell({...coords, column: 0})

  const referencesForSource = useReferencesForSource(data?.id || '') || []
  const referencesForTarget = useReferencesForTarget(data?.id || '') || []

  const tryEdit = async () => {
    const lockAcquired = await lock()
    if (lockAcquired) {
      setIsEditing(true)
    }
  }

  const renderContents = () => {
    if (!data || Object.keys(data).length === 0) {
      return (
        <div className="h-full w-full flex items-center justify-center cursor-pointer" onClick={tryEdit}>
          <CellIcon icon={AiOutlinePlus} />
        </div>
      )
    }

    if (coords.column === 0) {
      return (
        <div className="h-full w-full flex items-center justify-center cursor-pointer" onClick={tryEdit}>
          <CellText centered={true}>{data.name}</CellText>
        </div>
      )
    }

    const references = [
      ...referencesForSource,
      ...referencesForTarget,
    ]
    const isHub = references.length > 2

    return (
      <div className="w-full h-full flex items-center justify-center">
        <CellFile id={data.id} filename={data.filename} url={`${config.backendUrl}${data.file}`} isHub={isHub} onClick={() => setIsEditing(true)} />
      </div>
    )
  }

  const renderEditor = () => {
    const inputSpecs: InputSpec[] = coords.column === 0
      ?[
        {
          id: 'name',
          name: t('Designation'),
          placeholder: t('Enter the designation/name of the media'),
          type: 'text',
          initialValue: data?.name || '',
          maxLength: config.maxHeaderLength,
        },
      ]
      : [
        {
          id: 'filename',
          name: t('Designation/title/name'),
          placeholder: t('Enter the designation here'),
          type: 'text',
          initialValue: data?.filename || '',
        },
        {
          id: 'file',
          name: t('Upload file (if helpful)'),
          type: 'file',
          initialValue: data?.file || '',
        },
      ]
    const dialogTitle = coords.column === 0 ? t('Definition | Media & Documents') : t('Definition | {{name}}', {name: documentCell?.name || ''})

    const onClose = async (accepted: boolean, values?: {[id: string]: string | number | boolean}) => {
      if (accepted) {
        dispatch(setCell(coords, {...data, ...values}))
      }
      setIsEditing(false)
      await unlock()
    }

    return <InputDialog title={dialogTitle} inputSpecs={inputSpecs} open={isEditing} onClose={onClose} />
  }

  return (
    <>
      {renderContents()}
      {renderEditor()}
    </>
  )
}
