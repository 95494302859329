import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ColumnGroup } from "share2flow-board";
import { CustomControl } from "../../components/controls/Dialog";
import { InputDialog, InputSpec } from "../../components/controls/InputDialog";
import { createColumnGroup, deleteColumnGroup, updateColumnGroup, useColumnGroupById, useMaxColumnCount } from "../../rootState";
import { AppDispatch, RootState } from "../../store";
import { NotificationMessageType, showNotificationMessage } from "../notificationMessages/notificationMessagesSlice";

export interface GroupDialogProps {
  open: boolean,
  onClose: () => void,
  groupId?: string,
}

export function GroupDialog({ open, onClose, groupId }: GroupDialogProps) {
  const { t } = useTranslation()
  const groups = useSelector((state: RootState) => state.boardState.board?.columnGroups)
  const group = useColumnGroupById(groupId)
  const dispatch = useDispatch<AppDispatch>()
  const numColumns = useMaxColumnCount()

  const colorChoices = [
    '#EDF2C0',
    '#FBE5D6',
    '#DEEBF7',
    '#E2F0D9',
    '#D6DCE5'
  ]

  const inputSpecs: InputSpec[] = [
    {
      id: 'name',
      name: t('Designation of the process group'),
      placeholder: t('Enter the designation of the process group'),
      type: 'text',
      initialValue: group?.name || '',
    },
    {
      id: 'color',
      name: t('Color'),
      type: 'colorSelect',
      options: colorChoices.map(color => ({ label: color, value: color })),
      initialValue: group?.color || '#ffffff',
    },
    {
      id: 'startColumn',
      name: t('Start Column'),
      type: 'number',
      initialValue: group?.startColumn || 1,
    },
    {
      id: 'endColumn',
      name: t('End Column'),
      type: 'number',
      initialValue: group?.endColumn || 1,
    },
  ]

  const isThereOverlap = (startColumn: number, endColumn: number): boolean => {
    if (!groups) return false

    for (const group of groups) {
      if (group.id !== groupId &&
          (group.startColumn <= startColumn && startColumn <= group.endColumn ||
           group.startColumn <= endColumn && endColumn <= group.endColumn ||
           startColumn <= group.startColumn && group.startColumn <= endColumn ||
           startColumn <= group.endColumn && group.endColumn <= endColumn)) {
        return true
      }
    }

    return false
  }

  const onCloseDialog = async (accepted: boolean, values?: {[id: string]: string | number | boolean}) => {
    if (accepted && values) {
      const startColumn = values.startColumn as number
      const endColumn = values.endColumn as number
      if (isThereOverlap(startColumn, endColumn)) {
        dispatch(showNotificationMessage({
          type: NotificationMessageType.Error,
          title: t('Error setting group'),
          body: t('This specified range overlaps with another group'),
        }))
        return
      }

      if (startColumn > endColumn) {
        dispatch(showNotificationMessage({
          type: NotificationMessageType.Error,
          title: t('Error setting group'),
          body: t('End column must be greater than start column'),
        }))
        return
      }

      if (startColumn < 1) {
        dispatch(showNotificationMessage({
          type: NotificationMessageType.Error,
          title: t('Error setting group'),
          body: t('Start column must be equal to or greater than 1'),
        }))
        return
      }

      if (endColumn > numColumns) {
        dispatch(showNotificationMessage({
          type: NotificationMessageType.Error,
          title: t('Error setting group'),
          body: t(`End column must be equal to or less than {{endColumn}}`, { endColumn: numColumns }),
        }))
        return
      }

      if (group) {
        dispatch(updateColumnGroup({...values, id: group.id} as ColumnGroup))
      } else {
        dispatch(createColumnGroup(values as any as ColumnGroup))
      }
    }
    onClose()
  }

  const extraControls: CustomControl[] = group ? [
    {
      id: 'delete',
      text: t('Delete'),
      onClick: () => {
        onClose()
        dispatch(deleteColumnGroup(group.id))
      },
    },
  ] : []

  return (
    <InputDialog
      title={t('Definition | Process Group')}
      inputSpecs={inputSpecs}
      open={open}
      onClose={onCloseDialog}
      controls={extraControls}
      acceptLabel={group ? t('Update') : t('Create')}
    />
  )
}
