import { Fragment, useEffect, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { BiCheck, BiChevronDown, BiTrash } from 'react-icons/bi'
import { NamedIcon, NamedIcons } from './NamedIcon'
import config from '../../config'
import { CustomControl, Dialog } from '../controls/Dialog'
import { uploadFile } from '../../api'
import { useTranslation } from 'react-i18next'
import { formatGlobalFloat, parseGlobalFloat } from '../../utils'

export interface InputSpec {
  id: string,
  name: string,
  placeholder?: string,
  type: 'text' | 'select' | 'number' | 'checkbox' | 'textarea' | 'color' | 'avatar' | 'image' | 'file' | 'icon' | 'colorSelect',
  options?: {
    value: string,
    label: string,
  }[],
  initialValue: string | number | boolean,
  maxLength?: number,
  validator?: (value: string | number | boolean) => boolean,
  precondition?: (values: {[id: string]: string | number | boolean}) => boolean,
}

export interface InputDialogProps {
  title: string,
  inputSpecs: InputSpec[],
  open: boolean,
  onClose: (accepted: boolean, values?: {[id: string]: string | number | boolean}) => void,
  controls?: CustomControl[],
  acceptLabel?: string,
}

export function InputDialog({ title, inputSpecs, open, onClose, controls, acceptLabel }: InputDialogProps) {
  const { t, i18n } = useTranslation()
  const [values, setValues] = useState<(string | number | boolean)[]>(inputSpecs.map((spec) => spec.initialValue))
  const [isUploading, setIsUploading] = useState(false)

  useEffect(() => {
    setValues(inputSpecs.map((spec) => {
      if (spec.type === 'number') {
        return formatGlobalFloat(i18n.language, spec.initialValue)
      }
      return spec.initialValue
    }))
  }, [open])

  const renderInputField = (spec: InputSpec, idx: number) => {
    if (spec.precondition && !spec.precondition(getOutputValues())) {
      return <></>
    }
    switch (spec.type) {
      case 'text':
        return (
          <div key={spec.id}>
            <label htmlFor={spec.id} className="block text-sm font-medium leading-6 text-gray-900">
              {spec.name}
            </label>
            <div className="mt-2">
              <input
                id={spec.id}
                name={spec.name}
                placeholder={spec.placeholder}
                maxLength={spec.maxLength}
                type="text"
                className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={values[idx] as string}
                onChange={(e) => setValues(values.map((spec, index) => index === idx ? e.target.value : spec))}
              />
            </div>
          </div>
        )
      case 'number':
        return (
          <div key={spec.id}>
            <label htmlFor={spec.id} className="block text-sm font-medium leading-6 text-gray-900">
              {spec.name}
            </label>
            <div className="mt-2">
              <input
                id={spec.id}
                name={spec.name}
                placeholder={spec.placeholder}
                type="text"
                className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={values[idx].toString()}
                onChange={(e) => setValues(values.map((spec, index) => index === idx ? e.target.value : spec))}
              />
            </div>
          </div>
        )
      case 'select':
        return (
          <div key={spec.id}>
            <label htmlFor={spec.id} className="block text-sm font-medium leading-6 text-gray-900">
              {spec.name}
            </label>
            <div className="mt-2">
              <select
                id={spec.id}
                name={spec.name}
                className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={values[idx] as string}
                onChange={(e) => setValues(values.map((spec, index) => index === idx ? e.target.value : spec))}
              >
                {spec.options!.map((option) => <option key={option.value} value={option.value}>{option.label}</option>)}
              </select>
            </div>
          </div>
        )
      case 'icon':
        return (
          <Listbox key={spec.id} value={values[idx] as string} onChange={(value) => setValues(values.map((spec, index) => index === idx ? value : spec))}>
            {({ open }) => (
              <>
                <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">{spec.name}</Listbox.Label>
                <div className="relative mt-2">
                  <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                    <span className="flex items-center">
                      {values[idx] && <NamedIcon iconName={values[idx] as NamedIcons} className="h-5 w-5 flex-shrink-0" />}&nbsp;
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                      <BiChevronDown className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {spec.options!.map((icon) => (
                        <Listbox.Option
                          key={icon.value}
                          className={({ active }) =>
                            `${active ? 'bg-indigo-600 text-white' : 'text-gray-900'} relative cursor-default select-none py-2 pl-3 pr-9`
                          }
                          value={icon.value}
                        >
                          {({ selected, active }) => (
                            <>
                              <div className="flex items-center">
                                {icon.value && <NamedIcon iconName={icon.value as NamedIcons} className="h-5 w-5 flex-shrink-0" />}&nbsp;
                              </div>

                              {selected ? (
                                <span
                                  className={`${active ? 'text-white' : 'text-indigo-600'} absolute inset-y-0 right-0 flex items-center pr-4`}
                                >
                                  <BiCheck className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
      )
      case 'textarea':
        return (
          <div key={spec.id}>
            <label htmlFor={spec.id} className="block text-sm font-medium leading-6 text-gray-900">
              {spec.name}
            </label>
            <div className="mt-2">
              <textarea
                id={spec.id}
                name={spec.name}
                placeholder={spec.placeholder}
                maxLength={spec.maxLength}
                rows={5}
                className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={values[idx] as string}
                onChange={(e) => setValues(values.map((spec, index) => index === idx ? e.target.value : spec))}
              />
            </div>
          </div>
        )
      case 'color':
        return (
          <div key={spec.id}>
            <label htmlFor={spec.id} className="block text-sm font-medium leading-6 text-gray-900">
              {spec.name}
            </label>
            <div className="mt-2">
              <input
                id={spec.id}
                name={spec.name}
                type="color"
                className="block w-full h-12 rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={values[idx] as string}
                onChange={(e) => setValues(values.map((spec, index) => index === idx ? e.target.value : spec))}
              />
            </div>
          </div>
        )
      case 'avatar':
        return (
          <div key={spec.id}>
            <label htmlFor={spec.id} className="block text-sm font-medium leading-6 text-gray-900">
              {spec.name}
            </label>
            <div className="mt-2 flex items-center gap-x-3">
              {values[idx]
                && <img src={`${config.backendUrl}${values[idx]}`} className="h-12 w-12 rounded-full text-gray-300 object-cover" aria-hidden="true" />
              }
              <input
                id={spec.id}
                name={spec.name}
                type="file"
                className="rounded-md bg-white py-1.5 px-2.5 w-full text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                onChange={async (event) => {
                  const image = event.target.files?.[0]
                  if (image) {
                    setIsUploading(true)
                    const url = await uploadFile(image)
                    setIsUploading(false)
                    setValues(values.map((spec, index) => index === idx ? url : spec))
                  }
                }}
              />
            </div>
          </div>
        )
      case 'image':
        return (
          <div key={spec.id}>
            <label htmlFor={spec.id} className="block text-sm font-medium leading-6 text-gray-900">
              {spec.name}
            </label>
            <div className="mt-2 flex flex-col gap-y-3">
              {values[idx]
                && <img src={`${config.backendUrl}${values[idx]}`} className="w-full h-auto text-gray-300 object-cover" aria-hidden="true" />
              }
              <input
                id={spec.id}
                name={spec.name}
                type="file"
                className="rounded-md bg-white py-1.5 px-2.5 w-full text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                onChange={async (event) => {
                  const image = event.target.files?.[0]
                  if (image) {
                    setIsUploading(true)
                    const url = await uploadFile(image)
                    setIsUploading(false)
                    setValues(values.map((spec, index) => index === idx ? url : spec))
                  }
                }}
              />
            </div>
          </div>
        )
      case 'file':
        return (
          <div key={spec.id}>
            <label htmlFor={spec.id} className="block text-sm font-medium leading-6 text-gray-900">
              {spec.name}
            </label>
            <div className="mt-2 flex flex-col gap-y-3">
              {values[idx] &&
                <div className="w-full flex items-stretch">
                  <a
                    href={`${config.backendUrl}${(values[idx] as string)}`} 
                    target="_blank"
                    className="inline-flex flex-1 justify-center rounded-l-md bg-indigo-600 px-3 py-2 w-full text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"

                  >{(values[idx] as string).split('/')[(values[idx] as string).split('/').length-1]}</a>
                  <button
                    onClick={() => setValues(values.map((spec, index) => index === idx ? '' : spec))}
                    className="px-3 rounded-r-md bg-red-600 text-white shadow-sm hover:bg-red-500 cursor-pointer"
                  ><BiTrash /></button>
                </div>}
              <input
                id={spec.id}
                name={spec.name}
                type="file"
                className="rounded-md bg-white py-1.5 px-2.5 w-full text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                onChange={async (event) => {
                  const file = event.target.files?.[0]
                  if (file) {
                    setIsUploading(true)
                    const url = await uploadFile(file)
                    setIsUploading(false)
                    setValues(values.map((spec, index) => index === idx ? url : spec))
                  }
                }}
              />
            </div>
          </div>
        )
      case 'colorSelect':
        return (
          <div key={spec.id}>
            <label htmlFor={spec.id} className="block text-sm font-medium leading-6 text-gray-900">
              {spec.name}
            </label>
            <div className="mt-2 flex flex-col gap-y-3">
              <div className="flex flex-wrap gap-2">
                {spec.options!.map((color) => (
                  <div
                    key={color.value}
                    className={`w-8 h-8 rounded-full cursor-pointer ${color.value === values[idx] ? 'ring-2 ring-offset-2 ring-indigo-500' : ''}`}
                    style={{ backgroundColor: color.value }}
                    onClick={() => setValues(values.map((spec, index) => index === idx ? color.value : spec))}
                  />
                ))}
              </div>
            </div>
          </div>
        )
      case 'checkbox':
        return (
          <div key={spec.id} className="flex items-center">
            <input
              id={spec.id}
              name={spec.name}
              type="checkbox"
              className="rounded border-gray-300 text-indigo-600 shadow-sm focus:ring-indigo-500"
              checked={values[idx] as boolean}
              onChange={(e) => setValues(values.map((spec, index) => index === idx ? e.target.checked : spec))}
            />
            <label htmlFor={spec.id} className="ml-2 block text-sm font-medium leading-6 text-gray-900">
              {spec.name}
            </label>
          </div>
        )
    }
  }

  const getOutputValues = () => {
    let output: {[id: string]: string | number | boolean} = {}
    for (let i = 0; i < inputSpecs.length; i++) {
      if (inputSpecs[i].type === 'number') {
        output[inputSpecs[i].id] = parseGlobalFloat(values[i])
      } else {
        output[inputSpecs[i].id] = values[i]
      }
    }
    return output
  }

  const accept = (e?: any) => {
    if (e) e.preventDefault()
    onClose(true, getOutputValues())
    return false
  }

  const reject = () => {
    onClose(false)
    return false
  }

  const allControls = [
    {
      id: 'update',
      text: acceptLabel || t('Accept'),
      isPrimary: true,
      onClick: () => accept(),
    },
    {
      id: 'cancel',
      text: t('Cancel'),
      onClick: () => reject(),
    },
    ...(controls || []),
  ]

  return (
    <Dialog title={title} open={open} onClose={() => reject()} controls={allControls} controlsDisabled={isUploading}>
      <form className="flex-1 flex flex-col gap-2" onSubmit={(e) => accept(e)}>
        {inputSpecs.map(renderInputField)}
      </form>
    </Dialog>
  )
}
