import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from "../../components/controls/Dialog";
import { AppDispatch, RootState } from "../../store";
import { NotificationMessageType, showNotificationMessage } from "../notificationMessages/notificationMessagesSlice";
import { requestPhoneVerification, validatePhoneNumber } from "./api";

export interface PhoneVerificationDialogProps {
  open: boolean,
  onClose: () => void,
}

export function PhoneVerificationDialog({ open, onClose }: PhoneVerificationDialogProps) {
  const token = useSelector((state: RootState) => state.authentication.token)
  const dispatch = useDispatch<AppDispatch>()

  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [codeRequested, setCodeRequested] = useState<boolean>(false)

  const [phoneNumberError, setPhoneNumberError] = useState<string>()
  const [passwordError, setPasswordError] = useState<string>()

  useEffect(() => {
    if (open) {
      setPhoneNumber('')
      setPassword('')
      setCodeRequested(false)
      setPhoneNumberError(undefined)
    }
  }, [open])

  const requestCode = async () => {
    if (phoneNumber === '') {
      setPhoneNumberError(t('Required'))
      return
    }
    try {
      await requestPhoneVerification(token!, phoneNumber)
    } catch (e: any) {
      dispatch(showNotificationMessage({
        type: NotificationMessageType.Error,
        title: t('Error Sending Code'),
        body: e.toString(),
      }))
    }
    setCodeRequested(true)
  }

  const verifyPhone = async () => {
    if (password === '') {
      setPasswordError(t('Required'))
      return
    }
    try {
      await validatePhoneNumber(token!, phoneNumber, password)
      onClose()
      dispatch(showNotificationMessage({
        type: NotificationMessageType.Success,
        title: t('Phone Number Verified!'),
      }))
    } catch (e: any) {
      dispatch(showNotificationMessage({
        type: NotificationMessageType.Error,
        title: t('Error Sending Code'),
        body: e.toString(),
      }))
    }
  }

  const accept = (e?: any) => {
    if (e) e.preventDefault()
    if (codeRequested) {
      verifyPhone()
    } else {
      requestCode()
    }
    return false
  }

  const controls = codeRequested
    ? [
      {
        id: 'verifyPhone',
        text: t('Verify phone'),
        isPrimary: true,
        onClick: () => verifyPhone(),
      },
      {
        id: 'requestCode',
        text: t('Resend code'),
        onClick: () => requestCode(),
      },
      {
        id: 'close',
        text: t('Close'),
        onClick: () => onClose(),
      },
    ]
    : [
      {
        id: 'requestCode',
        text: t('Request code'),
        isPrimary: true,
        onClick: () => requestCode(),
      },
      {
        id: 'close',
        text: t('Close'),
        onClick: () => onClose(),
      },
    ]
  
  return (
    <Dialog title={t('Phone Verification')} open={open} onClose={() => onClose()} controls={controls} >
      <form className="flex-1 flex flex-col gap-2" onSubmit={(e) => accept(e)}>
        <div>
          <label htmlFor="phoneNumber" className="block text-sm font-medium leading-6 text-gray-900">
            {t('Phone number')}
          </label>
          <div className="mt-2">
            <input
              id="phoneNumber"
              type="text"
              className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              readOnly={codeRequested}
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            {phoneNumberError && <span className="text-red-700 text-sm">{phoneNumberError}</span>}
          </div>
        </div>
        { codeRequested &&
          <div>
            <label htmlFor="verificationCode" className="block text-sm font-medium leading-6 text-gray-900">
              {t('Verification code')}
            </label>
            <div className="mt-2">
              <input
                id="verificationCode"
                type="text"
                className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {passwordError && <span className="text-red-700 text-sm">{passwordError}</span>}
            </div>
          </div>
        }
      </form>
    </Dialog>
  )
}
