import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { AiOutlineUnlock } from "react-icons/ai"
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import config from "../../config"
import { RootState } from "../../store"

export function Invite() {
  const { t } = useTranslation()
  const params = useParams()
  const inviteId = params.inviteId!
  const token = useSelector((state: RootState) => state.authentication.token)
  const navigate = useNavigate()

  const [password, setPassword] = useState<string>('')
  const [requiresPassword, setRequiresPassword] = useState<boolean>(false)
  const [error, setError] = useState<string>()

  const redeemInvite = async () => {
    const res = await fetch(`${config.apiUrl}/sharing/${inviteId}/redeem`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ password }),
    })
    if (res.status === 200) {
      const resJson = await res.json()
      navigate(`/boards/${resJson.boardId}`)
    } else if (res.status === 401 && !requiresPassword) {
      setRequiresPassword(true)
    } else {
      setError(await res.text())
    }
  }

  useEffect(() => {
    redeemInvite()
  }, [])

  if (requiresPassword) {
    return (
      <div className="w-screen h-screen flex flex-col items-center justify-center p-8 gap-4">
        <h1 className="text-3xl font-bold">{t('This invite requires a password')}</h1>
        <form onSubmit={(e) => { redeemInvite(); e.preventDefault() }} className="mt-2 flex rounded-md shadow-sm">
          <div className="relative flex flex-grow items-stretch focus-within:z-10">
            <input
              type="password"
              placeholder={t('Password')}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
          <button
            type="submit"
            className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            <AiOutlineUnlock className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
            {t('Unlock')}
          </button>
        </form>
        {error && <p className="text-red-800">{t('Error')}: {error}</p>}
      </div>
    )
  }

  if (error) {
    return (
      <div className="w-screen h-screen flex flex-col items-center justify-center p-8 gap-4">
        <h1 className="text-3xl font-bold text-red-800">{error}</h1>
      </div>
    )
  }

  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center p-8 gap-4">
      <h1 className="text-3xl font-bold">{t('Redeeming invite')}</h1>
      <p>{t('Please wait one moment...')}</p>
    </div>
  )
}
