import { UploadFileResponse } from "share2flow-typedefs"
import config from "./config"

export async function uploadFile(file: File): Promise<string> {
  const data = new FormData()
  data.append('file', file)
  const res = await fetch(`${config.apiUrl}/fileUpload`, {
    method: 'POST',
    body: data,
  })
  if (res.status === 200) {
    const resJson: UploadFileResponse = await res.json()
    return `${resJson.path}`
  }
  throw 'Error uploading file'
}
