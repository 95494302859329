import { AiOutlineCheck } from 'react-icons/ai';
import { CellCoords, SectionIdentifier } from 'share2flow-board';
import { deleteCell, deleteColumn, deleteRow, insertColumn, insertRow, setCell, useCell } from '../../rootState';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { useState } from 'react';
import { PopupButton } from '../controls/PopupButton';
import { NamedIcons } from '../controls/NamedIcon';
import { PopupMenu } from '../controls/PopupMenu';
import { useTranslation } from 'react-i18next';
import { setClipboardContents } from '../../features/clipboard/clipboardSlice';

export type CheckmarkType = 'unchecked' | 'checked';

interface CellProps {
  coords: CellCoords,
  id?: string,
  zoomScale: number,
}

function zoomUnit(base: number, scale: number, unit: string): string {
  return `${base * scale}${unit}`
}

export function Cell({ children, coords, zoomScale, id }: React.PropsWithChildren<CellProps>) {
  const data = useCell(coords)
  const dispatch = useDispatch<AppDispatch>()
  const isDragging = useSelector((state: RootState) => state.contextMenu.isDragging)
  const [showContextMenu, setShowContextMenu] = useState<boolean>(false)
  const [contextMenuTop, setContextMenuTop] = useState<number>(0)
  const [contextMenuLeft, setContextMenuLeft] = useState<number>(0)

  const checkmark = data?.checkmark
  const editableCheckmark = coords.section === SectionIdentifier.PROBLEMS_AND_DIFFICULTIES

  const toggleCheckmark = () => {
    if (!editableCheckmark) return

    dispatch(setCell(coords, {...data, checkmark: (checkmark === 'checked'? 'unchecked' : 'checked')}))
  }

  const onMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.button !== 2 || isDragging) return

    setShowContextMenu(true)
    setContextMenuLeft(e.clientX)
    setContextMenuTop(e.clientY)
  }

  return (
    <div className="hover:bg-indigo-100 bg-opacity-50 border border-300 text-gray-700 flex items-center justify-center relative" id={id} style={{ width: zoomUnit(192, zoomScale, 'px'), height: zoomUnit(96, zoomScale, 'px') }} onMouseUp={onMouseUp}>
      <div className="absolute top-0 left-0" style={{ transform: `scale(${zoomScale})`, transformOrigin: '0 0', width: zoomUnit(192, 1, 'px'), height: zoomUnit(96, 1, 'px') }}>
        {children}
        {checkmark &&
          <div className={`w-8 h-8 absolute bottom-[2px] right-[2px] rounded-tl-xl flex items-center justify-center cursor-pointer ${checkmark === 'checked' ? 'bg-success-200 text-white' : 'bg-neutral-300 text-success-200'} ${editableCheckmark ? '' : 'pointer-events-none'}`} onClick={() => toggleCheckmark()}>
            <AiOutlineCheck size="16px" />
          </div>}
      </div>
      <ContextMenu show={showContextMenu} onClose={() => setShowContextMenu(false)} cellCoords={coords} left={contextMenuLeft} top={contextMenuTop} />
    </div>
  )
}

interface ContextMenuProps {
  show: boolean,
  onClose: () => void
  cellCoords?: CellCoords,
  left: number,
  top: number,
}

function ContextMenu({ show, onClose, cellCoords, left, top }: ContextMenuProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const sections = useSelector((state: RootState) => state.boardState.board?.sections)
  const clipboardContents = useSelector((state: RootState) => state.clipboard.contents)

  const cutCellF = () => {
    if (!cellCoords || !sections) return
    const cell = sections[cellCoords.section]?.[cellCoords.row]?.[cellCoords.column]

    dispatch(setClipboardContents({ coords: cellCoords, cell: cell }))
    dispatch(deleteCell(cellCoords))
    onClose()
  }

  const copyCellF = () => {
    if (!cellCoords || !sections) return
    const cell = sections[cellCoords.section]?.[cellCoords.row]?.[cellCoords.column]

    let newCell = { ...cell }
    newCell.id = undefined

    dispatch(setClipboardContents({ coords: cellCoords, cell: newCell }))
    onClose()
  }

  const pasteCellF = () => {
    if (!cellCoords || !clipboardContents) return
    if (cellCoords.section !== clipboardContents.coords.section) return
    if (clipboardContents.cell) {
      dispatch(setCell(cellCoords, { ...clipboardContents.cell }))

      // Delete cell ID so that it is regenerated on the next paste
      if (clipboardContents.cell.id) {
        let newCell = { ...clipboardContents.cell }
        newCell.id = undefined
        dispatch(setClipboardContents({ coords: cellCoords, cell: newCell }))
      }
    }
    onClose()
  }

  const deleteCellF = () => {
    if (!cellCoords) return
    dispatch(deleteCell(cellCoords))
    onClose()
  }

  const insertRowAbove = () => {
    if (!cellCoords) return
    dispatch(insertRow(cellCoords))
    onClose()
  }

  const insertRowBelow = () => {
    if (!cellCoords) return
    dispatch(insertRow({ ...cellCoords, row: cellCoords.row + 1 }))
    onClose()
  }

  const deleteRowF = () => {
    if (!cellCoords) return
    dispatch(deleteRow(cellCoords))
    onClose()
  }

  const insertColumnLeft = () => {
    if (!cellCoords) return
    dispatch(insertColumn(cellCoords))
    onClose()
  }

  const insertColumnRight = () => {
    if (!cellCoords) return
    dispatch(insertColumn({ ...cellCoords, column: cellCoords.column + 1 }))
    onClose()
  }

  const deleteColumnF = () => {
    if (!cellCoords) return
    dispatch(deleteColumn(cellCoords))
    onClose()
  }

  return (
    <PopupMenu isVisible={show} onDismiss={() => onClose()} width={300} left={left} top={top}>
      <PopupButton
        key="Cut cell"
        iconName={NamedIcons.CUT}
        text={t('Cut cell')}
        onClick={() => cutCellF()}
      />
      <PopupButton
        key="Copy cell"
        iconName={NamedIcons.COPY}
        text={t('Copy cell')}
        onClick={() => copyCellF()}
      />
      {clipboardContents && clipboardContents.coords.section === cellCoords?.section &&
        (cellCoords?.column === 0) === (clipboardContents.coords.column === 0) &&
        <PopupButton
        key="Paste cell"
        iconName={NamedIcons.PASTE}
        text={t('Paste cell')}
        onClick={() => pasteCellF()}
      />}
      <PopupButton
        key="Delete cell"
        iconName={NamedIcons.TRASH}
        text={t('Delete cell')}
        onClick={() => deleteCellF()}
      />
      <PopupButton
        key="Insert row above"
        text={t('Insert row above')}
        iconName={NamedIcons.ARROW_UP}
        onClick={() => insertRowAbove()}
      />
      <PopupButton
        key="Insert row below"
        text={t('Insert row below')}
        iconName={NamedIcons.ARROW_DOWN}
        onClick={() => insertRowBelow()}
      />
      <PopupButton
        key="Delete row"
        iconName={NamedIcons.TRASH}
        text={t('Delete row')}
        onClick={() => deleteRowF()}
      />
      <PopupButton
        key="Insert column to left"
        text={t('Insert column to left')}
        iconName={NamedIcons.ARROW_LEFT}
        onClick={() => insertColumnLeft()}
      />
      <PopupButton
        key="Insert column to right"
        text={t('Insert column to right')}
        iconName={NamedIcons.ARROW_RIGHT}
        onClick={() => insertColumnRight()}
      />
      <PopupButton
        key="Delete column"
        iconName={NamedIcons.TRASH}
        text={t('Delete column')}
        onClick={() => deleteColumnF()}
      />
    </PopupMenu>
  )
}
