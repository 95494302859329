import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { LanguageDetector } from "../../components/controls/LanguageDetector"
import { AppDispatch, RootState } from "../../store"
import { NotificationMessageType, showNotificationMessage } from "../notificationMessages/notificationMessagesSlice"
import { authenticateGoogle } from "./api"
import { setSignInUserState } from "./authenticationSlice"

export function GoogleCallback() {
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const token = useSelector((state: RootState) => state.authentication.token)

  useEffect(() => {
    const query = new URLSearchParams(window.location.search)
    authenticateGoogle(query.toString()).then((res) => {
      dispatch(setSignInUserState({
        type: 'success',
        token: res.token,
        useSession: true,
      }))
    }).catch((e) => {
      dispatch(showNotificationMessage({
        type: NotificationMessageType.Error,
        title: t('Error authenticating with Google'),
        body: e.message.toString(),
      }))

      navigate('/')
    })
  }, [])

  useEffect(() => {
    if (token) {
      navigate('/')
    }
  }, [token])

  return (
    <>
      <LanguageDetector />
      <h1>{t('Please wait...')}</h1>
    </>
  )
}
