import { useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom";

export interface TooltipProps {
  title?: string,
  className?: string,
  children: React.ReactNode,
}

export function Tooltip({ title, className, children }: TooltipProps) {
  const [show, setShow] = useState(false)
  const [pLeft, setPLeft] = useState<number>()
  const [pTop, setPTop] = useState<number>()
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const rect = ref.current?.getBoundingClientRect()
    if (rect) {
      setPLeft(rect.left + rect.width + 8)
      setPTop(rect.top + rect.height / 2)
    }
  }, [show])

  const portalEl = document.body

  if (!title) return (
    <div className={className}>
      {children}
    </div>
  )

  return (
    <div ref={ref} className={className}>
      <div onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
        {children}
      </div>
      {portalEl && createPortal(
        <div className={`fixed z-[1002] transition transition-opacity ease-in-out duration-200 flex flex-col justify-center pointer-events-none ${show ? 'opacity-100' : 'opacity-0'}`} style={{ left: pLeft, top: pTop! - 100, height: 200 }}>
          <div className="bg-black text-gray-200 text-sm px-2 py-1 rounded-md shadow-md whitespace-nowrap">
            {title}
          </div>
        </div>
      , portalEl)}
    </div>
  )
}
