import { Fragment, useEffect, useState } from 'react'
import { Dialog, Listbox, Transition } from '@headlessui/react'
import 'react-calendar/dist/Calendar.css';
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { hideDialog } from './sharingSlice'
import config from '../../config';
import { HiCheck, HiChevronUpDown } from 'react-icons/hi2';
import { rolesList, UserBoardRole } from '../authorization';
import { AiOutlinePaperClip, AiOutlineSend } from 'react-icons/ai';
import { NotificationMessageType, showNotificationMessage } from '../notificationMessages/notificationMessagesSlice';
import QRCode from 'react-qr-code'
import { useTranslation } from 'react-i18next';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export interface SharingDialogProps {
  boardId: string,
  boardTitle: string,
}

export function SharingDialog({ boardId, boardTitle }: SharingDialogProps) {
  const { t } = useTranslation()
  const dialogOpen = useSelector((state: RootState) => state.sharing.dialogOpen)
  const token = useSelector((state: RootState) => state.authentication.token)
  const dispatch = useDispatch<AppDispatch>()

  const [maxRedeems, setMaxRedeems] = useState<number>(1)
  const [role, setRole] = useState<string>(UserBoardRole.VIEWER)
  const [inviteId, setInviteId] = useState<string>()
  const [password, setPassword] = useState<string>('')
  const [shareEmail, setShareEmail] = useState<string>('')

  // TODO Do not show roles for which the user cannot create invites

  const address = `${window.location.protocol}//${window.location.host}`
  const inviteLink = `${address}/invites/${inviteId}`

  const createInvite = async () => {
    const res = await fetch(`${config.apiUrl}/sharing`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ boardId, maxRedeems, role, password }),
    })
    if (res.status >= 400) {
      dispatch(showNotificationMessage({
        type: NotificationMessageType.Error,
        title: t('Error creating invite'),
        body: await res.text(),
      }))
    }
    if (res.status === 200) {
      const resJson = await res.json()
      setInviteId(resJson.inviteId)
    }
  }

  const sendInvite = async () => {
    const res = await fetch(`${config.apiUrl}/sharing/${inviteId}/send`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ email: shareEmail, boardTitle }),
    })
    if (res.status >= 400) {
      dispatch(showNotificationMessage({
        type: NotificationMessageType.Error,
        title: t('Error sending invite'),
        body: await res.text(),
      }))
    }
    if (res.status === 200) {
      dispatch(showNotificationMessage({
        type: NotificationMessageType.Success,
        title: t('Invite sent!'),
      }))
    }
  }

  useEffect(() => {
    if (!dialogOpen) {
      setMaxRedeems(1)
      setRole(UserBoardRole.VIEWER)
      setInviteId(undefined)
      setPassword('')
      setShareEmail('')
    }
  }, [dialogOpen])

  const onClose = () => {
    dispatch(hideDialog())
  }

  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(inviteLink)
      dispatch(showNotificationMessage({
        type: NotificationMessageType.Success,
        title: t('Link copied to clipboard!'),
      }))
    } catch (e: any) {
      dispatch(showNotificationMessage({
        type: NotificationMessageType.Error,
        title: e.toString(),
      }))
    }
  }

  return (
    <Transition.Root show={dialogOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[1000]" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg border-2 border-purple-700">
                <div className="m-3 sm:m-5">
                  {inviteId
                    ? <>
                      <Dialog.Title as="h3" className="text-xl font-semibold leading-6 text-gray-900">
                        {t('Invite Link')}
                      </Dialog.Title>
                      <div className="mt-4">
                        <div className="flex items-center justify-center mb-4">
                          <div className="p-2 border rounded">
                            <QRCode value={inviteLink} />
                          </div>
                        </div>
                        <div className="mt-2 flex rounded-md shadow-sm">
                          <div className="relative flex flex-grow items-stretch focus-within:z-10">
                            <input
                              type="text"
                              readOnly={true}
                              value={inviteLink}
                              className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                          <button
                            type="button"
                            onClick={() => copyLink()}
                            className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          >
                            <AiOutlinePaperClip className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                            {t('Copy')}
                          </button>
                        </div>
                        <form onSubmit={(e) => { sendInvite(); e.preventDefault() }} className="mt-2 flex rounded-md shadow-sm">
                          <div className="relative flex flex-grow items-stretch focus-within:z-10">
                            <input
                              type="email"
                              placeholder={t('Email')}
                              value={shareEmail}
                              onChange={(e) => setShareEmail(e.target.value)}
                              className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                          <button
                            type="submit"
                            className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          >
                            <AiOutlineSend className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                            {t('Send')}
                          </button>
                        </form>
                      </div>
                      </>
                    : <>
                      <Dialog.Title as="h3" className="text-xl font-semibold leading-6 text-gray-900">
                        {t('Create Invite')}
                      </Dialog.Title>
                      <div className="mt-4 grid gap-4 md:grid-cols-2">
                        <div>
                          <Listbox value={maxRedeems} onChange={(selectedMaxRedeems) => setMaxRedeems(selectedMaxRedeems)}>
                            {({ open }) => (
                              <>
                                <div className="relative flex-1">
                                  <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                    <span className="block truncate">{maxRedeems} {maxRedeems === 1 ? t('Redemption') : t('Redemptions')}</span>
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                      <HiChevronUpDown className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </span>
                                  </Listbox.Button>

                                  <Transition
                                    show={open}
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                      {[1, 2, 5, 10, 25, 50, 100, 1000, 10000].map((redeems) => (
                                        <Listbox.Option
                                          key={redeems}
                                          className={({ active }) =>
                                            classNames(
                                              active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                                              'relative cursor-default select-none py-2 pl-3 pr-9'
                                          )
                                          }
                                          value={redeems}
                                        >
                                          {({ selected, active }) => (
                                            <>
                                              <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                {redeems}
                                              </span>

                                              {selected ? (
                                                <span
                                                  className={classNames(
                                                    active ? 'text-white' : 'text-indigo-600',
                                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                                  )}
                                                >
                                                  <HiCheck className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                              ) : null}
                                            </>
                                          )}
                                        </Listbox.Option>
                                      ))}
                                    </Listbox.Options>
                                  </Transition>
                                </div>
                              </>
                            )}
                          </Listbox>
                        </div>
                        <div>
                          <Listbox value={role} onChange={(selectedRole) => setRole(selectedRole)}>
                            {({ open }) => (
                              <>
                                <div className="relative flex-1">
                                  <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                    <span className="block truncate">{role}</span>
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                      <HiChevronUpDown className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </span>
                                  </Listbox.Button>

                                  <Transition
                                    show={open}
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                      {rolesList.map((role) => (
                                        <Listbox.Option
                                          key={role}
                                          className={({ active }) =>
                                            classNames(
                                              active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                                              'relative cursor-default select-none py-2 pl-3 pr-9'
                                          )
                                          }
                                          value={role}
                                        >
                                          {({ selected, active }) => (
                                            <>
                                              <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                {role}
                                              </span>

                                              {selected ? (
                                                <span
                                                  className={classNames(
                                                    active ? 'text-white' : 'text-indigo-600',
                                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                                  )}
                                                >
                                                  <HiCheck className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                              ) : null}
                                            </>
                                          )}
                                        </Listbox.Option>
                                      ))}
                                    </Listbox.Options>
                                  </Transition>
                                </div>
                              </>
                            )}
                          </Listbox>
                        </div>
                        <div className="col-span-2">
                          <input
                            type="password"
                            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder={t('Password')}
                          />
                        </div>
                        <button
                          type="button"
                          className="flex-1 mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                          onClick={() => onClose()}
                        >
                          {t('Cancel')}
                        </button>
                        <button
                          type="button"
                          className="flex-1 inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                          onClick={() => createInvite()}
                        >
                          {t('Create invite')}
                        </button>
                      </div>
                    </>
                  }
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
