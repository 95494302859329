import { createSlice } from '@reduxjs/toolkit'

export interface AuthorizationState {
  dialogOpen: boolean,
}

const initialState: AuthorizationState = {
  dialogOpen: false,
}

export const authorizationSlice = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    showDialog: (state) => {
      state.dialogOpen = true
    },
    hideDialog: (state) => {
      state.dialogOpen = false
    },
  },
})

export const { showDialog, hideDialog } = authorizationSlice.actions

export default authorizationSlice.reducer
