const backendUrl = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:3002'
//const backendUrl = 'https://dev.share2flow.com'
const cmsUrl = 'https://backend.s2f-cms.manticoredev.com'

const config = {
  backendUrl,
  apiUrl: backendUrl + '/api',
  cmsUrl,
  maxHeaderLength: 40,
  maxKpiPrefixLength: 15,
}

export default config
