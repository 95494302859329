import { TFunction } from "i18next";

export function getStatuses(t: TFunction): { [key: string]: string } {
  return {
    'Not Started': t('Not Started'),
    'In Progress': t('In Progress'),
    'Under Review': t('Under Review'),
    'Completed': t('Completed'),
    'Waiting': t('Waiting'),
    'Rejected': t('Rejected'),
  }
}
