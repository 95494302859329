import { Fragment, useEffect, useMemo, useState } from 'react'
import { Dialog, Listbox, Menu, Transition } from '@headlessui/react'
import 'react-calendar/dist/Calendar.css';
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { hideDialog } from './authorizationSlice'
import config from '../../config';
import { NotificationMessageType, showNotificationMessage } from '../notificationMessages/notificationMessagesSlice';
import { HiCheck, HiChevronUpDown } from 'react-icons/hi2';
import { HiX } from 'react-icons/hi';
import { UserBoardRole, rolesList } from '.';
import { BoardMember } from 'share2flow-typedefs';
import { listBoardMembers } from './api';
import { Avatar } from '../../components/controls/Avatar';
import { useTranslation } from 'react-i18next';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export interface EditRolesDialogProps {
  boardId: string,
}

export function EditRolesDialog({ boardId }: EditRolesDialogProps) {
  const { t } = useTranslation()
  const dialogOpen = useSelector((state: RootState) => state.authorization.dialogOpen)
  const userId = useSelector((state: RootState) => state.authentication.user?.id)
  const token = useSelector((state: RootState) => state.authentication.token)
  const dispatch = useDispatch<AppDispatch>()

  const [users, setUsers] = useState<BoardMember[]>()

  const myRole = useMemo(() => {
    if (!users || !userId) return undefined

    const me = users.find((user) => user.userId === userId)
    if (!me) return undefined

    return me.role
  }, [users, userId])

  const updateRoles = async () => {
    try {
      const res = await listBoardMembers(token!, boardId)
      setUsers(res.members)
    } catch {}
  }

  useEffect(() => {
    if (dialogOpen) {
      updateRoles()
    } else {
      setUsers(undefined)
    }
  }, [dialogOpen])

  const onClose = () => {
    dispatch(hideDialog())
  }

  const setUserRole = async (userId: string, role: string) => {
    const res = await fetch(`${config.apiUrl}/authorization/${boardId}/members/${userId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ role }),
    })
    if (res.status === 200) {
      await updateRoles()
    } else {
      dispatch(showNotificationMessage({
        type: NotificationMessageType.Error,
        title: t('Error Changing Role'),
        body: await res.text(),
      }))
    }
  }

  const removeUser = async (userId: string) => {
    const res = await fetch(`${config.apiUrl}/authorization/${boardId}/members/${userId}`, {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    })
    if (res.status === 200) {
      await updateRoles()
    } else {
      dispatch(showNotificationMessage({
        type: NotificationMessageType.Error,
        title: t('Error Removing User'),
        body: await res.text(),
      }))
    }
  }

  const roleDisplay = (userId: string, userRole: string) => {
    if (myRole === UserBoardRole.OWNER && userRole !== UserBoardRole.OWNER) {
      return (
        <Listbox value={userRole} onChange={(selectedRole) => setUserRole(userId, selectedRole)}>
          {({ open }) => (
            <>
              <div className="relative flex-1">
                <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                  <span className="block truncate">{userRole}</span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <HiChevronUpDown className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {rolesList.map((role) => (
                      <Listbox.Option
                        key={role}
                        className={({ active }) =>
                          classNames(
                            active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9'
                          )
                        }
                        value={role}
                      >
                        {({ selected, active }) => (
                          <>
                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                              {role}
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-indigo-600',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                              >
                                <HiCheck className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      )
    } else {
      return (
        <p className="my-2 flex-1 text-sm leading-6 text-gray-900">{userRole}</p>
      )
    }
  }

  const deleteUserDropdown = (userId: string) => {
    return (
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
            <span className="sr-only">{t('Open options')}</span>
            <HiX className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <a
                    onClick={() => removeUser(userId)}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'cursor-pointer block px-4 py-2 text-sm'
                    )}
                  >
                    {t('Remove user')}
                  </a>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    )
  }

  return (
    <Transition.Root show={dialogOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[1000]" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg border-2 border-purple-700">
                <div className="m-3 sm:m-5">
                  <Dialog.Title as="h3" className="text-xl font-semibold leading-6 text-gray-900">
                    {t('Edit Users and Roles')}
                  </Dialog.Title>
                  <ul role="list" className="mt-4 divide-y divide-gray-100">
                    {users?.map((user) => (
                      <li key={user.userId} className="flex items-center justify-between gap-x-2 py-2">
                        <div className="flex min-w-0 gap-x-4 flex-1 items-center">
                          <Avatar avatarUrl={user.avatarUrl} />
                          <p className="text-sm font-semibold leading-6 text-gray-900">{user.name}</p>
                        </div>
                        <>{roleDisplay(user.userId, user.role)}</>
                        <>{myRole === UserBoardRole.OWNER && user.role !== UserBoardRole.OWNER && deleteUserDropdown(user.userId)}</>
                      </li>
                    ))}
                  </ul>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
