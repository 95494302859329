import { Textfit } from 'react-textfit';

interface CellTextProps {
  className?: string,
  centered?: boolean,
}

export function CellText({ children, className, centered }: React.PropsWithChildren<CellTextProps>) {
  if (centered) {
    return (
      <div className={`p-4 whitespace-pre-wrap h-full w-full overflow-hidden ${className || ''} flex items-center justify-center text-center`}>
        {children}
      </div>
    )
  }

  return (
    <div className={`p-4 whitespace-pre-wrap h-full w-full overflow-hidden ${className || ''}`}>
      <Textfit max={16} className="h-full w-full">
        {children}
      </Textfit>
    </div>
  )
}
