import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { signInUser } from "./authenticationSlice"
import { AppDispatch, RootState } from "../../store"
import { NotificationMessages } from "../../components/impure/NotificationMessages"
import { useTranslation } from "react-i18next"
import { ContinueWithGoogle } from "../../components/controls/ContinueWithGoogle"
import { LanguageDetector } from "../../components/controls/LanguageDetector"

export function Login() {
  const { t } = useTranslation()
  const signInState = useSelector((state: RootState) => state.authentication.signInState)
  const token = useSelector((state: RootState) => state.authentication.token)
  const dispatch = useDispatch<AppDispatch>()

  const navigate = useNavigate()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [otp, setOtp] = useState('')
  const [staySignedIn, setStaySignedIn] = useState(false)

  const submit = (e: any) => {
    if (e) e.preventDefault()
    dispatch(signInUser({ email, password, otp: signInState.type.startsWith('otp') ? otp : null, staySignedIn }))
    return false
  }

  useEffect(() => {
    if (token) {
      navigate('/')
    }
  }, [token])

  return (
    <div className="bootstrap">
      <LanguageDetector />
      <main className="login_main">
        <div className="flex-grow-1 login_bannerImg" style={{ backgroundImage: "url('/img/login/login.png')" }}>
        </div>
        <div className="login_form flex flex-col items-center gap-4">
          <form action="#" autoComplete="off" onSubmit={submit}>
            <div className="text-center flex justify-center">
              <img className="form_logo" src="/img/login/share2flow.png" alt="" />
            </div>
            <h6 className="fs_xl mb-3 c_dark">{t('Logindaten')}</h6>
            <div className="mb-2 mb-xxl-3">
              <div className="d-flex mb-3 align-items-center">
                <div className="pe-2">
                  <img className="form_inp_icon fs_lg" src="/img/login/email_icon.png" alt="" />
                </div>
                <div className="position-relative flex-grow-1">
                  <input className="form-control form_inp fs_lg" type="email" placeholder={t('E-Mailadresse eintragen')} value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
              </div>
              <div className="d-flex mb-3 align-items-center">
                <div className="pe-2">
                  <img className="form_inp_icon fs_lg" src="/img/login/lock_icon.png" alt="" />
                </div>
                <div className="position-relative flex-grow-1 pwd_tglBox">
                  <input className="form-control form_inp fs_lg" type="password" placeholder={t('Passwort eintragen')} value={password} onChange={(e) => setPassword(e.target.value)}/>
                  <button className="btn pwd_tglBtn" type="button">
                    <i className="fi fi-sr-eye"></i>
                    <i className="fi fi-sr-eye-crossed"></i>
                  </button>
                </div>
              </div>
              {signInState.type.startsWith('otp') &&
              <>
                <p className="mb-2">{t('You have 2FA enabled. Please enter the One Time Password')}</p>
                <div className="d-flex mb-3 align-items-center">
                  <div className="pe-2">
                    <img className="form_inp_icon fs_lg" src="/img/login/lock_icon.png" alt="" />
                  </div>
                  <div className="position-relative flex-grow-1 pwd_tglBox">
                    <input className="form-control form_inp fs_lg" type="text" placeholder={t('OTP')} value={otp} onChange={(e) => setOtp(e.target.value)} />
                  </div>
                </div>
              </>
              }
              <label className="form-check form-switch fs_lg fw-medium c_dark">
                <input className="form-check-input" type="checkbox" role="switch" checked={staySignedIn} onChange={(e) => setStaySignedIn(e.target.checked)} />
                <div className="form-check-label">{t('eingeloggt bleiben')}</div>
              </label>
            </div>
            <div className="mb-2 min-h-[1rem]">
              {signInState.type === 'failure' && <p className="text-red-500">{signInState.error}</p>}
              {signInState.type === 'otp-failure' && <p className="text-red-500">{t('Invalid One Time Password')}</p>}
            </div>
            <div className="text-center">
              <button className="btn btn_secondary border_3 fs_lg fw_medium shadow_def py-2 px-3 mb-3" type="submit">{t('anmelden')}</button>
              <div className="mb-2 mb-xxl-3">
                <Link className="fs_lg fw-medium c_primary_2 hover_underline" to="/auth/recover-password">{t('Passwort vergessen')}</Link>
              </div>
              <div>
                <div className="fs_lg fw-medium">
                  {t('Noch kein Benutzerkonto?')} <Link className="c_primary_2 hover_underline" to="/auth/signup">{t('registrieren')}</Link>
                </div>
              </div>

            </div>
          </form>
          <ContinueWithGoogle />
        </div>
        <NotificationMessages />
      </main>
    </div>
  )
}
