import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useLockCell } from "../../features/locking";
import { CellColorBox } from "../cells/CellColorBox";
import { CellIcon } from "../cells/CellIcon";
import { CellText } from "../cells/CellText";
import { InputDialog, InputSpec } from "../controls/InputDialog";
import { CellContentsProps } from "./CellContents";
import { AppDispatch } from "../../store";
import { useDispatch } from "react-redux";
import { setCell, setColumn, useCell, useColumn } from "../../rootState";
import { useTranslation } from "react-i18next";
import config from "../../config";

export function SupportLevelCell({ coords }: CellContentsProps) {
  const { t } = useTranslation()
  const data = useCell(coords)
  const column = useColumn(coords) || []
  const levelCell = useCell({...coords, column: 0})
  const [isEditing, setIsEditing] = useState(false)
  const { lock, unlock } = useLockCell(coords)
  const dispatch = useDispatch<AppDispatch>()

  const onClick = async () => {
    if (coords.column === 0) {
      const lockAcquired = await lock()
      if (lockAcquired) {
        setIsEditing(true)
      }
      return
    }

    const newColumn = column.map((_cell, idx) => {
      if (idx === coords.row) {
        return { selected: !!!data?.selected }
      }
      return {}
    });
    dispatch(setColumn(coords, newColumn))
  }

  const renderEditor = () => {
    const inputSpecs: InputSpec[] = [
      {
        id: 'name',
        name: t('Level designation'),
        type: 'text',
        initialValue: data?.name || '',
        maxLength: config.maxHeaderLength,
      },
      {
        id: 'color',
        name: t('Color assignment'),
        type: 'color',
        initialValue: data?.color || '#ffffff',
      },
    ]

    const onClose = async (accepted: boolean, values?: {[id: string]: string | number | boolean}) => {
      if (accepted) {
        dispatch(setCell(coords, {...data, ...values}))
      }
      setIsEditing(false)
      await unlock()
    }

    return <InputDialog title={t('Definition | Need for support')} inputSpecs={inputSpecs} open={isEditing} onClose={onClose} />
  }

  const renderContents = () => {
    if (coords.column === 0) {
      return (
        <div className="w-full h-full flex items-center justify-center cursor-pointer" onClick={() => onClick()}>
          <CellText centered={true}>{data?.name}</CellText>
        </div>
      )
    }

    return (
      <div className="cursor-pointer w-full h-full flex items-center justify-center" onClick={() => onClick()}>
        {(() => {
          if (data?.selected === true) {
            if (!levelCell) return <CellColorBox color="#ffffff" />
              return <CellColorBox color={levelCell.color} />
          }

          const columnHasSelection = column.find((cell) => cell?.selected === true)

          if (columnHasSelection) {
            return <></>
          }

          return (
            <CellIcon icon={AiOutlinePlus} />
          )
        })()}
      </div>
    )
  }

  return (
    <>
      {renderContents()}
      {renderEditor()}
    </>
  )
}
