import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'
import { Action, BoardInfo, BoardState as BoardState2, Cell, CellCoords, newBoardState, Reference, SectionIdentifier, dispatchEvent, ColumnGroup } from "share2flow-board"
import { sendMessage } from './socketState'
import { RootState } from './store'
import { useSelector } from 'react-redux'
import { BoardMember } from 'share2flow-typedefs'

export interface BoardState {
  board?: BoardState2,
  members?: BoardMember[],
}

const initialState: BoardState = {
  board: undefined,
  members: undefined,
}

interface ActionPayload {
  action: Action,
  args: any,
}

export const boardStateSlice = createSlice({
  name: 'boardState',
  initialState,
  reducers: {
    applyAction(state, action: PayloadAction<ActionPayload>) {
      if (state.board) {
        dispatchEvent(state.board, action.payload.action, action.payload.args)
      }
    },
    init(state, action: PayloadAction<any>) {
      state.board = newBoardState(action.payload)
    },
    clearBoard(state) {
      state.board = undefined
      state.members = undefined
    },
    setMembers(state, action: PayloadAction<BoardMember[]>) {
      state.members = action.payload
    }
  },
})

// Getters
export const useCell = (coords: CellCoords): Cell | undefined => 
  useSelector((state: RootState) => state.boardState.board?.sections[coords.section]?.[coords.row]?.[coords.column])
export const useRow = (coords: CellCoords): Cell[] | undefined =>
  useSelector((state: RootState) => state.boardState.board?.sections[coords.section]?.[coords.row])
export const useColumn = (coords: CellCoords): Cell[] | undefined =>
  useSelector((state: RootState) => state.boardState.board?.sections[coords.section])?.map((row) => row[coords.column])
export const useMaxColumnCount = (): number =>
  useSelector((state: RootState) => {
    if (!state.boardState.board) return 0
    return Object.values(state.boardState.board.sections).reduce(
      (acc, section) => Math.max(acc, section.reduce((rowAcc, row) => Math.max(rowAcc, row.length), 0), 0), 0
    ) || 0
  })
 {
}
export const findCellCoordsById = (board: BoardState2 | undefined, id: string | undefined): CellCoords | undefined => {
    if (!board || !id) return undefined
    for (let [section, rows] of Object.entries(board.sections)) {
      for (let rowIdx = 0; rowIdx < rows.length; rowIdx++) {
        for (let colIdx = 0; colIdx < rows[rowIdx].length; colIdx++) {
          if (rows[rowIdx][colIdx] && 'id' in rows[rowIdx][colIdx] && rows[rowIdx][colIdx].id === id) {
            return {section: section as SectionIdentifier, row: rowIdx, column: colIdx}
          }
        }
      }
    }
    return undefined
}
export const useCellCoordsById = (id: string | undefined): CellCoords | undefined =>
  findCellCoordsById(useSelector((state: RootState) => state.boardState.board), id)

// Cell manipulation
export const setCell = (coords: CellCoords, cell: Cell) => {
  if (!cell.id) cell.id = uuidv4()
  return sendMessage({event: 'action', data: {action: Action.setCell, args: {coords, cell}}})
}
export const deleteCell = (coords: CellCoords) => {
  return sendMessage({event: 'action', data: {action: Action.deleteCell, args: {coords}}})
}

  // Row manipulation
export const setRow = (coords: CellCoords, cells: Cell[]) => {
  return sendMessage({event: 'action', data: {action: Action.setRow, args: {coords, cells}}})
}
export const insertRow = (coords: CellCoords) => {
  return sendMessage({event: 'action', data: {action: Action.insertRow, args: {coords}}})
}
export const deleteRow = (coords: CellCoords) => {
  return sendMessage({event: 'action', data: {action: Action.deleteRow, args: {coords}}})
}

// Column manipulation
export const setColumn = (coords: CellCoords, cells: Cell[]) => {
  return sendMessage({event: 'action', data: {action: Action.setColumn, args: {coords, cells}}})
}
export const insertColumn = (coords: CellCoords) => {
  return sendMessage({event: 'action', data: {action: Action.insertColumn, args: {coords}}})
}
export const deleteColumn = (coords: CellCoords) => {
  return sendMessage({event: 'action', data: {action: Action.deleteColumn, args: {coords}}})
}

// References
// Getters
export const useReferencesForSource = (id: string, tag?: string): Reference[] | undefined =>
  useSelector((state: RootState) => state.boardState.board?.references)?.filter((reference) => reference.sourceId === id && (!tag || reference.sourceTag === tag))
export const useReferencesForTarget = (id: string, tag?: string): Reference[] | undefined =>
  useSelector((state: RootState) => state.boardState.board?.references)?.filter((reference) => reference.targetId === id && (!tag || reference.targetTag === tag))
// Manipulation
export const addReference = (reference: Reference) => {
  return sendMessage({event: 'action', data: {action: Action.addReference, args: {reference}}})
}
export const setReferenceBySource = (reference: Reference) => {
  return sendMessage({event: 'action', data: {action: Action.setReferenceBySource, args: {reference}}})
}
export const clearReferenceBySource = (sourceId: string, sourceTag: string) => {
  return sendMessage({event: 'action', data: {action: Action.clearReferenceBySource, args: {sourceId, sourceTag}}})
}
export const replaceReference = (oldReference: Reference, newReference: Reference) => {
  return sendMessage({event: 'action', data: {action: Action.replaceReference, args: {oldReference, newReference}}})
}
export const deleteReference = (reference: Reference) => {
  return sendMessage({event: 'action', data: {action: Action.deleteReference, args: {reference}}})
}
export const deleteReferencesFor = (id: string) => {
  return sendMessage({event: 'action', data: {action: Action.deleteReferencesFor, args: {id}}})
}

// Column Groups
// Getters
export const useColumnGroupById = (id?: string): ColumnGroup | undefined => {
  const groups = useSelector((state: RootState) => state.boardState.board?.columnGroups)?.filter((group) => group.id === id)
  if (groups && groups.length > 0) return groups[0]
}
// Manipulation
export const createColumnGroup = (group: ColumnGroup) => {
  return sendMessage({event: 'action', data: {action: Action.createColumnGroup, args: {group}}})
}
export const updateColumnGroup = (group: ColumnGroup) => {
  return sendMessage({event: 'action', data: {action: Action.updateColumnGroup, args: {group}}})
}
export const deleteColumnGroup = (groupId: string) => {
  return sendMessage({event: 'action', data: {action: Action.deleteColumnGroup, args: {groupId}}})
}

// Statuses
export const setStatuses = (statuses: [string, string][]) => {
  return sendMessage({event: 'action', data: {action: Action.setStatuses, args: {statuses}}})
}

// Countdown
export const setCountdownEnd = (time: number | undefined) => {
  return sendMessage({event: 'action', data: {action: Action.setCountdownEnd, args: {time}}})
}

// Board info
export const setBoardInfo = (boardInfo: BoardInfo) => {
  return sendMessage({event: 'action', data: {action: Action.setBoardInfo, args: {boardInfo}}})
}

// Undo/Redo
export const undo = () => {
  return sendMessage({event: 'action', data: {action: Action.undo, args: {}}})
}
export const redo = () => {
  return sendMessage({event: 'action', data: {action: Action.redo, args: {}}})
}

// Locks
export const lockCell = (coords: CellCoords) => {
  return sendMessage({event: 'lock', data: coords})
  // TODO callbacks for lock functions
  //if (!socketState.socket) throw new Error("Socket not connected")

  //return new Promise<string | null>((resolve, reject) => {
    //socketState.socket?.timeout(5000).emit('lock', coords, (err: any, response: any) => {
      //if (err) {
        //reject(err)
      //} else {
        //resolve(response)
      //}
    //})
  //})
}
export const unlockCell = (coords: CellCoords) => {
  return sendMessage({event: 'unlock', data: coords})
}
export const lockRow = (coords: CellCoords) => {
  return sendMessage({event: 'lockRow', data: coords})
}
export const unlockRow = (coords: CellCoords) => {
  return sendMessage({event: 'unlockRow', data: coords})
}

export const { applyAction, init, clearBoard, setMembers } = boardStateSlice.actions

export default boardStateSlice.reducer
