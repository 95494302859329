import { useTranslation } from "react-i18next"
import { PlanSummary } from "./PlanSummary"
import { create, Dropin } from 'braintree-web-drop-in'
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { NotificationMessageType, showNotificationMessage } from '../notificationMessages/notificationMessagesSlice'
import { addPaymentMethod, createCustomer, getClientToken, getCustomer, getSubscription, removePaymentMethod, setBillingAddress } from './api'
import { BillingAddress, Subscription as SubscriptionType } from "share2flow-typedefs"
import { SubmitHandler, useForm } from "react-hook-form"
import countries from './countries.json'

export function Subscription() {
  const { t } = useTranslation()
  const token = useSelector((state: RootState) => state.authentication.token)
  const dispatch = useDispatch<AppDispatch>()

  const [clientToken, setClientToken] = useState(undefined)
  const [customer, setCustomer] = useState<any>()
  const [subscription, setSubscription] = useState<SubscriptionType>()
  const [dropinInstance, setDropinInstance] = useState<Dropin>()

  const { register, handleSubmit, reset } = useForm<BillingAddress>()

  const hasAddress = customer?.addresses.length > 0
  const hasPaymentMethod = customer?.paymentMethods.length > 0

  const updateClientToken = async (token: string) => {
    try {
      const result = await getClientToken(token)
      setClientToken(result.clientToken)
    } catch(e: any) {
      setClientToken(undefined)
      dispatch(showNotificationMessage({
        type: NotificationMessageType.Error,
        title: t('Error Sending Code'),
        body: e.toString(),
      }))
    }
  }

  const updateCustomer = async (token: string) => {
    try {
      const result = await getCustomer(token)
      setCustomer(result.customer)
      if (result.customer.addresses.length === 0) return
      const address = result.customer.addresses[0]
      reset({
        firstName: address.firstName,
        lastName: address.lastName,
        streetAddress: address.streetAddress,
        postalCode: address.postalCode,
        locality: address.locality,
        region: address.region,
        countryName: address.countryName,
        company: address.company,
      })
      return result.customer
    } catch(e: any) {
      setCustomer(undefined)
      console.error(e)
    }
  }

  const updateSubscription = async (token: string) => {
    try {
      const result = await getSubscription(token)
      setSubscription(result.subscription)
    } catch(e: any) {
      setSubscription(undefined)
      console.error(e)
    }
  }

  const onSaveBillingInformation: SubmitHandler<BillingAddress> = async (data) => {
    try {
      await setBillingAddress(token!, data)
      updateCustomer(token!)
      dispatch(showNotificationMessage({
        type: NotificationMessageType.Success,
        title: t('Billing information saved!'),
      }))
    } catch(e: any) {
      console.error(e)
    }
  }

  const onSavePaymentMethod: SubmitHandler<BillingAddress> = async (data) => {
    if (!dropinInstance) return

    try {
      const payload = await dropinInstance.requestPaymentMethod()

      if (!customer) {
        const customer = await createCustomer(token!, {
          billingAddress: data,
          paymentMethodNonce: payload.nonce,
        })
        setCustomer(customer.customer)
      } else {
        await setBillingAddress(token!, data)
        await addPaymentMethod(token!, payload.nonce)
        updateCustomer(token!)
      }
      dispatch(showNotificationMessage({
        type: NotificationMessageType.Success,
        title: t('Payment method added!'),
      }))
    } catch(e: any) {
      console.error(e)
    }
  }

  const onRemovePaymentMethod = async () => {
    try {
      await removePaymentMethod(token!)
      updateCustomer(token!)
      updateClientToken(token!)
      dispatch(showNotificationMessage({
        type: NotificationMessageType.Success,
        title: t('Payment method removed!'),
      }))
    } catch(e: any) {
      console.error(e)
    }
  }

  const onPlanChange = () => {
    updateSubscription(token!)
  }

  useEffect(() => {
    if (!token) return

    const updateAll = async () => {
      const customer = await updateCustomer(token)
      if (!customer?.paymentMethods.length) {
        await updateClientToken(token)
      }
      await updateSubscription(token)
    }
    updateAll()
  }, [token, hasPaymentMethod])

  useEffect(() => {
    if (!clientToken) return

    create({
      authorization: clientToken,
      container: '#dropin-container'
    }, function (err, dropinInstance) {
      if (err) {
        setDropinInstance(undefined)
        console.error(err);
        return;
      }
      setDropinInstance(dropinInstance)
    });
  }, [clientToken])

  return (
    <div className="bootstrap">
      <main className="main-section">
        <div className="container">
          <div className="inner_container">
            <div className="row gy-4 gx-xl-5 mb-5">
              <div className="col-md-6 col-lg-4">
                <div>
                  <h6 className="fs_lg fw_semiBold mb-4">
                    {t('Subscription details')}
                  </h6>
                  <PlanSummary onPlanChange={onPlanChange} subscription={subscription} hasPaymentMethod={hasPaymentMethod} />
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div>
                  <h6 className="fs_lg fw_semiBold mb-4">
                    {t('Billing Information')}
                  </h6>
                  <div className="mb-4">
                    <input className="form-control fs_lg fw_light" type="text" placeholder={t('First name')} {...register("firstName")} />
                  </div>
                  <div className="mb-4">
                    <input className="form-control fs_lg fw_light" type="text" placeholder={t('Last name')} {...register("lastName")} />
                  </div>
                  <div className="mb-4">
                    <input className="form-control fs_lg fw_light" type="text" placeholder={t('Company')} {...register("company")} />
                  </div>
                  <div className="mb-4">
                    <select className="form-control fs_lg fw_light" {...register("countryName")}>
                      <option value="">{t('Country')}</option>
                      {countries.map(c => (
                        <option key={c} value={c}>{c}</option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-4">
                    <input className="form-control fs_lg fw_light" type="text" placeholder={t('State or province')} {...register("region")} />
                  </div>
                  <div className="mb-4">
                    <input className="form-control fs_lg fw_light" type="text" placeholder={t('Postal code')} {...register("postalCode")} />
                  </div>
                  <div className="mb-4">
                    <input className="form-control fs_lg fw_light" type="text" placeholder={t('City')} {...register("locality")} />
                  </div>
                  <div className="mb-4">
                    <input className="form-control fs_lg fw_light" type="text" placeholder={t('Address')} {...register("streetAddress")} />
                  </div>
                  {hasAddress && (
                    <button className="btn btn_def btn_primary w-100" onClick={handleSubmit(onSaveBillingInformation)}>
                      {t('Save billing information')}
                    </button>
                  )}
                  {/*
                  TODO add custom fields
                  <div className="">
                    <input className="form-control fs_lg fw_light" type="text" placeholder="VAT Tax ID" />
                  </div>
                  */}
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div>
                  <h6 className="fs_lg fw_semiBold">
                    {t('Payment method')}
                  </h6>
                  {hasPaymentMethod ? (
                    <div className="mt-4">
                      {customer?.paymentMethods[0].cardType} {t('ending in')} {customer?.paymentMethods[0].last4}
                      <button className="btn btn_def btn_primary w-100 mt-4" onClick={onRemovePaymentMethod}>
                        {t('Remove payment method')}
                      </button>
                    </div>
                  ) : (
                    <div>
                      <div id="dropin-container"></div>
                      <button className="btn btn_def btn_primary w-100" onClick={handleSubmit(onSavePaymentMethod)}>
                        {t('Add payment method')}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}
