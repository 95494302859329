import { Reference } from "share2flow-board";
import { PopupButton } from "../controls/PopupButton";
import { PopupMenu } from "../controls/PopupMenu";
import { deleteReference } from "../../rootState";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";

export interface DocumentFlowArrowControlsProps {
  clickedArrow: Reference | undefined,
  clearClickedArrow: () => void,
}

export function DocumentFlowArrowControls({clickedArrow, clearClickedArrow}: DocumentFlowArrowControlsProps) {
  const dispatch = useDispatch<AppDispatch>()

  const deleteClickedArrow = () => {
    if (!clickedArrow) {
      return
    }
    dispatch(deleteReference(clickedArrow))
    clearClickedArrow()
  }

  return (
    <PopupMenu isVisible={!!clickedArrow} onDismiss={() => clearClickedArrow()} width={200}>
      <PopupButton
        key="delete"
        iconName="TRASH"
        text="Delete"
        onClick={() => deleteClickedArrow()}
      />
    </PopupMenu>
  )
}

export function renderDocumentFlowArrowControls(clickedArrow: Reference | undefined, clearClickedArrow: () => void) {
  return (
    <DocumentFlowArrowControls
      clickedArrow={clickedArrow}
      clearClickedArrow={clearClickedArrow}
    />
  )
}
