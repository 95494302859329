import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Plan, User } from "share2flow-typedefs"
import { Avatar } from "../../components/controls/Avatar"
import { Dialog } from "../../components/controls/Dialog"
import { LoadingState } from "../../components/controls/LoadingState"
import { RootState } from "../../store"
import { formatDateLong } from "../../utils"
import { NotificationMessageType, showNotificationMessage } from "../notificationMessages/notificationMessagesSlice"
import { getSubscription, listUsers, setUserActive } from "./api"

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}

export function ManagementUsers() {
  const { t, i18n } = useTranslation()
  const token = useSelector((state: RootState) => state.authentication.token)

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [users, setUsers] = useState<any[]>([])
  const [selectedUser, setSelectedUser] = useState<any>(null)
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [subscription, setSubscription] = useState<any>(null)

  const fetchUsers = async () => {
    try {
      const users = await listUsers(token!)
      setUsers(users)
      if (selectedUser) {
        const user = users.find((u) => u.id === selectedUser.id)
        if (user) {
          setSelectedUser(user)
          fetchSubscription(user.subscriptionId)
        }
      }
    } catch (e: any) {
      showNotificationMessage({
        type: NotificationMessageType.Error,
        title: t('Error fetching users'),
        body: e.toString(),
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  const fetchSubscription = async (id: string) => {
    setSubscription(null)
    try {
      const subscription = await getSubscription(token!, id)
      setSubscription(subscription)
    } catch (e: any) {
      showNotificationMessage({
        type: NotificationMessageType.Error,
        title: t('Error fetching subscription'),
        body: e.toString(),
      })
    }
  }

  const activateUser = async (id: string, active: boolean) => {
    await setUserActive(token!, id, active)
    await fetchUsers()
  }

  const selectUser = (user: any) => {
    setSelectedUser(user)
    fetchSubscription(user.subscriptionId)
    setDialogOpen(true)
  }

  return (
    <main className="flex justify-center">
      <div className="w-full max-w-7xl">
        <LoadingState isLoading={isLoading}>
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle">
                  <table className="min-w-full border-separate border-spacing-0">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                        >
                          {t('Name')}
                        </th>
                        <th
                          scope="col"
                          className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                        >
                          {t('Email')}
                        </th>
                        <th
                          scope="col"
                          className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                        >
                          {t('Signed up')}
                        </th>
                        <th
                          scope="col"
                          className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                        >
                          {t('Role')}
                        </th>
                        <th
                          scope="col"
                          className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                        >
                          {t('Active')}
                        </th>
                        <th
                          scope="col"
                          className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                        >
                          <span className="sr-only">{t('View')}</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((user, userIdx) => (
                        <tr key={user.email}>
                          <td
                            className={classNames(
                              userIdx !== users.length - 1 ? 'border-b border-gray-200' : '',
                              'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8',
                            )}
                          >
                            <div className="flex items-center">
                              <Avatar avatarUrl={user.avatarUrl} size={24} />
                              <span className="ml-2">{user.name}</span>
                            </div>
                          </td>
                          <td
                            className={classNames(
                              userIdx !== users.length - 1 ? 'border-b border-gray-200' : '',
                              'hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell',
                            )}
                          >
                            {user.email}
                          </td>
                          <td
                            className={classNames(
                              userIdx !== users.length - 1 ? 'border-b border-gray-200' : '',
                              'hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell',
                            )}
                          >
                            {formatDateLong(i18n.language, new Date(user.insertedAt))}
                          </td>
                          <td
                            className={classNames(
                              userIdx !== users.length - 1 ? 'border-b border-gray-200' : '',
                              'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
                            )}
                          >
                            {user.isAdmin ? 'Admin' : 'User'}
                          </td>
                          <td
                            className={classNames(
                              userIdx !== users.length - 1 ? 'border-b border-gray-200' : '',
                              'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
                            )}
                          >
                            {user.isActive ? t('Yes') : t('No')}
                          </td>
                          <td
                            className={classNames(
                              userIdx !== users.length - 1 ? 'border-b border-gray-200' : '',
                              'relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-8 lg:pr-8',
                            )}
                          >
                            <a className="text-indigo-600 hover:text-indigo-900 cursor-pointer" onClick={() => selectUser(user)}>
                              {t('View')}<span className="sr-only">, {user.name}</span>
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </LoadingState>
      </div>

      <Dialog
        title={t('User details')}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        controls={[{ id: 'toggleActivated', text: selectedUser?.isActive ? 'Deactivate' : 'Activate', onClick: () => activateUser(selectedUser?.id, !selectedUser?.isActive) }]}
      >
        <div>
          <div className="mt-6">
            <dl className="divide-y divide-gray-100">
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">{t('Name')}</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  <div className="flex items-center">
                    <Avatar avatarUrl={selectedUser?.avatarUrl} size={24} />
                    <span className="ml-2">{selectedUser?.name}</span>
                  </div>
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">{t('Email address')}</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{selectedUser?.email}</dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">{t('Active')}</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{selectedUser?.isActive ? "Yes" : "No"}</dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">{t('Signed up')}</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{selectedUser?.insertedAt && formatDateLong(i18n.language, new Date(selectedUser?.insertedAt || ''))}</dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">{t('Last login')}</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{selectedUser?.lastLoggedInAt && formatDateLong(i18n.language, new Date(selectedUser?.lastLoggedInAt))}</dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">{t('Subscription plan')}</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{subscription?.planId}</dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">2FA</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex gap-2">
                  {selectedUser?.appMFA &&
                    <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                      App
                    </span>
                  }
                  {selectedUser?.smsMFA &&
                    <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                      SMS
                    </span>
                  }
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </Dialog>
    </main>
  )
}
