import { useTranslation } from "react-i18next"

export function Statistics() {
  const { t } = useTranslation()

  return (
    <div className="bootstrap">
      {/* .................. start page header .................. */}
      <div className="page-header pt-3 bg_light">
        <div className="container">
          <h1 className="page-title">
            {t('Statistics')}
          </h1>
        </div>
      </div>
      {/* .................. end page header .................. */}

      {/* .................. start main section .................. */}
      <main className="main-section">
        <div className="container bg-rose-200">
          <div className="inner_container">
            Statistics go here
          </div>
        </div>
      </main>
      {/* .................. end main section .................. */}
    </div>
  )
}
