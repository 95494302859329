import { useTranslation } from "react-i18next";
import { NavLink, Outlet } from "react-router-dom";

export function Settings() {
  const { t } = useTranslation()

  return (
    <div className="bootstrap">
      {/* .................. start page header .................. */}
      <div className="page-header pt-3 bg_light">
        <div className="container">
          <h1 className="page-title">
            {t('Settings')}
          </h1>
          <ul className="page-header-nav list-unstyled ps-0 fs_lg">
            <li className="page-header-navItem">
              <NavLink className={({ isActive }) => `page-header-navLink ${isActive ? 'active' : ''}`} end to="/settings">{t('User')}</NavLink>
            </li>
            <li className="page-header-navItem">
              <NavLink className={({ isActive }) => `page-header-navLink ${isActive ? 'active' : ''}`} to="/settings/notifications">{t('Notifications')}</NavLink>
            </li>
            <li className="page-header-navItem">
              <NavLink className={({ isActive }) => `page-header-navLink ${isActive ? 'active' : ''}`} to="/settings/security">{t('Security')}</NavLink>
            </li>
          </ul>
        </div>
      </div>
      {/* .................. end page header .................. */}

      {/* .................. start main section .................. */}
      <Outlet />
      {/* .................. start main section .................. */}
    </div>
  )
}
