import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { areCellCoordsEqual, Cell, CellCoords, Row, SectionIdentifier } from 'share2flow-board'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import { PopupMenu } from '../../components/controls/PopupMenu'
import { PopupButton } from '../../components/controls/PopupButton'
import { BiEditAlt } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { hideDialog } from '../../features/actionPlan/actionPlanSlice'
import { deleteCell, setCell, setStatuses, useColumn } from '../../rootState';
import { useTranslation } from 'react-i18next';
import { getStatuses } from './statuses';

interface TableHeaderCellProps {
  text: string,
  onEdit?: () => void,
}

function TableHeaderCell({ text, onEdit }: TableHeaderCellProps) {
  return (
    <div className="bg-gray-200 text-gray-700 uppercase flex items-center justify-center text-center text-sm px-2 py-3 flex items-center">
      {text}
      {onEdit && <button className="ml-1 text-gray-700" onClick={onEdit}><BiEditAlt /></button>}
    </div>
  )
}

interface TableCellProps {
  text: string,
  className?: string,
}

function TableCell({ text, className }: TableCellProps) {
  return <div className={`text-gray-700 flex items-center justify-center text-center text-sm px-2 py-3 ${className || ''}`}>{text}</div>
}

interface StatusTableCellProps {
  coords: CellCoords,
  cell: any,
  className?: string,
  options: {[key: string]: string},
}

function StatusTableCell({ coords, cell, className, options }: StatusTableCellProps) {
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false)
  const dispatch = useDispatch<AppDispatch>()

  const setStatus = async (status: string) => {
    dispatch(setCell(coords, {...cell, status, checkmark: status === 'Completed' ? 'checked' : 'unchecked'}))
    setDropdownVisible(false)
  }

  const onClick = () => {
    setDropdownVisible(true)
  }

  let status = ''
  if (cell?.status) {
    status = options[cell.status] ?? ''
  }

  return (
    <div className={`relative flex items-center justify-center text-center text-sm px-2 py-3 ${className || ''}`}>
      {status
        ? <div className="text-green-700 bg-green-50 rounded-full px-3 py-1 cursor-pointer" onClick={onClick}>
          {status}
        </div>
        : <div className="w-full h-full cursor-pointer" onClick={onClick}></div>
      }
      <div className={`absolute top-5 inset-0 ${dropdownVisible ? '' : 'pointer-events-none'}`}>
        <PopupMenu isVisible={dropdownVisible} onDismiss={() => setDropdownVisible(false)} width={200}>
          {Object.entries(options).map(([key, value]) => (
            <PopupButton
              key={key}
              iconName="x"
              text={value}
              onClick={() => setStatus(key)}
            />
          ))}
        </PopupMenu>
      </div>
    </div>
  )
}

interface LevelTableCellProps {
  coords: CellCoords,
  cell: any,
  className?: string,
}

function LevelTableCell({ coords, cell, className }: LevelTableCellProps) {
  const { t } = useTranslation()
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false)
  const dispatch = useDispatch<AppDispatch>()

  const levelCells = useColumn({section: SectionIdentifier.SUPPORT_LEVEL, row: 0, column: 0}) || []

  const setLevel = (level: string) => {
    dispatch(setCell(coords, {...cell, level}))
    setDropdownVisible(false)
  }

  const onClick = () => {
    setDropdownVisible(true)
  }

  return (
    <div className={`relative ${className || ''}`}>
      <div className="w-full h-full flex items-center justify-center text-center text-gray-700 text-sm cursor-pointer" onClick={onClick}>
        {cell.level}
      </div>
      <div className={`absolute top-5 inset-0 ${dropdownVisible ? '' : 'pointer-events-none'}`}>
        <PopupMenu isVisible={dropdownVisible} onDismiss={() => setDropdownVisible(false)} width={400}>
          {levelCells.filter(cell => cell).map((cell, idx) => (
            <PopupButton
              key={idx}
              text={cell.name}
              onClick={() => setLevel(cell.name)}
            />
          ))}
          <PopupButton
            iconName="Trash"
            text={t('Clear')}
            onClick={() => setLevel('')}
          />
        </PopupMenu>
      </div>
    </div>
  )
}

interface EditableTableCellProps {
  coords: CellCoords,
  cell: any,
  cellKey: string,
  type?: "text" | "date",
  className?: string,
}

function EditableTableCell({ coords, cell, cellKey, type, className }: EditableTableCellProps) {
  const [isEditing, setIsEditing] = useState<boolean>()
  const [value, setValue] = useState<string>()
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const textRef = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch<AppDispatch>()

  const onEdit = () => {
    setIsEditing(true)
    setValue(cell[cellKey])
    const height = textRef.current!.offsetHeight
    setTimeout(() => {
      if (!inputRef.current) return
      inputRef.current!.style.height = height + 'px'
      inputRef.current!.focus()
    }, 0)
  }

  const onValueChange = (e: React.ChangeEvent) => {
    setValue((e.target as any).value)
    onResize()
  }

  const onDateChange = (date: Date | null) => {
    let newValue = ''
    if (date) {
      newValue = ("0" + date.getDate()).slice(-2) + "-" + ("0"+(date.getMonth()+1)).slice(-2) + "-" + date.getFullYear()
    }
    dispatch(setCell(coords, {...cell, [cellKey]: newValue}))
    setIsEditing(false)
  }

  const onResize = () => {
    if (!inputRef.current) return
    inputRef.current!.style.height = inputRef.current!.scrollHeight + 'px'
  }

  const onAccept = () => {
    dispatch(setCell(coords, {...cell, [cellKey]: value}))
    setIsEditing(false)
  }

  const parseDate = (text?: string): Date | null => {
    if (!text || text === '') return null
    const parts = text.split('-')
    const day = parseInt(parts[0])
    const month = parseInt(parts[1])
    const year = parseInt(parts[2])
    return new Date(year, month, day)
  }

  if (isEditing) {
    if (!type || type === 'text') {
      return (
        <textarea
          ref={inputRef}
          className="text-gray-700 flex items-center justify-center text-center text-sm px-2 py-3"
          value={value}
          onChange={onValueChange}
          onFocus={onResize}
          onBlur={() => onAccept()}
        />
      )
    } else if (type === 'date') {
      return (
        <div className="relative">
          <div className="absolute z-[100] top-0 -left-16 -right-16">
            <Calendar onChange={(v) => onDateChange(v as any)} value={parseDate(value)} />
          </div>
        </div>
      )
    }
  }

  return (
    <div
      ref={textRef}
      className={`text-gray-700 flex items-center justify-center text-center text-sm px-2 py-3 overflow-hidden ${className || ''}`}
      onMouseDown={onEdit}
    >
      {cell[cellKey] || ''}
    </div>
  )
}

interface CustomEditableTableCellProps {
  value: string,
  onChange: (value: string) => void,
  className?: string,
}

function CustomEditableTableCell({ value, onChange, className }: CustomEditableTableCellProps) {
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [newValue, setNewValue] = useState<string>('')

  const onEdit = () => {
    setIsEditing(true)
    setNewValue(value)
  }

  const onValueChange = (e: React.ChangeEvent) => {
    setNewValue((e.target as any).value)
  }

  const onAccept = () => {
    onChange(newValue)
    setIsEditing(false)
  }

  if (isEditing) {
    return (
      <input
        type="text"
        className="h-12 text-gray-700 flex items-center justify-center text-center text-sm px-2 py-3"
        value={newValue}
        onChange={onValueChange}
        onBlur={() => onAccept()}
      />
    )
  }

  return (
    <div
      className={`h-12 text-gray-700 flex items-center justify-center text-center text-sm px-2 py-3 overflow-hidden ${className || ''}`}
      onMouseDown={onEdit}
    >
      {value}
    </div>
  )
}

export interface PlanDialogProps {
}

export function PlanDialog({}: PlanDialogProps) {
  const { t } = useTranslation()
  const dialogOpen = useSelector((state: RootState) => state.actionPlan.dialogOpen)
  const currentCellCoords = useSelector((state: RootState) => state.actionPlan.currentCellCoords)
  const rows = useSelector((state: RootState) => state.boardState.board?.sections[SectionIdentifier.IDEAS_AND_IMPROVEMENTS])
  const activeCell = useSelector((state: RootState) => currentCellCoords && state.boardState.board?.sections[currentCellCoords.section]?.[currentCellCoords.row]?.[currentCellCoords.column])
  const dispatch = useDispatch()

  const statuses = getStatuses(t)

  const tableRow = (coords: CellCoords, cell: any, isHighlighted: boolean, index: number) => {
    let rowChar = ''
    if (coords.row >= 26) {
      rowChar += String.fromCharCode('A'.charCodeAt(0) + Math.floor(coords.row / 26))
    }
    rowChar += String.fromCharCode('A'.charCodeAt(0) + (coords.row % 26))

    const id = `I${rowChar}${coords.column}`
    const className = isHighlighted ? 'bg-purple-200' : (index % 2 ? 'bg-gray-100' : '')

    if (!cell || !(Object.keys(cell).length > 0 || isHighlighted)) {
      return <Fragment key={id}></Fragment>
    }

    return (
      <Fragment key={id}>
        <TableCell text={id} className={className} />
        <EditableTableCell coords={coords} cell={cell} cellKey="bottleNeck" className={className} />
        <EditableTableCell coords={coords} cell={cell} cellKey="text" className={className} />
        <EditableTableCell coords={coords} cell={cell} cellKey="name" className={className} />
        <EditableTableCell coords={coords} cell={cell} cellKey="endDate" type="date" className={className} />
        <StatusTableCell coords={coords} cell={cell} className={className} options={statuses} />
        <LevelTableCell coords={coords} cell={cell} className={className} />
      </Fragment>
    )
  }

  const tableRows = () => {
    if (!rows) return (
      <></>
    )

    let counter = -1
    return rows.flatMap((row: Row, rowIdx) => (
      row.map((cell: Cell, colIdx) => {
        if (colIdx === 0) {
          return undefined
        }

        const coords = {
          section: SectionIdentifier.IDEAS_AND_IMPROVEMENTS,
          row: rowIdx,
          column: colIdx,
        }
        const isHighlighted = !!currentCellCoords && areCellCoordsEqual(coords, currentCellCoords)

        if (cell && (Object.keys(cell).length > 0 || isHighlighted)) {
          counter += 1
        }

        return tableRow(coords, cell, isHighlighted, counter)
      }).filter(cell => cell)
    ))
  }

  const onClose = () => {
    if (currentCellCoords) {
      if (!activeCell || !(activeCell.text || activeCell.bottleNeck || activeCell.name || activeCell.endDate)) {
        dispatch(deleteCell(currentCellCoords))
      }
    }

    dispatch(hideDialog())
  }

  return (
    <>
      <Transition.Root show={dialogOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[1000]" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl border-2 border-purple-700">
                  <div className="grid grid-cols-plan-table">
                    <TableHeaderCell text={t('ID')} />
                    <TableHeaderCell text={t('Bottleneck')} />
                    <TableHeaderCell text={t('Improvement')} />
                    <TableHeaderCell text={`${t('Name')} (${t('User')}/${t('Guest')})`} />
                    <TableHeaderCell text={t('End Date')} />
                    <TableHeaderCell text={t('Status')} />
                    <TableHeaderCell text={t('Level')} />

                    {tableRows()}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}
