import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { LoadingState } from "../../components/controls/LoadingState"
import { RootState } from "../../store"
import { NotificationMessageType, showNotificationMessage } from "../notificationMessages/notificationMessagesSlice"
import { getPreference, setPreference } from "../preferences/api"
import { getNotificationSettings, setNotificationSetting } from "./api"

export type NotificationType = {
  notificationType: string,
  title: string,
}

export function NotificationsSettings() {
  const { t } = useTranslation()
  const token = useSelector((state: RootState) => state.authentication.token)

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [notificationSettings, setNotificationSettings] = useState<{[key: string]: boolean}>({})
  const [isEmailEnabled, setIsEmailEnabled] = useState<boolean>(false)

  const notificationTypes: NotificationType[] = [
    {
      notificationType: 'board-change',
      title: t('On board change'),
    },
    {
      notificationType: 'task-assign',
      title: t('When a task is assigned to me'),
    },
    {
      notificationType: 'task-complete',
      title: t('When a task is completed'),
    },
    {
      notificationType: 'new-videos-articles',
      title: t('When new videos or articles are available'),
    },
  ]

  const reloadNotificationSettings = async () => {
    const notificationSettings = await getNotificationSettings(token!)
    setNotificationSettings(notificationSettings)
    setIsLoading(false)
  }

  const reloadNotificationPreferences = async () => {
    const emailNotifications = await getPreference(token!, 'email-notifications')
    setIsEmailEnabled(emailNotifications.value === 'true')
    setIsLoading(false)
  }

  useEffect(() => {
    reloadNotificationSettings()
    reloadNotificationPreferences()
  }, [])

  const setSetting = async (notificationType: string, enabled: boolean) => {
    await setNotificationSetting(token!, { notificationType, enabled })
    setNotificationSettings({ ...notificationSettings, [notificationType]: enabled })
  }

  const setEmailNotificationsSetting = async (enabled: boolean) => {
    try {
      await setPreference(token!, 'email-notifications', enabled ? 'true' : 'false')
      setIsEmailEnabled(enabled)
    } catch (e: any) {
        dispatch(showNotificationMessage({
          type: NotificationMessageType.Error,
          title: t('Error changing setting'),
          body: e.toString(),
        }))
    }
  }

  return (
    <main className="main-section">
      <div className="container">
        <div className="inner_container">
          {/* notifications form */}
          <div className="pb-5">
            <h3 className="fs_lg c_gray mb-4 fw_medium">
              {t('Notifications')}
            </h3>

            <LoadingState isLoading={isLoading}>
              {notificationTypes.map((notificationType) =>
                <div key={notificationType.notificationType} className="form-check form-switch mb-3">
                  <label className="form-check-label fs_lg">
                    <input className="form-check-input" type="checkbox" role="switch" checked={!!notificationSettings?.[notificationType.notificationType]} onChange={(e) => setSetting(notificationType.notificationType, e.target.checked)} />
                    {notificationType.title}
                  </label>
                </div>
              )}
            </LoadingState>

          </div>

          {/* notification settings */}
          <div className="pb-5">
            <h3 className="fs_lg c_gray mb-4 fw_medium">
              {t('Preferences')}
            </h3>

            <div className="form-check form-switch mb-3">
              <label className="form-check-label fs_lg">
                <input className="form-check-input" type="checkbox" role="switch" checked={isEmailEnabled} onChange={(e) => setEmailNotificationsSetting(e.target.checked)} />
                {t('Receive notifications via email')}
              </label>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}
function dispatch(arg0: any) {
    throw new Error("Function not implemented.")
}

