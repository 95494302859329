import { Cell, CheckmarkType } from '../cells/Cell';
import { IconButton, IconButtonColor } from '../controls/IconButton';
import { FaTrash, FaPlusCircle, FaArrowRight, FaArrowDown, FaObjectGroup } from 'react-icons/fa';
import { ArcherContainer } from 'react-archer';
import { AnchorPositionType } from 'react-archer/lib/types';
import { useState } from 'react';
import { CellContents } from './CellContents';
import { CellCoords, SectionIdentifier } from 'share2flow-board';
import { AppDispatch, RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCell, deleteColumn, deleteRow, insertColumn, insertRow, useMaxColumnCount } from '../../rootState';
import { GroupDialog } from '../../features/groups/GroupDialog';
import { columnWidth } from '../../utils';
import { useTranslation } from 'react-i18next';

export interface CellData {
  contents?: JSX.Element,
  checkmark?: CheckmarkType,
  onClick?: () => void,
  id?: string,
  centerRadius?: number,
  arrows?: { [key in AnchorPositionType]?: string },
}

export interface SectionData {
  id: SectionIdentifier,
  title: string,
  imagePath: string,
  onDeleteClicked?: () => void,
  onAddClicked?: () => void,
}

export interface SectionProps {
  boardId: string,
  sectionData: SectionData,
  sectionIdx: number,
  hiddenColumns?: Set<number>,
  groupColumns?: Set<number>,
  columnColorMapping?: { [key: number]: string },
}

export function zoomUnit(base: number, scale: number, unit: string): string {
  return `${base * scale}${unit}`
}

export function Section({ boardId, sectionData, sectionIdx, hiddenColumns, groupColumns, columnColorMapping }: SectionProps) {
  const { t } = useTranslation()
  const section = useSelector((state: RootState) => state.boardState.board?.sections[sectionData.id]) || []
  const numColumns = useMaxColumnCount() + 1
  const columnsArray = new Array(numColumns).fill(0)
  const dispatch = useDispatch<AppDispatch>()

  const [isDeleteMode, setIsDeleteMode] = useState<boolean>(false)
  const [isInsertMode, setIsInsertMode] = useState<boolean>(false)
  const [hoveredRow, setHoveredRow] = useState<number | undefined>(undefined)
  const [hoveredColumn, setHoveredColumn] = useState<number | undefined>(undefined)

  const zoomScale = useSelector((state: RootState) => state.zoom.scale)

  const [isGroupDialogOpen, setIsGroupDialogOpen] = useState<boolean>(false)

  const toggleDeleteMode = () => {
    setIsInsertMode(false)
    setHoveredRow(undefined)
    setHoveredColumn(undefined)
    setIsDeleteMode(!isDeleteMode)
  }

  const toggleInsertMode = () => {
    setIsDeleteMode(false)
    setHoveredRow(undefined)
    setHoveredColumn(undefined)
    setIsInsertMode(!isInsertMode)
  }

  const deleteRowF = (rowIdx: number) => {
    dispatch(deleteRow({ section: sectionData.id, row: rowIdx, column: 0 }))
    setIsDeleteMode(false)
  }

  const deleteColumnF = (colIdx: number) => {
    dispatch(deleteColumn({ section: sectionData.id, row: 0, column: colIdx }))
    setIsDeleteMode(false)
  }

  const deleteCellF = (coords: CellCoords) => {
    dispatch(deleteCell(coords))
  }

  const insertRowF = (rowIdx: number) => {
    dispatch(insertRow({ section: sectionData.id, row: rowIdx, column: 0 }))
    setIsInsertMode(false)
  }

  const insertColumnF = (colIdx: number) => {
    dispatch(insertColumn({ section: sectionData.id, row: 0, column: colIdx }))
    setIsInsertMode(false)
  }

  const renderDeleteRow = (rowIdx: number) => (
    <>
      <div
        className={`absolute inset-0 pointer-events-none z-[512] ${hoveredRow === rowIdx && 'bg-red-400/50'}`}
      >
      </div>
      <div
        className="absolute inset-y-0 cursor-e-resize z-[514] flex items-center justify-center bg-red-200/50"
        onMouseEnter={() => setHoveredRow(rowIdx)}
        onMouseLeave={() => setHoveredRow(undefined)}
        onClick={() => deleteRowF(rowIdx)}
        style={{ width: zoomUnit(40, zoomScale, 'px') }}
      >
        <FaArrowRight className="text-button-peach" />
      </div>
    </>
  )

  const renderInsertRow = (rowIdx: number) => (
    <>
      <div
        className={`absolute h-4 inset-x-0 cursor-e-resize z-[512] ${hoveredRow === rowIdx && 'bg-green-400/50'}`}
        onMouseEnter={() => setHoveredRow(rowIdx)}
        onMouseLeave={() => setHoveredRow(undefined)}
        onClick={() => insertRowF(rowIdx)}
      >
      </div>
      <div
        className="absolute h-8 w-12 cursor-e-resize z-[514] flex items-center justify-center bg-green-200/50"
        onMouseEnter={() => setHoveredRow(rowIdx)}
        onMouseLeave={() => setHoveredRow(undefined)}
        onClick={() => insertRowF(rowIdx)}
      >
        <FaArrowRight className="text-button-green" />
      </div>
      {rowIdx === section.length - 1 &&
        <>
          <div
            className={`absolute h-4 bottom-0 inset-x-0 cursor-e-resize z-[512] ${hoveredRow === rowIdx + 1 && 'bg-green-400/50'}`}
            onMouseEnter={() => setHoveredRow(rowIdx + 1)}
            onMouseLeave={() => setHoveredRow(undefined)}
            onClick={() => insertRowF(rowIdx + 1)}
          >
          </div>
          <div
            className="absolute h-8 w-12 bottom-0 cursor-e-resize z-[514] flex items-center justify-center bg-green-200/50"
            onMouseEnter={() => setHoveredRow(rowIdx + 1)}
            onMouseLeave={() => setHoveredRow(undefined)}
            onClick={() => insertRowF(rowIdx + 1)}
          >
            <FaArrowRight className="text-button-green" />
          </div>
        </>
      }
    </>
  )

  const renderDeleteColumn = (colIdx: number, rowIdx: number) => {
    if (colIdx === 0) return <></>
    if (rowIdx !== 0) return <></>

    return (
      <>
        <div
          className={`absolute -top-[1000px] -bottom-[1000px] pointer-events-none z-[512] ${hoveredColumn === colIdx && 'bg-red-500/50'}`}
          style={{ left: zoomUnit(colIdx * columnWidth, zoomScale, 'px'), width: zoomUnit(columnWidth, zoomScale, 'px') }}
        >
        </div>
        <div
          className="absolute cursor-s-resize z-[513] flex items-center justify-center bg-red-200/50"
          onMouseEnter={() => setHoveredColumn(colIdx)}
          onMouseLeave={() => setHoveredColumn(undefined)}
          onClick={() => deleteColumnF(colIdx)}
          style={{ left: zoomUnit(colIdx * columnWidth, zoomScale, 'px'), width: zoomUnit(columnWidth, zoomScale, 'px'), height: zoomUnit(32, zoomScale, 'px') }}
        >
          <FaArrowDown className="text-button-peach" />
        </div>
      </>
    )
  }

  const renderInsertColumn = (colIdx: number, rowIdx: number) => {
    if (colIdx === 0) return <></>
    if (rowIdx !== 0) return <></>

    return (
      <>
        <div
          className={`absolute w-3 inset-y-0 -top-[1000px] -bottom-[1000px] cursor-s-resize z-[512] ${hoveredColumn === colIdx && 'bg-green-400/50'}`}
          onMouseEnter={() => setHoveredColumn(colIdx)}
          onMouseLeave={() => setHoveredColumn(undefined)}
          onClick={() => insertColumnF(colIdx)}
        >
        </div>
        <div
          className="absolute w-8 h-12 cursor-s-resize z-[513] flex items-center justify-center bg-green-200/50"
          onMouseEnter={() => setHoveredColumn(colIdx)}
          onMouseLeave={() => setHoveredColumn(undefined)}
          onClick={() => insertColumnF(colIdx)}
        >
          <FaArrowDown className="text-button-green" />
        </div>
      </>
    )
  }

  const renderDeleteCell = (coords: CellCoords) => (
    <div
      className="absolute top-0 cursor-pointer hover:bg-red-500/50 z-[510] delete-cell"
      style={{ left: zoomUnit(coords.column * columnWidth, zoomScale, 'px'), height: zoomUnit(96, zoomScale, 'px'), width: zoomUnit(columnWidth, zoomScale, 'px') }}
      onClick={() => deleteCellF(coords)}
    >
    </div>
  )

  const colorHexToRgba = (hex: string, alpha: number) => {
    const r = parseInt(hex.slice(1, 3), 16)
    const g = parseInt(hex.slice(3, 5), 16)
    const b = parseInt(hex.slice(5, 7), 16)

    return `rgba(${r},${g},${b},${alpha})`
  }

  return (
    <div className={`flex ${sectionIdx % 2 ? 'bg-active-100' : 'bg-white'}`} style={{ height: zoomUnit(Math.max(section.length, 2) * 96, zoomScale, 'px') }}>
      <div className="flex render-single-row">
        <div className={`border border-gray-300 sticky left-0 z-[500] ${sectionIdx % 2 ? 'bg-active-100' : 'bg-white'}`} style={{ width: zoomUnit(columnWidth, zoomScale, 'px'), height: zoomUnit(96 * Math.max(section.length, 2), zoomScale, 'px') }}>
          <div className="absolute top-0 left-0 shrink-0 p-2 flex flex-col gap-2 items-center justify-center" style={{ transform: `scale(${zoomScale})`, transformOrigin: '0 0', width: zoomUnit(columnWidth, 1, 'px'), height: zoomUnit(96 * Math.max(section.length, 2), 1, 'px') }}>
            <div className="flex gap-2">
              <IconButton icon={FaTrash} color={IconButtonColor.BAD} onClick={() => toggleDeleteMode()} title={t('Delete')} />
              <IconButton icon={FaPlusCircle} color={IconButtonColor.GOOD} onClick={() => toggleInsertMode()} title={t('Insert')} />
              {sectionData.id === SectionIdentifier.ROLES_AND_FLOWS && <IconButton icon={FaObjectGroup} color={IconButtonColor.NEUTRAL} onClick={() => setIsGroupDialogOpen(true)} title={t('Group')} />}
            </div>
            <h2 className="text-center text-md text-gray-700 font-semibold">{sectionData.title}</h2>
            {sectionData.imagePath &&
              <img src={sectionData.imagePath} className="w-24" />}
          </div>
        </div>
        <ArcherContainer
          strokeColor="#50b2d9"
          svgContainerStyle={{ zIndex: 10 }}
          endShape={{ arrow: { arrowLength: 5, arrowThickness: 5 } }}
        >
          {section.map((row, rowIdx: number) => (
            <div className="flex relative" key={rowIdx}>
              {isDeleteMode && renderDeleteRow(rowIdx)}
              {isInsertMode && renderInsertRow(rowIdx)}
              {columnsArray.map((_, colIdx: number) => {
                const coords = {section: sectionData.id, row: rowIdx, column: colIdx}
                let color = '#ffffff'
                if (sectionIdx % 2) {
                  color = '#f8f7fc'
                }
                if (columnColorMapping?.[colIdx]) {
                  color = colorHexToRgba(columnColorMapping[colIdx], 0.2)
                }

                if (hiddenColumns?.has(colIdx)) {
                  if (groupColumns?.has(colIdx)) {
                    const groupCells = []
                    let i = colIdx
                    while (hiddenColumns?.has(i) && (i === colIdx || !groupColumns?.has(i))) {
                      groupCells.push(i)
                      i++
                    }
                    return (
                      <div key={colIdx} className="relative">
                        <div className="hover:bg-indigo-100 bg-opacity-50 border border-300 text-gray-700 flex items-center justify-center relative" style={{ width: zoomUnit(columnWidth, zoomScale, 'px'), height: zoomUnit(96, zoomScale, 'px') }}>
                        </div>

                        {groupCells.map((i) => {
                          const cell = row[i]
                          const coords = {section: sectionData.id, row: rowIdx, column: i}

                          return (
                            <div key={i} className="absolute left-0 top-0">
                              <Cell coords={coords} zoomScale={zoomScale}>
                                <CellContents coords={coords} boardId={boardId} />
                              </Cell>
                            </div>
                          )
                        })}
                      </div>
                    )
                  } else {
                    return <></>
                  }
                }


                return (
                  <div className={`${colIdx === 0 ? 'sticky z-[500]' : ''}`} key={colIdx} style={{ left: zoomUnit(columnWidth, zoomScale, 'px'), backgroundColor: color }}>
                    {isDeleteMode && renderDeleteColumn(colIdx, rowIdx)}
                    {isInsertMode && renderInsertColumn(colIdx, rowIdx)}
                    {isDeleteMode && renderDeleteCell(coords)}
                    <Cell coords={coords} zoomScale={zoomScale}>
                      <CellContents coords={coords} boardId={boardId} />
                    </Cell>
                  </div>
                );
              })}
            </div>
          ))}
        </ArcherContainer>
        <GroupDialog open={isGroupDialogOpen} onClose={() => setIsGroupDialogOpen(false)} />
      </div>
    </div>
  )
}
