import { Avatar } from "../controls/Avatar";

interface CellPersonProps {
  avatarUrl: string,
  name: string,
  description: string,
}

export function CellPerson({ avatarUrl, name, description }: CellPersonProps) {
  return (
    <div className="flex-1 flex gap-2 items-center m-2 overflow-hidden">
      <Avatar avatarUrl={avatarUrl} />
      <div className="flex flex-col text-sm flex-1">
        <span className="font-bold">{name}</span>
        <span>{description}</span>
      </div>
    </div>
  );
}
