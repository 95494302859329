import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface ArrowEndReference {
  id: string,
  tag: string,
}

export function areArrowEndReferencesEqual(first: ArrowEndReference, second: ArrowEndReference): boolean {
  return first.id === second.id && first.tag === second.tag
}

export interface ArrowDrawingState {
  isDrawing: boolean,
  source?: ArrowEndReference,
  target?: ArrowEndReference,
}

const initialState: ArrowDrawingState = {
  isDrawing: false,
  source: undefined,
  target: undefined,
}

export const arrowDrawingSlice = createSlice({
  name: 'arrowDrawing',
  initialState,
  reducers: {
    startDrawing: (state, action: PayloadAction<ArrowEndReference>) => {
      state.isDrawing = true
      state.source = action.payload
      state.target = undefined
    },
    setTarget: (state, action: PayloadAction<ArrowEndReference>) => {
      state.target = action.payload
    },
    clearTarget: (state) => {
      state.target = undefined
    },
    endDrawing: (state) => {
      state.isDrawing = false
      state.source = undefined
      state.target = undefined
    },
  },
})

export const { startDrawing, setTarget, clearTarget, endDrawing } = arrowDrawingSlice.actions

export default arrowDrawingSlice.reducer
