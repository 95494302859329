import config from "../../config"
import { BoardState as BoardState2, SectionIdentifier } from "share2flow-board"
import { findCellCoordsById } from "../../rootState"

export function exportPng(token: string, boardId: string) {
  window.open(`${config.apiUrl}/render/board/${boardId}/png?token=${token}`)
}

export function exportPdf(token: string, boardId: string) {
  window.open(`${config.apiUrl}/render/board/${boardId}/pdf?token=${token}`)
}

type csvObj = {
  number: number,
  userFunction: string,
  processDescription: string,
  kpis: string[],
  problems: string[],
  ideas: string[],
  comments: string[],
  pushToProcess: string[],
}

export function exportCsv(board: BoardState2) {
  const getKpiDesc = (cellId: string): string | undefined => {
    const coords = findCellCoordsById(board, cellId)
    if (coords) {
      const cell = board?.sections[coords.section]?.[coords.row]?.[coords.column]
      const kpiCell = board?.sections[coords.section]?.[coords.row]?.[0]
      if (cell && kpiCell) {
        return `${kpiCell.name}: ${kpiCell?.prefix || ''}${cell.value}${kpiCell?.suffix || ''}`
      }
    }
    return undefined
  }

  const getKpis = (cellId: string): string[] => {
    let result: string[] = []
    const leftKpiRef = board.references.filter((reference) => reference.sourceId === cellId && reference.sourceTag === 'leftKpi')
    const rightKpiRef = board.references.filter((reference) => reference.sourceId === cellId && reference.sourceTag === 'rightKpi')
    if (leftKpiRef.length) {
      const kpiDesc = getKpiDesc(leftKpiRef[0].targetId)
      if (kpiDesc) {
        result.push(kpiDesc)
      }
    }
    if (rightKpiRef.length) {
      const kpiDesc = getKpiDesc(rightKpiRef[0].targetId)
      if (kpiDesc) {
        result.push(kpiDesc)
      }
    }
    return result
  }

  const getProblems = (colIdx: number): string[] => {
    let result: string[] = []
    for (let rowIdx = 0; rowIdx < board.sections[SectionIdentifier.PROBLEMS_AND_DIFFICULTIES].length; rowIdx++) {
      const row = board.sections[SectionIdentifier.PROBLEMS_AND_DIFFICULTIES][rowIdx]
      if (row[colIdx] && row[colIdx].text) {
        result.push(row[colIdx].text)
      }
    }
    return result
  }

  const getIdeas = (colIdx: number): string[] => {
    let result: string[] = []
    for (let rowIdx = 0; rowIdx < board.sections[SectionIdentifier.IDEAS_AND_IMPROVEMENTS].length; rowIdx++) {
      const row = board.sections[SectionIdentifier.IDEAS_AND_IMPROVEMENTS][rowIdx]
      if (row[colIdx] && row[colIdx].text) {
        result.push(row[colIdx].text)
      }
    }
    return result
  }

  const getComments = (colIdx: number): string[] => {
    let result: string[] = []
    for (let rowIdx = 0; rowIdx < board.sections[SectionIdentifier.COMMENTS].length; rowIdx++) {
      const row = board.sections[SectionIdentifier.COMMENTS][rowIdx]
      if (row[colIdx] && row[colIdx].text) {
        result.push(row[colIdx].text)
      }
    }
    return result
  }

  const getPushToProcess = (cellId: string): string[] => {
    const tags = [
      'bottomRight',
      'topRight',
      'bottomLeft',
      'topLeft',
    ]
    const references = board.references.filter((reference) => reference.sourceId === cellId && tags.includes(reference.sourceTag))
    return references.map((reference) => reference.targetId)
  }

  let objs: csvObj[] = []
  let objIds: {[id: string]: number} = {}

  for (let rowIdx = 0; rowIdx < board.sections[SectionIdentifier.ROLES_AND_FLOWS].length; rowIdx++) {
    const row = board.sections[SectionIdentifier.ROLES_AND_FLOWS][rowIdx]
    for (let colIdx = 1; colIdx < row.length; colIdx++) {
      const cell = row[colIdx]
      if (cell) {
        objIds[cell.id] = objs.length + 1
        if (cell.type === 'Process Step') {
          objs.push({
            number: objs.length + 1,
            userFunction: row[0]?.role,
            processDescription: cell.text,
            kpis: getKpis(cell.id),
            problems: getProblems(colIdx),
            ideas: getIdeas(colIdx),
            comments: getComments(colIdx),
            pushToProcess: getPushToProcess(cell.id),
          })
        } else if (cell.type === 'Decision') {
          objs.push({
            number: objs.length + 1,
            userFunction: row[0]?.role,
            processDescription: 'Decision-based route',
            kpis: [],
            problems: getProblems(colIdx),
            ideas: getIdeas(colIdx),
            comments: getComments(colIdx),
            pushToProcess: getPushToProcess(cell.id),
          })
        }
      }
    }
  }

  const kpiColCount = objs.reduce((prev, curr) => Math.max(prev, curr.kpis.length), 0)
  const problemColCount = objs.reduce((prev, curr) => Math.max(prev, curr.problems.length), 0)
  const ideaColCount = objs.reduce((prev, curr) => Math.max(prev, curr.ideas.length), 0)
  const commentColCount = objs.reduce((prev, curr) => Math.max(prev, curr.comments.length), 0)
  const pushToProcessColCount = objs.reduce((prev, curr) => Math.max(prev, curr.pushToProcess.length), 0)

  let out = ''
  let emptyLine = true
  const pushLine = () => {
    emptyLine = true
    out += '\n'
  }
  const pushValue = (value: string) => {
    let escapedValue = value
    if (escapedValue.includes(',') || escapedValue.includes('\n')) {
      escapedValue.replaceAll('"', '""')
      escapedValue = `"${escapedValue}"`
    }
    if (!emptyLine) {
      out += ','
    }
    out += escapedValue
    emptyLine = false
  }
  const pushValues = (values: string[], length: number) => {
    for (let i = 0; i < length; i++) {
      if (i < values.length) {
        pushValue(values[i])
      } else {
        pushValue('')
      }
    }
  }
  const pushHeaderValues = (name: string, length: number) => {
    if (length === 0) {
      return
    }

    if (length === 1) {
      pushValue(name)
    } else {
      for (let i = 0; i < kpiColCount; i++) {
        pushValue(`${name} ${i + 1}`)
      }
    }
  }

  // Print headers
  pushValue('Process number')
  pushValue('User function')
  pushValue('Process description')
  pushHeaderValues('KPI', kpiColCount)
  pushHeaderValues('Problem', problemColCount)
  pushHeaderValues('Idea', ideaColCount)
  pushHeaderValues('Comment', commentColCount)
  pushHeaderValues('Push to process', pushToProcessColCount)
  pushLine()

  // Print values
  for (const obj of objs) {
    pushValue(obj.number.toString())
    pushValue(obj.userFunction)
    pushValue(obj.processDescription)
    pushValues(obj.kpis, kpiColCount)
    pushValues(obj.problems, problemColCount)
    pushValues(obj.ideas, ideaColCount)
    pushValues(obj.comments, commentColCount)
    pushValues(obj.pushToProcess.map((p) => objIds[p].toString()), pushToProcessColCount)
    pushLine()
  }

  // Download file
  const blob = new Blob([out], {type : 'text/csv'});
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `${board.boardInfo.title}.csv`;
  link.click();
  URL.revokeObjectURL(url);
}
