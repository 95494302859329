export function numberToLetter(num: number): string {
  const m = num % 26
  const c = String.fromCharCode(65 + m)
  const r = num - m
  return r > 0
    ? `${numberToLetter((r - 1) / 26)}${c}`
    : `${c}`
}

export function monthToShort(language: string, month: number): string {
  switch (language) {
    case 'de':
      return ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov','Dez'][month]
    default:
      return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct','Nov','Dec'][month]
  }
}

export function monthToLong(language: string, month: number): string {
  switch (language) {
    case 'de':
      return ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August','September', 'Oktober', 'November', 'Dezember'][month]
    default:
      return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August','September', 'October', 'November', 'December'][month]
  }
}

export function formatDateLong(language: string, date: Date): string {
  const day = date.getDate()
  const month = date.getMonth()
  const year = date.getFullYear()
  return `${day}. ${monthToLong(language, month)} ${year}`
}

export function formatDateTime(language: string, date: Date): string {
  const day = date.getDate()
  const month = date.getMonth()
  const year = date.getFullYear()
  const hours = date.getHours()
  const minutes = date.getMinutes()
  return `${day}. ${monthToLong(language, month)} ${year}, ${hours}:${minutes.toString().padStart(2, '0')}`
}

export const columnWidth = 192

export function parseGlobalFloat(value: string | number | boolean): number {
  if (typeof value === 'boolean') {
    return value ? 1 : 0
  }
  if (typeof value === 'number') {
    return value
  }
  return parseFloat(value.replace(',', '.'))
}

export function formatGlobalFloat(language: string, value: number | string | boolean, decimalPlaces: number | undefined = undefined): string {
  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No'
  }
  if (typeof value === 'string') {
    return value
  }
  if (decimalPlaces === undefined) {
    if (language === 'de') {
      return value.toString().replace('.', ',')
    }
    return value.toString()
  }
  if (language === 'de') {
    return value.toFixed(decimalPlaces).replace('.', ',').replace(/0+$/, '').replace(/,$/, '')
  }
  return value.toFixed(decimalPlaces).replace(/0+$/, '').replace(/\.$/, '')
}
