import { Fragment, PropsWithChildren } from 'react'
import { Dialog as HeadlessDialog, Transition } from '@headlessui/react'

export interface CustomControl {
  id: string,
  text: string,
  isPrimary?: boolean,
  onClick: () => void,
}

export interface DialogProps {
  title: string,
  controls?: CustomControl[],
  controlsDisabled?: boolean,
  open: boolean,
  onClose: () => void,
}

export function Dialog({ title, controls, controlsDisabled, open, onClose, children }: PropsWithChildren<DialogProps>) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <HeadlessDialog as="div" className="relative z-[1000]" onClose={() => onClose()} onMouseDown={(e: any) => { e.stopPropagation() }}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <HeadlessDialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-3 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mt-3">
                    <HeadlessDialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      {title}
                    </HeadlessDialog.Title>
                    <div className="mt-2 flex">
                      {children}
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:flex flex-row-reverse sm:gap-3">
                  {controls && controls.map((control) => (
                    <button
                      key={control.id}
                      type="button"
                      disabled={controlsDisabled}
                      className={control.isPrimary
                        ? "flex-1 inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                        : "flex-1 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"}
                      onClick={() => control.onClick()}
                    >
                      {control.text}
                    </button>
                  ))}
                </div>
              </HeadlessDialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </HeadlessDialog>
    </Transition.Root>
  )
}
