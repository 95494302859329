import { NamedIcon, NamedIcons } from '../controls/NamedIcon';

export enum GradientColor {
  GOOD,
  BAD,
}

interface CellKpiProps {
  text: string,
  sum?: string,
  avg?: string,
  iconName?: NamedIcons,
  color?: GradientColor,
  showStatsOnHover?: boolean,
}

export function CellKpi({ text, sum, avg, iconName, color, showStatsOnHover }: CellKpiProps) {
  return (
    <div className={`w-full h-full flex flex-col items-stretch justify-between py-1 group bg-gradient-to-r via-transparent to-transparent ${color === GradientColor.BAD && 'from-primary-orange/25'} ${color === GradientColor.GOOD && 'from-primary-teal/25'}`}>
      {avg
        && <p className={`text-center text-xs text-gray-400 ${showStatsOnHover && 'opacity-0 group-hover:opacity-100'}`}>Ø {avg}</p>}
      <div className="flex flex-1 gap-2 items-center px-2 overflow-hidden">
        {iconName
          && <NamedIcon iconName={iconName} size="20px" className={`${color === GradientColor.BAD && 'text-primary-orange'} ${color === GradientColor.GOOD && 'text-primary-teal'} ${!color && 'text-gray-400'}`} />}
        <p className="text-center flex-1">{text}</p>
      </div>
      {sum
        && <p className={`text-center text-xs text-gray-400 ${showStatsOnHover && 'opacity-0 group-hover:opacity-100'}`}>∑ {sum}</p>}
    </div>
  )
}
