import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { newBoardState, SectionIdentifier } from "share2flow-board"
import { Board, RichBoardItem, BoardMember, UserBoardRole } from "share2flow-typedefs"
import { Avatar } from "../../components/controls/Avatar"
import { LoadingState } from "../../components/controls/LoadingState"
import { NamedIcon } from "../../components/controls/NamedIcon"
import config from "../../config"
import { RootState } from "../../store"
import { formatGlobalFloat } from "../../utils"
import { listBoardMembers } from "../authorization/api"
import { createBoard, getBoard, listRecentBoards } from "./api"

export function BoardList() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [boards, setBoards] = useState<RichBoardItem[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const token = useSelector((state: RootState) => state.authentication.token)

  useEffect(() => {
    const updateBoards = async () => {
      try {
        setBoards(await listRecentBoards(token!, 2))
      } finally {
        setIsLoading(false)
      }
    }

    updateBoards()
  }, [])

  const createNewBoard = async () => {
    try {
      const res = await createBoard(token!, t('New Board'))
      navigate(`/boards/${res.id}`)
    } catch {}
  }

  return (
    <div className="bootstrap">
      {/* .................. start page header .................. */}
      <div className="page-header pt-3 bg_light">
        <div className="container">
          <h1 className="page-title">
            {t('Home')}
          </h1>
        </div>
      </div>
      {/* .................. end page header .................. */}

      {/* .................. start main section .................. */}
      <main className="main-section">
        <div className="container">
          <div className="inner_container">
            <div className="pb-3 pb-xl-4">
              <button className="btn btn_def btn_primary" onClick={createNewBoard}>+ {t('New Board')}</button>
            </div>

            {/* board row */}
            <LoadingState isLoading={isLoading}>
              {boards.map((board) => <BoardItem key={board.id} board={board} />)}
            </LoadingState>
          </div>
        </div>
      </main>
      {/* .................. end main section .................. */}
    </div>
  )
}

interface BoardItemProps {
  board: RichBoardItem,
}

type KPISummary = {
  idx: number,
  icon: string,
  name: string,
  unit?: string,
  min?: string,
  max?: string,
  avg?: string,
  sum?: string,
}

function BoardItem({ board }: BoardItemProps) {
  const { t, i18n } = useTranslation()
  const token = useSelector((state: RootState) => state.authentication.token)

  const [users, setUsers] = useState<BoardMember[]>()
  const [kpis, setKpis] = useState<KPISummary[]>()

  const updateRoles = async () => {
    try {
      const res = await listBoardMembers(token!, board.id)
      setUsers(res.members)
    } catch (e: any) {
      console.error(e)
    }
  }

  const updateKpis = async () => {
    try {
      const res: Board = await getBoard(token!, board.id)
      const state = newBoardState(JSON.parse(res.data))
      const kpis: KPISummary[] = state.sections[SectionIdentifier.KPIS].map((row, idx): KPISummary | undefined => {
        if (!row || row.length < 1) return undefined

        const header = row[0]
        if (!header || header.type !== 'Number') return undefined

        let min: number | undefined
        let max: number | undefined
        let avg: number | undefined
        let sum: number | undefined
       
        if (header.type === 'Number') {
          const values: number[] = row.slice(1).map((item) => item?.value).filter((value) => !!value)
          if (values.length < 1) return
          min = values[0]
          max = values[0]
          sum = 0
          let count = 0
          for (let value of values) {
            if (typeof value !== 'number') {
              value = parseInt(value)
              if (isNaN(value)) continue
            }
            count += 1
            if (value < min) min = value
            if (value > max) max = value
            sum += value
          }
          avg = Math.floor(100 * sum / count) / 100
        }

        return {
          idx,
          icon: header.icon,
          name: header.name,
          unit: (header.prefix || header.suffix || '').trim(),
          min: min !== undefined ? formatGlobalFloat(i18n.language, min, 2) : undefined,
          max: max !== undefined ? formatGlobalFloat(i18n.language, max, 2) : undefined,
          avg: avg !== undefined ? formatGlobalFloat(i18n.language, avg, 2) : undefined,
          sum: sum !== undefined ? formatGlobalFloat(i18n.language, sum, 2) : undefined,
        }
      }).filter((kpi) => !!kpi).slice(0, 3) as KPISummary[]

      setKpis(kpis)
    } catch (e: any) {
      console.error(e)
    }
  }

  useEffect(() => {
    updateRoles()
    updateKpis()
  }, [board])

  return (
    <div className="py-4 border-bottom">
      <Link to={`/boards/${board.id}`} className="fs_lg fw_medium hover_underline c_dark mb-3 block">
        {board.title}
      </Link>
      <div className="grid lg:grid-cols-3 gap-4 align-stretch">
        <div>
          <div className="card">
            <div className="card-header bg_warning_light d-flex justify-content-between align-items-center">
              {!board.ownerId
                ? <div className="fs_sm fw_semiBold c_dark me-3">{t('Board Overview')}</div>
                : <div className="d-flex card_userInfo gap-2">
                    <Avatar avatarUrl={board.ownerAvatarUrl} size={28} />
                    <div className="card_userName fs_sm fw_semiBold c_dark">{board.ownerName}</div>
                  </div>
              }
            </div>
            <Link to={`/boards/${board.id}`} className="card-body p-0">
              <img className="img-fluid" src={`${config.apiUrl}/render/board/${board.id}/preview?token=${token}`} alt="board" />
            </Link>
          </div>
        </div>
        <div>
          <div className="card h-full">
            <div className="card-header bg_warning_light d-flex justify-content-between align-items-center">
              <div className="fs_sm fw_semiBold c_dark me-3">{t('User Overview')}</div>
            </div>
            <div className="card-body p-3">
              <ul className="user-overviewList list-unstyled ps-0">
                {users?.map((user) => (
                  <li className="user-overviewItem" key={user.userId}>
                    <div className="user-overviewItemUser">
                      <div className="user-overviewIconBox">
                        <i className="fi fi-rr-check text-success"></i>
                      </div>
                      <div className="user-overviewImgBox">
                        <Avatar avatarUrl={user.avatarUrl} size={30} />
                      </div>
                      <div className="user-overviewUserName">
                        {user.name}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div>
          <div className="card h-full">
            <div className="card-header bg_warning_light d-flex justify-content-between align-items-center">
              <div className="fs_sm fw_semiBold c_dark">{t('KPI Summary')}</div>
            </div>
            <div className="card-body p-3">
              {kpis?.map((kpi) => (
                <div className="summary_area" key={kpi.idx}>
                  <h6 className="summary_header c_dark">
                    {kpi.icon &&
                      <NamedIcon iconName={kpi.icon as any} size="15px" className='c_gray' />}
                    <span>{kpi.name} {kpi.unit && `[${kpi.unit}]`}</span>
                  </h6>
                  <div className="d-flex">
                    <div className="summary_item w-25 bg_light text-center py-2 px-1">
                      <div className="fs_sm fw_semiBold c_gray mb-2">Min.</div>
                      <div className="fs_lg fw_semiBold c_secondary">{kpi.min || '-'}</div>
                    </div>
                    <div className="summary_item w-25 bg_light text-center py-2 px-1">
                      <div className="fs_sm fw_semiBold c_gray mb-2">Ø</div>
                      <div className="fs_lg fw_semiBold c_secondary">{kpi.avg || '-'}</div>
                    </div>
                    <div className="summary_item w-25 bg_light text-center py-2 px-1">
                      <div className="fs_sm fw_semiBold c_gray mb-2">Max.</div>
                      <div className="fs_lg fw_semiBold c_secondary">{kpi.max || '-'}</div>
                    </div>
                    <div className="summary_item w-25 bg_light text-center py-2 px-1">
                      <div className="fs_sm fw_semiBold c_gray mb-2">∑</div>
                      <div className="fs_lg fw_semiBold c_secondary">{kpi.sum || '-'}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
