import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { UserNotification } from "share2flow-typedefs"
import { io } from "socket.io-client"
import { LoadingState } from "../../components/controls/LoadingState"
import config from "../../config"
import { AppDispatch, RootState } from "../../store"
import { formatDateLong, monthToShort } from "../../utils"
import { NotificationMessageType, showNotificationMessage } from "../notificationMessages/notificationMessagesSlice"
import { getUserNotifications, markNotificationsAsRead } from "./api"

export function Notifications() {
  const { t, i18n } = useTranslation()
  const token = useSelector((state: RootState) => state.authentication.token)
  const dispatch = useDispatch<AppDispatch>()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [userNotifications, setUserNotifications] = useState<UserNotification[]>([])

  const updateUserNotifications = async () => {
    setIsLoading(true)
    try {
      const nofications = await getUserNotifications(token!)
      setUserNotifications(nofications.notifications)
    } catch (e: any) {
      dispatch(showNotificationMessage({
        type: NotificationMessageType.Error,
        title: t('Error fetching notifications'),
        body: e.toString(),
      }))
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    updateUserNotifications()

    const socket = io(`${config.backendUrl}/notifications`, {
      auth: {
        token,
      }
    })

    socket?.on("notification", (data) => {
      updateUserNotifications();
    });

    return () => {
      socket?.disconnect();
    }
  }, []);

  const markAllAsRead = async () => {
    await markNotificationsAsRead(token!)
    await updateUserNotifications()
  }

  return (
    <div className="bootstrap">
      {/* .................. start page header .................. */}
      <div className="page-header pt-3 bg_light">
        <div className="container">
          <h1 className="page-title">
            {t('Notifications')}
          </h1>
        </div>
      </div>
      {/* .................. end page header .................. */}

      {/* .................. start main section .................. */}
      <main className="main-section">
        <div className="container">
          <div className="inner_container">
            <div className="d-flex justify-content-end align-items-center mb-4">
              <button className="btn btn-primary" onClick={markAllAsRead}>{t('Mark all as read')}</button>
            </div>
            <LoadingState isLoading={isLoading}>
              {userNotifications.length === 0
                ? (
                  <div className="text-center">
                    <p className="c_gray2 mb-3">{t('No notifications found')}</p>
                  </div>
                )
                : (
                  <ul className="invoice_list list-unstyled ps-0">
                    {userNotifications.map((notification) => {
                      const date = new Date(notification.insertedAt)
                      const hours = date.getHours().toString().padStart(2, '0')
                      const minutes = date.getMinutes().toString().padStart(2, '0')
                      return (
                        <li key={notification.id} className="invoice_item c_gray2 d-flex align-items-start">
                          <div className="invoice_date text-end">
                            <span>{date.getDay()} {monthToShort(i18n.language, date.getMonth())} {date.getFullYear()}</span><br />
                            <span className="c_gray">{hours}:{minutes}</span>
                          </div>
                          <div className="rounded-3 border py-2 px-3 d-flex justify-content-between flex-grow-1">
                            <div className="me-2">
                              <Link
                                to={notification.boardId ? `/boards/${notification.boardId}` : `${notification.url}`}
                                className={`hover_underline c_gray2 cursor-pointer ${notification.read? '' : 'font-bold'}`}
                                target={notification.boardId ? '_self' : '_blank'}
                              >{notification.title}</Link>
                            </div>
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                )}
            </LoadingState>
          </div>
        </div>
      </main>
      {/* .................. end main section .................. */}
    </div>
  )
}
