interface CellColorBoxProps {
  color: string,
}

export function CellColorBox({ color }: CellColorBoxProps) {
  return (
    <div className="w-4 h-4 rounded border border-gray-400" style={{ backgroundColor: color }}>
    </div>
  );
}
