import config from "../../config"

export async function listUsers(token: string): Promise<any[]> {
  const res = await fetch(`${config.apiUrl}/management/users`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
  return await res.json()
}

export async function getSubscription(token: string, id: string): Promise<any> {
  const res = await fetch(`${config.apiUrl}/management/subscriptions/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
  return await res.json()
}

export async function setUserActive(token: string, id: string, active: boolean): Promise<void> {
  await fetch(`${config.apiUrl}/management/users/${id}/active`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({ active }),
  })
}
