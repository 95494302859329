import { AllPlansResponse, BillingAddress, Customer, CustomerResponse, SubscriptionResponse, TransactionsResponse } from "share2flow-typedefs"
import config from "../../config"

export async function getClientToken(token: string): Promise<any> {
  const res = await fetch(`${config.apiUrl}/billing/client_token`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
  return await res.json()
}

export async function getCustomer(token: string): Promise<CustomerResponse> {
  const res = await fetch(`${config.apiUrl}/billing/customer`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
  return await res.json()
}

export async function createCustomer(token: string, customer: Customer): Promise<CustomerResponse> {
  const res = await fetch(`${config.apiUrl}/billing/customer`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({
      customer,
    }),
  })
  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
  return await res.json()
}

export async function getSubscription(token: string): Promise<SubscriptionResponse> {
  const res = await fetch(`${config.apiUrl}/billing/subscription`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
  return await res.json()
}

export async function setSubscription(token: string, planId?: string): Promise<SubscriptionResponse> {
  const res = await fetch(`${config.apiUrl}/billing/subscription`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({
      planId,
    }),
  })
  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
  return await res.json()
}

export async function allPlans(token: string): Promise<AllPlansResponse> {
  const res = await fetch(`${config.apiUrl}/billing/all-plans`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
  return await res.json()
}

export async function setBillingAddress(token: string, billingAddress: BillingAddress): Promise<void> {
  const res = await fetch(`${config.apiUrl}/billing/billing-address`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({ billingAddress }),
  })
  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
}

export async function addPaymentMethod(token: string, paymentMethodNonce: string): Promise<void> {
  const res = await fetch(`${config.apiUrl}/billing/payment-method`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({ paymentMethodNonce }),
  })
  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
}

export async function removePaymentMethod(token: string): Promise<void> {
  const res = await fetch(`${config.apiUrl}/billing/payment-method`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
}

export async function allTransactions(token: string, year: number): Promise<TransactionsResponse> {
  const res = await fetch(`${config.apiUrl}/billing/transactions/${year}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
  if (res.status === 404) {
    return {
      transactions: [],
    }
  }
  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
  return await res.json()
}
