import React from 'react';
import { IconType } from 'react-icons';

export enum IconButtonColor {
  GOOD,
  BAD,
  NEUTRAL,
}

interface IconButtonProps {
  icon: IconType,
  color?: IconButtonColor,
  onClick?: React.MouseEventHandler<HTMLButtonElement>,
  size?: number,
  title?: string,
}

export function IconButton({ icon, color, onClick, size, title }: IconButtonProps) {
  let colorClass = 'text-gray-400';

  if (color === IconButtonColor.GOOD) {
    colorClass = 'text-button-green';
  } else if (color === IconButtonColor.BAD) {
    colorClass = 'text-button-peach';
  } else if (color === IconButtonColor.NEUTRAL) {
    colorClass = 'text-button-neutral';
  }

  return (
    <button className="p-1 bg-button-bg hover:bg-button-bg/50 rounded-md" onClick={onClick} title={title}>
      {React.createElement(icon, { size: `${size || 18}px`, className: colorClass })}
    </button>
  )
}
