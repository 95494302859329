import { NamedIcon } from "./NamedIcon";

interface PopupButtonProps {
  iconName?: string,
  icon?: React.ReactNode,
  text: string,
  onClick: () => void,
}

export function PopupButton({ iconName, icon, text, onClick }: PopupButtonProps) {
  let iconElement = icon

  if (!icon && iconName) {
    iconElement = <NamedIcon iconName={iconName as any} size="20px" className="text-gray-400" />
  }

  if (!iconElement) {
    iconElement = <div className="w-[20px] h-[20px]"></div>
  }

  return (
    <button className="flex gap-4 items-center w-full hover:bg-gray-200 px-4 py-2" onClick={() => onClick()}>
      {iconElement}
      <p className="text-sm text-left">{text}</p>
    </button>
  )
}
