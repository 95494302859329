import { AiOutlineLink } from 'react-icons/ai';
import { Textfit } from 'react-textfit';
import config from '../../config';

interface CellCommentProps {
  imageUrl?: string,
  text: string,
  url?: string,
  onClickComment?: () => void,
}

export function CellComment({ imageUrl, text, url, onClickComment }: CellCommentProps) {
  return (
    <div className="flex flex-col gap-2 p-2 w-full h-full">
      <div className="flex flex-1 gap-2 cursor-pointer overflow-hidden" onClick={() => onClickComment && onClickComment()}>
        {imageUrl
          && <div className="flex-1">
            <img src={`${config.backendUrl}${imageUrl}`} className="h-full object-cover rounded-lg" />
          </div>
        }
        <div className="flex-[2] flex">
          <Textfit max={16} className="leading-none flex-1">{text}</Textfit>
        </div>
      </div>
      {url
        && <div className="flex items-center gap-1">
          <AiOutlineLink size="16" className="text-gray-400" />
          <a href={url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`} target="_blank" rel="noreferrer" className="text-xs text-gray-400 whitespace-nowrap overflow-hidden text-ellipsis">{url}</a>
        </div>
      }
    </div>
  );
}
