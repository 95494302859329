import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { CellCoords } from "share2flow-board";

export interface ActionPlanState {
  dialogOpen: boolean,
  currentCellCoords?: CellCoords,
}

const initialState: ActionPlanState = {
  dialogOpen: false,
  currentCellCoords: undefined,
}

export const actionPlanSlice = createSlice({
  name: 'actionPlan',
  initialState,
  reducers: {
    showDialog: (state, action: PayloadAction<CellCoords>) => {
      state.dialogOpen = true
      state.currentCellCoords = action.payload
    },
    hideDialog: (state) => {
      state.dialogOpen = false
      state.currentCellCoords = undefined
    },
  },
})

export const { showDialog, hideDialog } = actionPlanSlice.actions

export default actionPlanSlice.reducer
