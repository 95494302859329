import { ChangePasswordRequest, GetAuthAppUriResponse, GetNotificationSettingsResponse, GetOTPSettingsResponse, GetUserSettingsResponse, NotificationSetting, RequestVerificationRequest, SetAuthApp2FARequest, SetNotificationSettingRequest, SetPhone2FAEnabledRequest, SetPhoneNumberRequest, SetUserAvatarRequest, UserSettings } from "share2flow-typedefs"
import config from "../../config"

// User

export async function getUserSettings(token: string): Promise<UserSettings> {
  const res = await fetch(`${config.apiUrl}/userSettings`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
  if (res.status === 404) {
    return {
      name: '',
      surname: '',
      streetNameNr: '',
      zipCode: '',
      city: '',
    }
  } else if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
  const resJson: GetUserSettingsResponse = await res.json()
  return resJson.userSettings
}

export async function setUserSettings(token: string, userSettings: UserSettings): Promise<void> {
  const res = await fetch(`${config.apiUrl}/userSettings`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(userSettings),
  })
  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
}

export type PasswordChangeType = {
  oldPassword: string,
  newPassword: string,
  confirmNewPassword: string,
}

export async function changeUserPassword(token: string, passwordChange: PasswordChangeType): Promise<void> {
  if (passwordChange.newPassword !== passwordChange.confirmNewPassword) {
    throw new Error("New Password and Confirm New Password do not match!")
  }

  const request: ChangePasswordRequest = {
    oldPassword: passwordChange.oldPassword,
    newPassword: passwordChange.newPassword,
  }

  const res = await fetch(`${config.apiUrl}/auth/changePassword`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(request),
  })
  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
}

// SMS OTP

export async function getOtpSettings(token: string): Promise<GetOTPSettingsResponse> {
  const res = await fetch(`${config.apiUrl}/otp`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
  return res.json()
}

export async function requestPhoneVerification(token: string, phoneNumber: string): Promise<void> {
  const request: RequestVerificationRequest = {
    phoneNumber
  }

  const res = await fetch(`${config.apiUrl}/otp/requestVerification`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(request),
  })
  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
}

export async function validatePhoneNumber(token: string, phoneNumber: string, otp: string): Promise<void> {
  const request: SetPhoneNumberRequest = {
    phoneNumber,
    otp
  }

  const res = await fetch(`${config.apiUrl}/otp/phoneNumber`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(request),
  })
  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
}

export async function setPhone2FAEnabled(token: string, enabled: boolean): Promise<void> {
  const request: SetPhone2FAEnabledRequest = {
    enabled,
  }

  const res = await fetch(`${config.apiUrl}/otp/phone2fa`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(request),
  })
  if (res.status === 404) {
    throw new Error('You must verify a phone number first')
  }
  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
}

// Auth App OTP

export async function getAuthAppUri(token: string): Promise<GetAuthAppUriResponse> {
  const res = await fetch(`${config.apiUrl}/otp/authAppUri`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
  return await res.json()
}

export async function setAuthApp2FAEnabled(token: string, enabled: boolean, secret?: string, otp?: string): Promise<void> {
  const request: SetAuthApp2FARequest = {
    enabled,
    secret,
    otp,
  }

  const res = await fetch(`${config.apiUrl}/otp/authApp2fa`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(request),
  })
  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
}

// Notifications

export async function getNotificationSettings(token: string): Promise<{[key: string]: boolean}> {
  const res = await fetch(`${config.apiUrl}/notifications/settings`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
  const bodyJson: GetNotificationSettingsResponse = await res.json()
  const notificationSettingsObj: {[key: string]: boolean} = {}
  for (const notificationSetting of bodyJson.notificationSettings) {
    notificationSettingsObj[notificationSetting.notificationType] = notificationSetting.enabled
  }
  return notificationSettingsObj
}

export async function setNotificationSetting(token: string, setting: NotificationSetting): Promise<void> {
  const request: SetNotificationSettingRequest = {
    enabled: setting.enabled,
  }

  const res = await fetch(`${config.apiUrl}/notifications/settings/${setting.notificationType}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(request),
  })
  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
}

// Handle

export async function setUserAvatarUrl(token: string, avatarUrl: string): Promise<void> {
  const request: SetUserAvatarRequest = {
    avatarUrl,
  }

  const res = await fetch(`${config.apiUrl}/userSettings/avatar`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(request),
  })
  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
}

export async function setLanguage(token: string, language: string): Promise<void> {
  const res = await fetch(`${config.apiUrl}/language/${language}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
}

export async function listLogins(token: string): Promise<any[]> {
  const res = await fetch(`${config.apiUrl}/auth/logins`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
  return await res.json()
}

export async function deleteLogin(token: string, id: string): Promise<void> {
  await fetch(`${config.apiUrl}/auth/logins/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
}
