import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'

export enum NotificationMessageType {
  Error,
  Warning,
  Success,
  Info,
}

export interface NotificationMessage {
  id: string,
  type: NotificationMessageType,
  title: string,
  body?: string,
  delayMs: number,
  show: boolean,
}

export interface NewNotificationMessage {
  type: NotificationMessageType,
  title: string,
  body?: string,
  delayMs?: number,
}

export interface NotificationMessagesState {
  notificationMessages: NotificationMessage[],
}

const initialState: NotificationMessagesState = {
  notificationMessages: [],
}

export const actionPlanSlice = createSlice({
  name: 'actionPlan',
  initialState,
  reducers: {
    pushNotificationMessage: (state, action: PayloadAction<NotificationMessage>) => {
      state.notificationMessages.push(action.payload)
    },
    hideNotificationMessage: (state, action: PayloadAction<string>) => {
      state.notificationMessages = state.notificationMessages.map((n) => n.id === action.payload ? {...n, show: false} : n)
    },
    removeNotificationMessage: (state, action: PayloadAction<string>) => {
      state.notificationMessages = state.notificationMessages.filter((n) => n.id !== action.payload)
    },
  },
})

export const showNotificationMessage = createAsyncThunk(
  'notificationMessages/showNotificationMessage',
  async (data: NewNotificationMessage, thunkApi) => {
    const notificationMessage = {
      id: uuidv4(),
      show: true,
      ...data,
      delayMs: 5000,
    }
    thunkApi.dispatch(pushNotificationMessage(notificationMessage))
    await new Promise(resolve => setTimeout(resolve, notificationMessage.delayMs))
    thunkApi.dispatch(hideNotificationMessage(notificationMessage.id))
  }
)

export const hideAndRemoveNotificationMessage = createAsyncThunk(
  'notificationMessages/hideAndRemoveNotificationMessage',
  async (data: string, thunkApi) => {
    thunkApi.dispatch(hideNotificationMessage(data))
    await new Promise(resolve => setTimeout(resolve, 200))
    thunkApi.dispatch(removeNotificationMessage(data))
  }
)

export const { pushNotificationMessage, hideNotificationMessage, removeNotificationMessage } = actionPlanSlice.actions

export default actionPlanSlice.reducer
