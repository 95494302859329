import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import config from '../../config'
import { RootState } from '../../store'
import { ErrorResponse, LoginRequest, LoginResponse, SignUpRequest, SignUpResponse, User } from 'share2flow-typedefs'

const token = sessionStorage.getItem('token') || localStorage.getItem('token') || undefined

export type SignInUserInitial = {
  type: 'initial',
}
export type SignInUserInProgress = {
  type: 'in-progress',
}
export type SignInUserSuccess = {
  type: 'success',
  token: string,
  useSession: boolean,
}
export type SignInUserFailure = {
  type: 'failure',
  error: string,
}
export type SignInUserOtpRequired = {
  type: 'otp-required',
}
export type SignInUserOtpInProgress = {
  type: 'otp-in-progress',
}
export type SignInUserOtpFailure = {
  type: 'otp-failure',
}
export type SignInUserState = SignInUserInitial | SignInUserInProgress | SignInUserSuccess | SignInUserFailure | SignInUserOtpRequired | SignInUserOtpInProgress | SignInUserOtpFailure

export type SignUpUserInitial = {
  type: 'initial',
}
export type SignUpUserInProgress = {
  type: 'in-progress',
}
export type SignUpUserSuccess = {
  type: 'success',
}
export type SignUpUserFailure = {
  type: 'failure',
  error: string,
}
export type SignUpUserOtpRequired = {
  type: 'otp-required',
}
export type SignUpUserOtpInProgress = {
  type: 'otp-in-progress',
}
export type SignUpUserOtpFailure = {
  type: 'otp-failure',
}
export type SignUpUserState = SignUpUserInitial | SignUpUserInProgress | SignUpUserSuccess | SignUpUserFailure | SignUpUserOtpRequired | SignUpUserOtpRequired | SignUpUserOtpInProgress | SignUpUserOtpFailure

export interface AuthenticationState {
  token?: string,
  user?: User,
  signInState: SignInUserState,
  signUpState: SignUpUserState,
}

const initialState: AuthenticationState = {
  token: token,
  user: undefined,
  signInState: { type: 'initial' },
  signUpState: { type: 'initial' },
}

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setSignInUserState(state, action: PayloadAction<SignInUserState>) {
      state.signInState = action.payload
      switch (action.payload.type) {
        case 'success':
          state.token = action.payload.token
          state.user = undefined
          if (action.payload.useSession) {
            sessionStorage.setItem('token', action.payload.token)
          } else {
            localStorage.setItem('token', action.payload.token)
          }
          break
        default:
          state.token = undefined
          state.user = undefined
          sessionStorage.removeItem('token')
          localStorage.removeItem('token')
      }
    },
    setSignUpUserState(state, action: PayloadAction<SignUpUserState>) {
      state.signUpState = action.payload
    },
    setUser(state, action: PayloadAction<User>) {
      state.user = action.payload
    },
    clearUser(state) {
      state.user = undefined
    },
    signOutUser(state) {
      state.token = undefined
      state.user = undefined
      //state.signInState = { type: 'initial' }
      //state.signUpState = { type: 'initial' }
      sessionStorage.removeItem('token')
      localStorage.removeItem('token')
    }
  },
})

export const signInUser = createAsyncThunk(
  'authentication/signInUser',
  async (data: LoginRequest, thunkApi) => {
    const stateType = (thunkApi.getState() as RootState).authentication.signInState.type
    thunkApi.dispatch(setSignInUserState({
      type: (stateType === 'otp-required' || stateType === 'otp-failure') ? 'otp-in-progress' : 'in-progress'
    }))
    const res = await fetch(`${config.apiUrl}/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    const resJson: LoginResponse & ErrorResponse = await res.json()
    if (res.status >= 400) {
      thunkApi.dispatch(setSignInUserState({
        type: (resJson?.state as any) || 'failure',
        error: resJson?.error || 'There was an error. Please try again later',
      }))
      return
    }
    if (resJson.state === 'success') {
      thunkApi.dispatch(setSignInUserState({
        type: 'success',
        token: resJson.token!,
        useSession: !data.staySignedIn,
      }))
    } else if (resJson.state === 'otp-required') {
      thunkApi.dispatch(setSignInUserState({
        type: 'otp-required',
      }))
    }
  }
)

export const signUpUser = createAsyncThunk(
  'authentication/signUpUser',
  async (data: SignUpRequest, thunkApi) => {
    const stateType = (thunkApi.getState() as RootState).authentication.signUpState.type
    thunkApi.dispatch(setSignUpUserState({
      type: (stateType === 'otp-required' || stateType === 'otp-failure') ? 'otp-in-progress' : 'in-progress'
    }))
    const res = await fetch(`${config.apiUrl}/auth/signUp`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    if (res.status >= 400) {
      const resJson: SignUpResponse & ErrorResponse = await res.json()
      thunkApi.dispatch(setSignUpUserState({
        type: (resJson?.state as any) || 'failure',
        error: resJson?.error || 'There was an error. Please try again later',
      }))
      return
    }
    thunkApi.dispatch(setSignUpUserState({
      type: 'success',
    }))
    thunkApi.dispatch(signInUser({
      email: data.email,
      password: data.password,
      otp: '',
      staySignedIn: false,
    }))
  }
)

export const reloadUser = createAsyncThunk(
  'authentication/reloadUser',
  async (_data: void, thunkApi) => {
    const res = await fetch(`${config.apiUrl}/auth/user`, {
      headers: {
        'Authorization': `Bearer ${(thunkApi.getState() as RootState).authentication.token}`,
      },
    })
    if (res.status === 401) {
      thunkApi.dispatch(signOutUser())
      return
    }
    const resJson: User = await res.json()
    if (res.status >= 400) {
      thunkApi.dispatch(clearUser())
      return
    }
    thunkApi.dispatch(setUser(resJson))
  }
)

export const { setSignInUserState, setSignUpUserState, setUser, clearUser, signOutUser } = authenticationSlice.actions

export default authenticationSlice.reducer
