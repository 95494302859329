import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { GetOTPSettingsResponse } from "share2flow-typedefs"
import { LoadingState } from "../../components/controls/LoadingState"
import { AppDispatch, RootState } from "../../store"
import { NotificationMessageType, showNotificationMessage } from "../notificationMessages/notificationMessagesSlice"
import { getOtpSettings, setPhone2FAEnabled, setAuthApp2FAEnabled, listLogins, deleteLogin } from "./api"
import { AuthAppDialog } from "./AuthAppDialog"
import { PhoneVerificationDialog } from "./PhoneVerificationDialog"
import { UAParser } from "ua-parser-js";
import { formatDateTime } from "../../utils"
import { signOutUser } from "../authentication/authenticationSlice"
import { useNavigate } from "react-router-dom"

export function SecuritySettings() {
  const { t, i18n } = useTranslation()
  const token = useSelector((state: RootState) => state.authentication.token)
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const [showPhoneVerificationDialog, setShowPhoneVerificationDialog] = useState<boolean>(false)
  const [showAuthAppDialog, setShowAuthAppDialog] = useState<boolean>(false)

  const [otpSettings, setOtpSettings] = useState<GetOTPSettingsResponse>()
  // TODO while otpSettings is null show loading spinner

  const [logins, setLogins] = useState<any[]>([])

  const reloadSettings = async () => {
    const res = await getOtpSettings(token!)
    setOtpSettings(res)
  }

  useEffect(() => {
    reloadSettings()
  }, [])

  const setSms2FA = async (enabled: boolean) => {
    if (!otpSettings?.phoneNumber) {
      setShowPhoneVerificationDialog(true)
      return
    }

    try {
      await setPhone2FAEnabled(token!, enabled)
      dispatch(showNotificationMessage({
        type: NotificationMessageType.Success,
        title: `Two Factor Authentication ${enabled ? 'Enabled' : 'Disabled'}`,
      }))
    } catch (e: any) {
      dispatch(showNotificationMessage({
        type: NotificationMessageType.Error,
        title: 'Error Changing 2FA',
        body: e.toString(),
      }))
    }
    await reloadSettings()
  }

  const setApp2FA = async (enabled: boolean) => {
    if (!enabled) {
      await setAuthApp2FAEnabled(token!, false)
      await reloadSettings()
    } else {
      setShowAuthAppDialog(true)
    }
  }

  const closePhoneVerificationDialog = () => {
    setShowPhoneVerificationDialog(false)
    reloadSettings()
  }

  const closeAuthAppDialog = () => {
    setShowAuthAppDialog(false)
    reloadSettings()
  }

  const fetchLogins = async () => {
    try {
      const loginsResult = await listLogins(token!)
      const parser = new UAParser()
      for (const login of loginsResult) {
        const ua = parser.setUA(login.deviceName).getResult()
        login.deviceType = ua.device.type === 'mobile' ? 'Mobile' : 'Desktop'
        login.browserName = ua.browser.name
        login.osName = ua.os.name
        login.icon = login.deviceType === 'Mobile' ? '/img/settings/mobile_icon.png' : '/img/settings/desktop_icon.png'
      }
      setLogins(loginsResult)
    } catch (e: any) {
      dispatch(showNotificationMessage({
        type: NotificationMessageType.Error,
        title: 'Error fetching logins',
        body: e.toString(),
      }))
    }
  }

  useEffect(() => {
    fetchLogins()
  }, [])

  const deleteDeviceLogin = async (loginId: string) => {
    try {
      await deleteLogin(token!, loginId)
    } catch (e: any) {
      dispatch(showNotificationMessage({
        type: NotificationMessageType.Error,
        title: 'Error deleting login',
        body: e.toString(),
      }))
    }
    try {
      const loginsResult = await listLogins(token!)
      const parser = new UAParser()
      for (const login of loginsResult) {
        const ua = parser.setUA(login.deviceName).getResult()
        login.deviceType = ua.device.type === 'mobile' ? 'Mobile' : 'Desktop'
        login.browserName = ua.browser.name
        login.osName = ua.os.name
        login.icon = login.deviceType === 'Mobile' ? '/img/settings/mobile_icon.png' : '/img/settings/desktop_icon.png'
      }
      setLogins(loginsResult)
    } catch (e: any) {
      dispatch(signOutUser())
      navigate('/login')
    }
  }

  return (
    <main className="main-section">
      <div className="container">
        <div className="inner_container">
          {/* notifications form */}
          <div className="pb-5 border-bottom">
            <h3 className="fs_lg c_gray mb-4 fw_medium">
              {t('Security')}
            </h3>
            <LoadingState isLoading={!otpSettings}>
              <>
                <div className="row align-items-xl-center mb-4 gy-2 pb-3">
                  <div className="col-sm-12 col-md-3">
                    <h4 className="fs_lg fw_medium mb-0">
                      {t('Phone Verification')}
                    </h4>
                  </div>
                  <div className="col-sm-8 col-md-6">
                    <p className="fs_lg mb-0">
                      {otpSettings?.phoneNumber
                        ? t('Your phone is verified, click edit to change phone number')
                        : t('Your phone is not verified, click edit to set phone number')
                      }
                    </p>
                  </div>
                  <div className="col-sm-4 col-md-3">
                    <div className="text-end">
                      <button onClick={() => setShowPhoneVerificationDialog(true)} className="btn btn_def btn_primary">
                        {t('Edit')}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row align-items-xl-center mb-4 gy-2">
                  <div className="col-sm-12 col-md-3">
                    <h4 className="fs_lg fw_medium mb-0">
                      {t('Authentictor App 2FA')}
                    </h4>
                  </div>
                  <div className="col-sm-8 col-md-6">
                    <p className="fs_lg mb-0">
                      {t('To help keep your account secure we will ask you to confirm a code using Google Authenticator or Microsoft Authenticator')}
                    </p>
                  </div>
                  <div className="col-sm-4 col-md-3">
                    <div className="">
                      <div className="form-check form-switch lg_switch security_switch ms-auto">
                        <label className="form-check-label">
                          <input className="form-check-input me-0" type="checkbox" role="switch" checked={!!otpSettings?.authApp.enabled} onChange={(e) => setApp2FA(e.target.checked)} />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row align-items-xl-center mb-4 gy-2">
                  <div className="col-sm-12 col-md-3">
                    <h4 className="fs_lg fw_medium mb-0">
                      {t('SMS 2FA')}
                    </h4>
                  </div>
                  <div className="col-sm-8 col-md-6">
                    <p className="fs_lg mb-0">
                      {t('To help keep your account secure we will ask you to confirm a code we will send on your mobile phone')}
                    </p>
                  </div>
                  <div className="col-sm-4 col-md-3">
                    <div className="">
                      <div className="form-check form-switch lg_switch security_switch ms-auto">
                        <label className="form-check-label">
                          <input className="form-check-input me-0" type="checkbox" role="switch" checked={!!otpSettings?.phoneNumber?.mfaEnabled} onChange={(e) => setSms2FA(e.target.checked)} />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </LoadingState>
          </div>

          {/* Connected devices form */}
          <div className="py-5">
            <h3 className="fs_lg c_gray mb-4 fw_medium">
              {t('Connected devices')}
            </h3>
            {logins?.map((login) => (
              <div key={login.id} className="row align-items-xl-center mb-4 gy-2 pb-3">
                <div className="col-sm-8 col-md-9">
                  <div className="d-flex align-items-center">
                    <div className="pe-3">
                      <img className="device_icon" src={login.icon} alt={login.deviceType} />
                    </div>
                    <div>
                      <h4 className="fs_lg fw_medium mb-0">
                        {login.browserName} {t('on')} {login.osName}
                      </h4>
                      <p className="c_gray mb-0">
                        {t('Last activity')}: {formatDateTime(i18n.language, new Date(login.lastUsedAt))}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 col-md-3">
                  <div className="text-end">
                    <a className="btn btn_def btn_primary cursor-pointer" onClick={() => deleteDeviceLogin(login.id)}>
                      {t('Sign out')}
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <PhoneVerificationDialog open={showPhoneVerificationDialog} onClose={() => closePhoneVerificationDialog()} />
      <AuthAppDialog open={showAuthAppDialog} onClose={() => closeAuthAppDialog()} />
    </main>
  )
}
