import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Reference } from "share2flow-board";
import { deleteReference, replaceReference } from "../../rootState";
import { AppDispatch } from "../../store";
import { InputDialog, InputSpec } from "../controls/InputDialog";
import { NamedIcons } from "../controls/NamedIcon";
import { PopupButton } from "../controls/PopupButton";
import { PopupMenu } from "../controls/PopupMenu";

export interface FlowsArrowControlsProps {
  clickedArrow: Reference | undefined,
  clearClickedArrow: () => void,
}

export function FlowsArrowControls({clickedArrow, clearClickedArrow}: FlowsArrowControlsProps) {
  const { t } = useTranslation()
  const [editingArrow, setEditingArrow] = useState<Reference | undefined>(undefined)
  const [showArrowLabelDialog, setShowArrowLabelDialog] = useState<boolean>(false)
  const dispatch = useDispatch<AppDispatch>()

  const setClickedArrowColor = (color: string) => {
    if (!clickedArrow) {
      return
    }
    dispatch(replaceReference(clickedArrow, {...clickedArrow, color}))
    clearClickedArrow()
  }

  const deleteClickedArrow = () => {
    if (!clickedArrow) {
      return
    }
    dispatch(deleteReference(clickedArrow))
    clearClickedArrow()
  }

  const arrowLabelDialogInputSpecs: InputSpec[] = [
    {
      id: 'label',
      name: t('Label'),
      type: 'text',
      initialValue: editingArrow?.label || '',
    },
  ]

  const changeLabelClicked = () => {
    setEditingArrow(clickedArrow)
    clearClickedArrow()
    setShowArrowLabelDialog(true)
  }

  const onCloseArrowLabelDialog = (accepted: boolean, values?: {[id: string]: string | number | boolean}) => {
    setShowArrowLabelDialog(false)
    if (accepted && editingArrow) {
      dispatch(replaceReference(editingArrow, {...editingArrow, label: values!.label as string}))
      setEditingArrow(undefined)
      clearClickedArrow()
    }
  }

  return (
    <div>
      <PopupMenu isVisible={!!clickedArrow} onDismiss={() => clearClickedArrow()} width={200}>
        <PopupButton
          key="color:red"
          icon={<div className="w-[20px] h-[20px] rounded-md" style={{ backgroundColor: '#ef4444' }}></div>}
          text={t('Red')}
          onClick={() => setClickedArrowColor('#ef4444')}
        />
        <PopupButton
          key="color:green"
          icon={<div className="w-[20px] h-[20px] rounded-md" style={{ backgroundColor: '#22c55e' }}></div>}
          text={t('Green')}
          onClick={() => setClickedArrowColor('#22c55e')}
        />
        <PopupButton
          key="color:black"
          icon={<div className="w-[20px] h-[20px] rounded-md" style={{ backgroundColor: '#404040' }}></div>}
          text={t('Black')}
          onClick={() => setClickedArrowColor('#404040')}
        />
        <PopupButton
          key="label"
          iconName={NamedIcons.TAG}
          text={t('Set Label')}
          onClick={() => changeLabelClicked()}
        />
        <PopupButton
          key="delete"
          iconName={NamedIcons.TRASH}
          text={t('Delete')}
          onClick={() => deleteClickedArrow()}
        />
      </PopupMenu>
      <InputDialog title={t('Arrow Label')} inputSpecs={arrowLabelDialogInputSpecs} open={showArrowLabelDialog} onClose={onCloseArrowLabelDialog} />
    </div>
  )
}

export function renderFlowsArrowControls(clickedArrow: Reference | undefined, clearClickedArrow: () => void) {
  return (
    <FlowsArrowControls
      clickedArrow={clickedArrow}
      clearClickedArrow={clearClickedArrow}
    />
  )
}
