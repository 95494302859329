import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useLockCell } from "../../features/locking";
import { CellIcon } from "../cells/CellIcon";
import { CellText } from "../cells/CellText";
import { InputDialog, InputSpec } from "../controls/InputDialog";
import { CellContentsProps } from "./CellContents";
import { AppDispatch } from "../../store";
import { useDispatch } from "react-redux";
import { setCell, useCell } from "../../rootState";
import { useTranslation } from 'react-i18next'
import { numberToLetter } from "../../utils";
import config from "../../config";

export function ProblemsAndDifficultiesCell({ coords }: CellContentsProps) {
  const { t } = useTranslation()
  const data = useCell(coords)
  const [isEditing, setIsEditing] = useState(false)
  const { lock, unlock } = useLockCell(coords)
  const dispatch = useDispatch<AppDispatch>()

  const onClick = async () => {
    const lockAcquired = await lock()
    if (lockAcquired) {
      setIsEditing(true)
    }
  }

  const renderEditor = () => {
    const inputSpecs: InputSpec[] = coords.column === 0 ? [
      {
        id: 'name',
        name: t('Name'),
        placeholder: t('Enter the name of the problem or difficulty here'),
        type: 'text',
        initialValue: data?.name || '',
        maxLength: config.maxHeaderLength,
      },
    ] : [
      {
        id: 'text',
        name: t('Text'),
        type: 'textarea',
        initialValue: data?.text || '',
      },
    ]

    const onClose = async (accepted: boolean, values?: {[id: string]: string | number | boolean}) => {
      if (accepted) {
        dispatch(setCell(coords, {...data, ...values, checkmark: coords.column === 0 ? undefined : 'unchecked'}))
      }
      await unlock()
      setIsEditing(false)
    }

    return <InputDialog title={t('Problem or Difficulty')} inputSpecs={inputSpecs} open={isEditing} onClose={onClose} />
  }

  const renderContents = () => {
    if (coords.column === 0) {
      return (
        <>
          <div className="h-full w-full flex items-center justify-center cursor-pointer" onClick={() => onClick()}>
            <CellText centered={true}>
              {data?.name ?
                <span>{t('Problems & Difficulties')} <span className="font-bold">{data.name}</span></span> :
                <span>{t('Problems & Difficulties')} <span className="font-bold">{numberToLetter(coords.row)}</span></span>
              }
            </CellText>
          </div>
        </>
      )
    }

    if (!data || Object.keys(data).length === 0) {
      return (
        <div className="h-full w-full flex items-center justify-center cursor-pointer" onClick={() => onClick()}>
          <CellIcon icon={AiOutlinePlus} />
        </div>
      )
    }

    return (
      <div className={`w-full h-full cursor-pointer ${data.checkmark ? 'pr-4' : ''} ${data.checkmark === 'checked' && 'bg-success-100'}`} onClick={() => onClick()}>
        <CellText>{data.text}</CellText>
      </div>
    )
  }

  return (
    <>
      {renderContents()}
      {renderEditor()}
    </>
  )
}
