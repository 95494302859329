import { Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { HiXMark, HiOutlineCheckCircle, HiOutlineXCircle, HiOutlineExclamationCircle, HiOutlineInformationCircle } from "react-icons/hi2"
import { useDispatch, useSelector } from "react-redux";
import { hideAndRemoveNotificationMessage, NotificationMessageType } from "../../features/notificationMessages/notificationMessagesSlice";
import { AppDispatch, RootState } from "../../store";

export function NotificationMessages() {
  const { t } = useTranslation()
  const notificationMessages = useSelector((state: RootState) => state.notificationMessages.notificationMessages)
  const dispatch = useDispatch<AppDispatch>()

  const hideMessage = (id: string) => {
    dispatch(hideAndRemoveNotificationMessage(id))
  }

  return (
    <>
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-[2000]"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {notificationMessages.map((notificationMessage) =>
            <Transition
              key={notificationMessage.id}
              show={notificationMessage.show}
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      { notificationMessage.type === NotificationMessageType.Success &&
                        <HiOutlineCheckCircle className="h-6 w-6 text-green-400" aria-hidden="true" />}
                      { notificationMessage.type === NotificationMessageType.Error &&
                        <HiOutlineXCircle className="h-6 w-6 text-red-400" aria-hidden="true" />}
                      { notificationMessage.type === NotificationMessageType.Warning &&
                        <HiOutlineExclamationCircle className="h-6 w-6 text-yellow-400" aria-hidden="true" />}
                      { notificationMessage.type === NotificationMessageType.Info &&
                        <HiOutlineInformationCircle className="h-6 w-6 text-blue-400" aria-hidden="true" />}
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className="text-sm font-medium text-gray-900">{ notificationMessage.title }</p>
                      <p className="mt-1 text-sm text-gray-500">{ notificationMessage.body }</p>
                    </div>
                    <div className="ml-4 flex flex-shrink-0">
                      <button
                        type="button"
                        className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => {
                          hideMessage(notificationMessage.id!)
                        }}
                      >
                        <span className="sr-only">{t('Close')}</span>
                        <HiXMark className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
          )}
        </div>
      </div>
    </>
  )
}
