import { configureStore } from '@reduxjs/toolkit'
import { createSocketMiddleware } from './socketState'
import SocketReducer from './socketState'
import BoardStateReducer from './rootState'
import actionPlanReducer from './features/actionPlan/actionPlanSlice'
import arrowDrawingReducer from './features/arrowDrawing/arrowDrawingSlice'
import AuthenticationReducer from './features/authentication/authenticationSlice'
import NotificationMessagesReducer from './features/notificationMessages/notificationMessagesSlice'
import UserCursorsReducer from './features/userCursors/userCursorsSlice'
import AuthorizationReducer from './features/authorization/authorizationSlice'
import SharingReducer from './features/sharing/sharingSlice'
import ZoomReducer from './features/zoom/zoomSlice'
import ContextMenuReducer from './features/contextMenu/contextMenuSlice'
import ClipboardReducer from './features/clipboard/clipboardSlice'

export const store = configureStore({
  reducer: {
    actionPlan: actionPlanReducer,
    arrowDrawing: arrowDrawingReducer,
    authentication: AuthenticationReducer,
    notificationMessages: NotificationMessagesReducer,
    userCursors: UserCursorsReducer,
    socket: SocketReducer,
    boardState: BoardStateReducer,
    authorization: AuthorizationReducer,
    sharing: SharingReducer,
    zoom: ZoomReducer,
    contextMenu: ContextMenuReducer,
    clipboard: ClipboardReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .prepend(createSocketMiddleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
