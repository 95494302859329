import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useLockCell } from "../../features/locking";
import { CellText } from "../cells/CellText";
import { CellComment } from "../cells/CellComment";
import { CellIcon } from "../cells/CellIcon";
import { InputDialog, InputSpec } from "../controls/InputDialog";
import { CellContentsProps } from "./CellContents";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { setCell, useCell } from "../../rootState";
import { useTranslation } from 'react-i18next'
import { numberToLetter } from "../../utils";
import config from "../../config";

export function CommentCell({ coords }: CellContentsProps) {
  const { t } = useTranslation()
  const data = useCell(coords)
  const [isEditing, setIsEditing] = useState(false)
  const { lock, unlock } = useLockCell(coords)
  const dispatch = useDispatch<AppDispatch>()

  const tryEdit = async () => {
    const lockAcquired = await lock()
    if (lockAcquired) {
      setIsEditing(true)
    }
  }

  const renderContents = () => {
    if (coords.column === 0) {
      return (
        <>
          <div className="h-full w-full flex items-center justify-center cursor-pointer" onClick={() => tryEdit()}>
            <CellText centered={true}>
              {data?.name ?
                <span>{data.name}</span> :
                <span>{t('Comments')} <span className="font-bold">{numberToLetter(coords.row)}</span></span>
              }
            </CellText>
          </div>
        </>
      )
    }

    if (!data || Object.keys(data).length === 0) {
      return (
        <div className="h-full w-full flex items-center justify-center cursor-pointer" onClick={tryEdit}>
          <CellIcon icon={AiOutlinePlus} />
        </div>
      )
    }
    
    return (
      <CellComment imageUrl={data.imageUrl} text={data.text} url={data.url} onClickComment={tryEdit} />
    )
  }

  const renderEditor = () => {
    const inputSpecs: InputSpec[] = coords.column === 0 ? [
      {
        id: 'name',
        name: t('Name'),
        placeholder: t('Enter the name of the comment here'),
        type: 'text',
        initialValue: data?.name || '',
        maxLength: config.maxHeaderLength,
      },
    ] : [
      {
        id: 'text',
        name: t('Description'),
        placeholder: t('Enter your comments and notes here'),
        type: 'textarea',
        initialValue: data?.text || '',
      },
      {
        id: 'imageUrl',
        name: 'Image',
        type: 'image',
        initialValue: data?.imageUrl || '',
      },
      {
        id: 'url',
        name: 'URL',
        placeholder: t('Add the link/URL here'),
        type: 'text',
        initialValue: data?.url || '',
      },
    ]

    const onClose = async (accepted: boolean, values?: {[id: string]: string | number | boolean}) => {
      if (accepted) {
        dispatch(setCell(coords, {...data, ...values}))
      }
      setIsEditing(false)
      await unlock()
    }

    return <InputDialog title={t('Definition | Comments')} inputSpecs={inputSpecs} open={isEditing} onClose={onClose} />
  }

  return (
    <>
      {renderContents()}
      {renderEditor()}
    </>
  )
}
