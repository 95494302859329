import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Subscription, Plan } from "share2flow-typedefs";
import { AppDispatch, RootState } from "../../store";
import { NotificationMessageType, showNotificationMessage } from "../notificationMessages/notificationMessagesSlice";
import { allPlans, setSubscription } from "./api";

export interface PlanSummaryProps {
  subscription?: Subscription
  hasPaymentMethod: boolean
  onPlanChange: () => void
}

export function PlanSummary({ subscription, hasPaymentMethod, onPlanChange }: PlanSummaryProps) {
  const { t, i18n } = useTranslation()
  const token = useSelector((state: RootState) => state.authentication.token)
  const [plans, setPlans] = useState<Plan[]>([])

  useEffect(() => {
    const fetchPlans = async () => {
      const plans = await allPlans(token!)
      setPlans(plans.plans)
    }
    fetchPlans()
  }, [])

  const currentPlan = useMemo(() => {
    return plans.find(p => p.id === subscription?.planId)
  }, [plans, subscription])

  if (!subscription || !currentPlan) {
    return (
      <div className="shadow-sm rounded-3 overflow-hidden">
        <div className="bg_primary_light c_primary fs_lg fw_semiBold text-center p-3">
          {t('FREE')}
        </div>
        <div className="p-3 p-xxl-4">
          {hasPaymentMethod ? (
            <PlanChanger plans={plans} onPlanChange={onPlanChange} />
          ) : (
            <div>
              {t('Enter your billing information to select plan')}
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="shadow-sm rounded-3 overflow-hidden">
      <div className="bg_primary_light c_primary fs_lg fw_semiBold text-center p-3">
        {currentPlan.name}
      </div>
      <div className="p-3 p-xxl-4">
        <div className="text-center h2 fw_semiBold c_gray2 mb-3">
          {subscription.price?.replace('.', ',')} €
        </div>
        <div className="text-center rounded-2 bg_warning p-2 mb-3">
          {currentPlan.billingFrequency === 1 ? t('MONTHLY') : t('ANNUAL')} {t('SUBSCRIPTION')}
        </div>

        <div className="text-center rounded-2 p-2 mb-3 border border_primary">
          <div className="mb-1 c_primary_light">
            {t('Renewal Date')}
          </div>
          <div className="c_primary fw_semiBold">
            {(new Date(subscription.nextBillingDate)).toLocaleDateString(i18n.language, {year:"numeric", month:"long", day:"numeric"})}<br/>
          </div>
        </div>
        {/*
        <div className="text-center rounded-2 p-2 mb-4 border border_primary">
          <div className="mb-1 c_primary_light">
            # Licenses
          </div>
          <div className="c_primary fw_semiBold">
            1 User
          </div>
        </div>
        */}
        <PlanChanger subscription={subscription} plans={plans} onPlanChange={onPlanChange} />
      </div>
    </div>
  )
}

export interface PlanChangerProps {
  subscription?: Subscription
  plans: Plan[]
  onPlanChange: () => void
}

export function PlanChanger({ subscription, plans, onPlanChange }: PlanChangerProps) {
  const { t } = useTranslation()
  const token = useSelector((state: RootState) => state.authentication.token)
  const dispatch = useDispatch<AppDispatch>()
  const [selectedPlan, setSelectedPlan] = useState(subscription?.planId || 'free')

  const currentPlan = plans.find(p => p.id === subscription?.planId)
  const availablePlans = plans.filter(p => !subscription || p.billingFrequency === currentPlan?.billingFrequency)

  const changePlan = async () => {
    if (selectedPlan === 'free') {
      try {
        if (subscription) {
          await setSubscription(token!, undefined)
          dispatch(showNotificationMessage({
            type: NotificationMessageType.Success,
            title: t('Subscription cancelled!'),
          }))
        }
        onPlanChange()
      } catch (e: any) {
        dispatch(showNotificationMessage({
          type: NotificationMessageType.Error,
          title: t('Error cancelling subscription'),
          body: e.toString(),
        }))
      }
    } else {
      if (selectedPlan !== subscription?.planId) {
        try {
          await setSubscription(token!, selectedPlan)
          dispatch(showNotificationMessage({
            type: NotificationMessageType.Success,
            title: t('Plan changed!'),
          }))
        } catch (e: any) {
          dispatch(showNotificationMessage({
            type: NotificationMessageType.Error,
            title: t('Error changing plan'),
            body: e.toString(),
          }))
        }
        onPlanChange()
      }
    }
  }

  return (
    <div>
      <select className="form-select w-100 mb-3" value={selectedPlan} onChange={e => setSelectedPlan(e.target.value)}>
        <option value="free">{t('Free')}</option>
        {availablePlans.map(plan => (
          <option key={plan.id} value={plan.id}>{plan.name} - {plan.price.replace('.', ',')} €</option>
        ))}
      </select>
      <button onClick={changePlan} className="btn btn_def btn_primary w-100">
        {t('CHANGE PLAN')}
      </button>
    </div>
  )
}
