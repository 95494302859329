import { createSlice } from '@reduxjs/toolkit'

export interface SharingState {
  dialogOpen: boolean,
}

const initialState: SharingState = {
  dialogOpen: false,
}

export const sharingSlice = createSlice({
  name: 'sharing',
  initialState,
  reducers: {
    showDialog: (state) => {
      state.dialogOpen = true
    },
    hideDialog: (state) => {
      state.dialogOpen = false
    },
  },
})

export const { showDialog, hideDialog } = sharingSlice.actions

export default sharingSlice.reducer
