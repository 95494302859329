import { useTranslation } from "react-i18next";
import { NavLink, Outlet } from "react-router-dom";

export function Management() {
  const { t } = useTranslation()

  return (
    <>
      <div className="bootstrap">
        <div className="page-header pt-3 bg_light">
          <div className="container">
            <h1 className="page-title">
              {t('Management')}
            </h1>
            <ul className="page-header-nav list-unstyled ps-0 fs_lg">
              <li className="page-header-navItem">
                <NavLink className={({ isActive }) => `page-header-navLink ${isActive ? 'active' : ''}`} end to="/management/users">{t('Users')}</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Outlet />
    </>
  )
}
