import { CollapsedGroupsResponse, CreateBoardResponse, SetCollapsedGroupsRequest } from "share2flow-typedefs"
import config from "../../config"

export async function getCollapsedGroupIds(token: string, boardId: string): Promise<string[]> {
  const res = await fetch(`${config.apiUrl}/groups/${boardId}/collapsed`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
  const response: CollapsedGroupsResponse = await res.json()
  return response.groupIds
}

export async function setCollapsedGroupIds(token: string, boardId: string, groupIds: string[]): Promise<CreateBoardResponse> {
  const request: SetCollapsedGroupsRequest = { groupIds }

  const res = await fetch(`${config.apiUrl}/groups/${boardId}/collapsed`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(request),
  })

  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
  return await res.json()
}
