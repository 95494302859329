import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { io } from "socket.io-client";
import config from "../../config";
import { RootState } from "../../store";

export function NotificationsIndicator() {
  const token = useSelector((state: RootState) => state.authentication.token)
  const [hasNotifications, setHasNotifications] = useState(false);

  useEffect(() => {
    const socket = io(`${config.backendUrl}/notifications`, {
      auth: {
        token,
      }
    })

    socket?.on("notification", (data) => {
      setHasNotifications(true);
    });

    socket?.on("clear", (data) => {
      setHasNotifications(false);
    });

    return () => {
      socket?.disconnect();
    }
  }, []);

  if (!hasNotifications) {
    return <></>;
  }

  return (
    <span className="header-notifDot"></span>
  );
}
