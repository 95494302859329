import { useForm, SubmitHandler } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { uploadFile } from "../../api"
import { LoadingState } from "../../components/controls/LoadingState"
import config from "../../config"
import { AppDispatch, RootState } from "../../store"
import { reloadUser } from "../authentication/authenticationSlice"
import { NotificationMessageType, showNotificationMessage } from "../notificationMessages/notificationMessagesSlice"
import { changeUserPassword, getUserSettings, PasswordChangeType, setLanguage, setUserAvatarUrl, setUserSettings } from "./api"
import { UserSettings as UserSettingsType } from 'share2flow-typedefs'
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"

function PersonalInformationForm() {
  const { t } = useTranslation()
  const token = useSelector((state: RootState) => state.authentication.token)
  const dispatch = useDispatch<AppDispatch>()

  const { register, handleSubmit, formState } = useForm<UserSettingsType>({
    defaultValues: async () => getUserSettings(token!)
  })
  const onSubmit: SubmitHandler<UserSettingsType> = async (data) => {
    try {
      await setUserSettings(token!, data)
      dispatch(showNotificationMessage({
        type: NotificationMessageType.Success,
        title: t('Settings saved!'),
      }))
    } catch (e: any) {
      dispatch(showNotificationMessage({
        type: NotificationMessageType.Error,
        title: t('Error'),
        body: e.message,
      }))
    }
  }

  return (
    <div className="py-4">
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <h3 className="fs_lg c_gray mb-4 fw_medium">
          {t('Personal Information')}
        </h3>
        <LoadingState isLoading={formState.isLoading}>
          <div className="row">
            <div className="col-lg-10 col-xl-8">
              <div className="row gy-2 mb-3 mb-xl-4 align-items-center">
                <div className="col-md-5 col-xl-4">
                  <div className="inp_label fs_lg fw_medium">
                    {t('Name')}
                  </div>
                </div>
                <div className="col-md-7  col-xl-8">
                  <input className="form-control fs_lg def_inp" type="text" {...register("name")} />
                </div>
              </div>
              <div className="row gy-2 mb-3 mb-xl-4 align-items-center">
                <div className="col-md-5 col-xl-4">
                  <div className="inp_label fs_lg fw_medium">
                    {t('Surname')}
                  </div>
                </div>
                <div className="col-md-7  col-xl-8">
                  <input className="form-control fs_lg def_inp" type="text" {...register("surname")} />
                </div>
              </div>
              <div className="row gy-2 mb-3 mb-xl-4 align-items-center">
                <div className="col-md-5 col-xl-4">
                  <div className="inp_label fs_lg fw_medium">
                    {t('Street + Nr.')}
                  </div>
                </div>
                <div className="col-md-7  col-xl-8">
                  <input className="form-control fs_lg def_inp" type="text" {...register("streetNameNr")} />
                </div>
              </div>
              <div className="row gy-2 mb-3 mb-xl-4 align-items-center">
                <div className="col-md-5 col-xl-4">
                  <div className="inp_label fs_lg fw_medium">
                    {t('Zip Code')}
                  </div>
                </div>
                <div className="col-md-7  col-xl-8">
                  <input className="form-control fs_lg def_inp" type="text" {...register("zipCode")} />
                </div>
              </div>
              <div className="row gy-2 mb-3 mb-xl-4 align-items-center">
                <div className="col-md-5 col-xl-4">
                  <div className="inp_label fs_lg fw_medium">
                    {t('City')}
                  </div>
                </div>
                <div className="col-md-7  col-xl-8">
                  <input className="form-control fs_lg def_inp" type="text" {...register("city")} />
                </div>
              </div>
              <div className="row gy-2 mb-3 mb-xl-4 align-items-center">
                <div className="col-12">
                  <button className="btn btn_def btn_primary" type="submit">
                    {t('Save Changes')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </LoadingState>
      </form>
    </div>
  )
}

function LanguageForm() {
  const { t } = useTranslation()
  const token = useSelector((state: RootState) => state.authentication.token)
  const currentLanguage = useSelector((state: RootState) => state.authentication.user?.language)
  const dispatch = useDispatch<AppDispatch>()

  const [selectedLanguage, setSelectedLanguage] = useState<string>()

  useEffect(() => {
    setSelectedLanguage(currentLanguage)
  }, [currentLanguage])

  const changeLanguage = async (newLanguage: string) => {
    try {
      await setLanguage(token!, newLanguage)
      setSelectedLanguage(newLanguage)
      dispatch(reloadUser())
      dispatch(showNotificationMessage({
        type: NotificationMessageType.Success,
        title: t('Language changed!'),
      }))
    } catch (e: any) {
      dispatch(showNotificationMessage({
        type: NotificationMessageType.Error,
        title: t('Error'),
        body: e.message,
      }))
    }
  }

  return (
    <div className="py-4">
      <h3 className="fs_lg c_gray mb-4 fw_medium">
        {t('Language Settings')}
      </h3>
      <div className="row">
        <div className="col-lg-10 col-xl-8">
          <div className="row gy-2 mb-3 mb-xl-4 align-items-center">
            <div className="col-md-5 col-xl-4">
              <div className="inp_label fs_lg fw_medium">
                {t('Language')}
              </div>
            </div>
            <div className="col-md-7  col-xl-8">
              <select className="form-control fs_lg def_inp" value={selectedLanguage} onChange={(e) => changeLanguage(e.target.value)}>
                <option value="en">English</option>
                <option value="de">Deutsch</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function PasswordForm() {
  const { t } = useTranslation()
  const token = useSelector((state: RootState) => state.authentication.token)
  const dispatch = useDispatch<AppDispatch>()

  const { register, handleSubmit, reset } = useForm<PasswordChangeType>()
  const onSubmit: SubmitHandler<PasswordChangeType> = async (data) => {
    try {
      await changeUserPassword(token!, data)
      reset()
      dispatch(showNotificationMessage({
        type: NotificationMessageType.Success,
        title: 'Password changed!',
      }))
    } catch (e: any) {
      dispatch(showNotificationMessage({
        type: NotificationMessageType.Error,
        title: 'Error',
        body: e.message,
      }))
    }
  }

  return (
    <div className="py-4">
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <h3 className="fs_lg c_gray mb-4 fw_medium">
          {t('Password Change')}
        </h3>
        <div className="row">
          <div className="col-lg-10 col-xl-8">
            <div className="row gy-2 mb-3 mb-xl-4 align-items-center">
              <div className="col-md-5 col-xl-4">
                <div className="inp_label fs_lg fw_medium">
                  {t('Current Password')}
                </div>
              </div>
              <div className="col-md-7  col-xl-8">
                <input className="form-control fs_lg def_inp" type="password" {...register("oldPassword", { required: true })} />
              </div>
            </div>
            <div className="row gy-2 mb-3 mb-xl-4 align-items-center">
              <div className="col-md-5 col-xl-4">
                <div className="inp_label fs_lg fw_medium">
                  {t('New Password')}
                </div>
              </div>
              <div className="col-md-7  col-xl-8">
                <input className="form-control fs_lg def_inp" type="password" {...register("newPassword", { required: true })} />
              </div>
            </div>
            <div className="row gy-2 mb-3 mb-xl-4 align-items-center">
              <div className="col-md-5 col-xl-4">
                <div className="inp_label fs_lg fw_medium">
                  {t('Confirm New Password')}
                </div>
              </div>
              <div className="col-md-7  col-xl-8">
                <input className="form-control fs_lg def_inp" type="password" {...register("confirmNewPassword", { required: true })} />
              </div>
            </div>
            <div className="row gy-2 mb-3 mb-xl-4 align-items-center">
              <div className="col-12">
                <button className="btn btn_def btn_outline_primary" type="submit">
                  {t('Change Password')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

function HandleSettings() {
  const user = useSelector((state: RootState) => state.authentication.user)
  const token = useSelector((state: RootState) => state.authentication.token)
  const dispatch = useDispatch<AppDispatch>()

  if (!user) {
    return (
      <LoadingState isLoading={true} />
    )
  }

  return (
    <div className="d-flex align-items-center user-infoArea">
      <div className="user-imgBox image-uploadBox">
        <input
          id="avatar-upload"
          type="file"
          accept="image/png, image/jpeg"
          className="hidden"
          onChange={async (event) => {
            const image = event.target.files?.[0]
            if (image) {
              const url = await uploadFile(image)
              await setUserAvatarUrl(token!, url)
              dispatch(reloadUser())
            }
          }}
        />
        <label htmlFor="avatar-upload" className="cursor-pointer">
          {user.avatarUrl
            ? <img className="user-img aspect-square object-cover" src={config.backendUrl + user.avatarUrl} />
            : <div className="user-img flex items-center justify-center rounded-full bg-gray-100">
                <i className="fi fi-rr-cloud-upload-alt text-4xl text-sky-500"></i>
              </div>
          }
        </label>
      </div>
      <div className="user-infoBox">
        <span className="user-infoInp user-nameInp">{user.name}</span>
        <span className="user-infoInp user-emailInp fs_lg">{user.email}</span>
      </div>
    </div>
  )
}

export function UserSettings() {
  const { t } = useTranslation()

  return (
    <main className="main-section">
      <div className="container">
        <div className="inner_container">
          {/* user info form */}
          <div className="pb-4">
            <form action="#" autoComplete="off">
              <div className="row gy-4">
                <div className="col-lg-6">
                  <HandleSettings />
                </div>
                <div className="col-lg-6 bg-rose-200">
                  <div className="d-flex align-items-center user-companyInfoArea">
                    <div className="user-imgBox image-uploadBox">
                      <img className="user-companyImg image-uploadPreview" src="" />
                      <label className="user-companyImgInpBox" >
                        <input className="d-none image-upload-input" type="file" accept=".jpg,.png" />
                        <img src="/img/settings/upload-icon.svg" alt="" />
                        <div className="user-companyImgInpTitle_1">
                          {t('Upload Logo')}
                        </div>
                        <div className="user-companyImgInpTitle_2 text-center mx-2">
                          JPG/PNG | {t('Max size 1 MB')}
                        </div>
                      </label>
                    </div>
                    <div className="user-companyInfoBox">
                      <input className="user-infoInp user-companyNameInp" type="text" name="name" id="" value={t('Company Name')} placeholder={t('Company Name')} />
                      <button className="user-companyInfoEditBtn" type="button">
                        <i className="fi fi-rr-edit"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <PersonalInformationForm />
          <LanguageForm />
          <PasswordForm />
        </div>
      </div>
    </main>
  )
}
