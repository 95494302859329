import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ZoomState {
  scale: number,
}

const initialState: ZoomState = {
  scale: 1,
}

export const zoomSlice = createSlice({
  name: 'zoom',
  initialState,
  reducers: {
    zoomIn: (state) => {
      state.scale = Math.min(state.scale * 1.25, 4)
    },
    zoomOut: (state) => {
      state.scale = Math.max(state.scale * 0.8, 0.25)
    },
    setZoomScale: (state, action: PayloadAction<number>) => {
      state.scale = action.payload
    },
    resetZoom: (state) => {
      state.scale = 1.0
    },
  },
})

export const { zoomIn, zoomOut, setZoomScale, resetZoom } = zoomSlice.actions

export default zoomSlice.reducer
