import { GetUserPreferenceResponse, SetUserPreferenceRequest } from "share2flow-typedefs"
import config from "../../config"

export async function getPreference(token: string, key: string): Promise<GetUserPreferenceResponse> {
  const res = await fetch(`${config.apiUrl}/preferences/${key}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
  return await res.json()
}

export async function setPreference(token: string, key: string, value: string): Promise<void> {
  const request: SetUserPreferenceRequest = { value }

  const res = await fetch(`${config.apiUrl}/preferences/${key}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(request),
  })
  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
}
