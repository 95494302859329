import { useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { recoverPassword } from "./api"
import { NotificationMessageType, showNotificationMessage } from "../notificationMessages/notificationMessagesSlice"
import { AppDispatch } from "../../store"
import { NotificationMessages } from "../../components/impure/NotificationMessages"
import { useTranslation } from "react-i18next"
import { LanguageDetector } from "../../components/controls/LanguageDetector"

type Status = 'new' | 'in-progress' | 'error' | 'otp' | 'otp-in-progress' | 'otp-error'

export function RecoverPassword() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()

  const [status, setStatus] = useState<Status>('new')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [otp, setOtp] = useState('')
  const [error, setError] = useState('')

  const submit = async (e: any) => {
    if (e) e.preventDefault()
    if (status === 'new' || status === 'error') {
      setStatus('in-progress')
    }
    setError('')
    try {
      await recoverPassword(email, password, status.startsWith('otp') ? otp : null)
      if (status.startsWith('otp')) {
        dispatch(showNotificationMessage({
          type: NotificationMessageType.Success,
          title: 'Password Reset Successfully!',
        }))
        navigate('/auth/login')
      } else {
        setStatus('otp')
      }
    } catch (e: any) {
      setError(e.message)
      if (status.startsWith('otp')) {
        setStatus('otp-error')
      } else {
        setStatus('error')
      }
    }
    return false
  }

  return (
    <div className="bootstrap">
      <LanguageDetector />
      <main className="login_main">
        <div className="flex-grow-1 login_bannerImg" style={{ backgroundImage: "url('/img/login/login.png')" }}>
        </div>
        <div className="login_form">
          <form action="#" autoComplete="off" onSubmit={submit}>
            <div className="text-center flex justify-center">
              <img className="form_logo" src="/img/login/share2flow.png" alt="" />
            </div>
            <h6 className="fs_xl mb-3 c_dark">{t('Recover Password')}</h6>
            <div className="mb-2 mb-xxl-3">
              <div className="d-flex mb-3 align-items-center">
                <div className="pe-2">
                  <img className="form_inp_icon fs_lg" src="/img/login/email_icon.png" alt="" />
                </div>
                <div className="position-relative flex-grow-1">
                  <input className="form-control form_inp fs_lg" type="email" placeholder={t('E-Mailadresse eintragen')} value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
              </div>
              {status.startsWith('otp') &&
              <>
                <div className="d-flex mb-3 align-items-center">
                  <div className="pe-2">
                    <img className="form_inp_icon fs_lg" src="/img/login/lock_icon.png" alt="" />
                  </div>
                  <div className="position-relative flex-grow-1 pwd_tglBox">
                    <input className="form-control form_inp fs_lg" type="password" placeholder={t('Neues Passwort eintragen')} value={password} onChange={(e) => setPassword(e.target.value)}/>
                    <button className="btn pwd_tglBtn" type="button">
                      <i className="fi fi-sr-eye"></i>
                      <i className="fi fi-sr-eye-crossed"></i>
                    </button>
                  </div>
                </div>
                <p className="mb-2">{t('A code has been sent to your email address. Please enter the code below')}</p>
                <div className="d-flex mb-3 align-items-center">
                  <div className="pe-2">
                    <img className="form_inp_icon fs_lg" src="/img/login/lock_icon.png" alt="" />
                  </div>
                  <div className="position-relative flex-grow-1 pwd_tglBox">
                    <input className="form-control form_inp fs_lg" type="text" placeholder={t('OTP')} value={otp} onChange={(e) => setOtp(e.target.value)} />
                  </div>
                </div>
              </>
              }
            </div>
            <div className="mb-4 min-h-[1rem]">
              {error && <p className="text-red-500">{error}</p>}
            </div>
            <div className="text-center">
              <button className="btn btn_secondary border_3 fs_lg fw_medium shadow_def py-2 px-3 mb-3" type="submit" disabled={status.includes('in-progress')}>{t('Recover password')}</button>
            </div>
          </form>
        </div>
        <NotificationMessages />
      </main>
    </div>
  )
}
