import { AiOutlineClockCircle, AiOutlineCreditCard } from "react-icons/ai";
import { HiOutlineChartPie } from "react-icons/hi";
import { BiCircle } from "react-icons/bi";
import { FaArrowDown, FaArrowLeft, FaArrowRight, FaArrowUp, FaCopy, FaCut, FaFileCsv, FaFileImage, FaFilePdf, FaPaste, FaTag, FaTrash } from "react-icons/fa";

export enum NamedIcons {
  CIRCLE = "Circle",
  CLOCK = "Clock",
  PIE_CHART = "Pie Chart",
  CREDIT_CARD = "Credit Card",
  TRASH = "Trash",
  TAG = "Tag",
  ARROW_UP = "Arrow Up",
  ARROW_DOWN = "Arrow Down",
  ARROW_LEFT = "Arrow Left",
  ARROW_RIGHT = "Arrow Right",

  TIME = "Time",
  COST = "Cost",
  COMPETENCY = "Competency",
  INVENTORY = "Inventory",
  PRODUCTIVITY = "Productivity",
  DISTANCE = "Distance",

  FILE_IMAGE = "File Image",
  FILE_PDF = "File PDF",
  FILE_CSV = "File CSV",

  CUT = "Cut",
  COPY = "Copy",
  PASTE = "Paste",
}

export interface NamedIconProps {
  iconName: NamedIcons,
  size?: string,
  className?: string,
}

export function NamedIcon({ iconName, size, className }: NamedIconProps) {
  switch (iconName) {
    case NamedIcons.CIRCLE:
      return <BiCircle size={size} className={className} />
    case NamedIcons.CLOCK:
      return <AiOutlineClockCircle size={size} className={className} />
    case NamedIcons.PIE_CHART:
      return <HiOutlineChartPie size={size} className={className} />
    case NamedIcons.CREDIT_CARD:
      return <AiOutlineCreditCard size={size} className={className} />
    case NamedIcons.TRASH:
      return <FaTrash size={size} className={className} />
    case NamedIcons.TAG:
      return <FaTag size={size} className={className} />
    case NamedIcons.ARROW_UP:
      return <FaArrowUp size={size} className={className} />
    case NamedIcons.ARROW_DOWN:
      return <FaArrowDown size={size} className={className} />
    case NamedIcons.ARROW_LEFT:
      return <FaArrowLeft size={size} className={className} />
    case NamedIcons.ARROW_RIGHT:
      return <FaArrowRight size={size} className={className} />
    case NamedIcons.TIME:
      return <img src="/icons_kpi/Time.png" style={{ width: size, height: size, objectFit: 'contain' }} className={className} />
    case NamedIcons.COST:
      return <img src="/icons_kpi/Cost.png" style={{ width: size, height: size, objectFit: 'contain' }} className={className} />
    case NamedIcons.COMPETENCY:
      return <img src="/icons_kpi/Competency.png" style={{ width: size, height: size, objectFit: 'contain' }} className={className} />
    case NamedIcons.INVENTORY:
      return <img src="/icons_kpi/Inventory.png" style={{ width: size, height: size, objectFit: 'contain' }} className={className} />
    case NamedIcons.PRODUCTIVITY:
      return <img src="/icons_kpi/Productivity.png" style={{ width: size, height: size, objectFit: 'contain' }} className={className} />
    case NamedIcons.DISTANCE:
      return <img src="/icons_kpi/Distance.png" style={{ width: size, height: size, objectFit: 'contain' }} className={className} />
    case NamedIcons.FILE_IMAGE:
      return <FaFileImage size={size} className={className} />
    case NamedIcons.FILE_PDF:
      return <FaFilePdf size={size} className={className} />
    case NamedIcons.FILE_CSV:
      return <FaFileCsv size={size} className={className} />
    case NamedIcons.CUT:
      return <FaCut size={size} className={className} />
    case NamedIcons.COPY:
      return <FaCopy size={size} className={className} />
    case NamedIcons.PASTE:
      return <FaPaste size={size} className={className} />
    default:
      return <></>
  }
}
